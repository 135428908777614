import { apiSlice } from "../apiSlice";

export const resourceSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({

        saveResources: builder.mutation({
            query: (data) => ({
                url: `bookmark/save-bookmark`,
                method: 'POST',
                body: data,
            })
        }),
        getResources: builder.query({
            query: () => ({
                url: 'bookmark/get-bookmark',
                method: 'GET',
            })
        }),
        deleteResources: builder.mutation({
            query: (id) => ({
                url: `bookmark/unsave-bookmark/${id}`,
                method: 'DELETE',
            })
        }),
    })
})

export const {
    useSaveResourcesMutation,
    useGetResourcesQuery,
    useDeleteResourcesMutation
} = resourceSlice;