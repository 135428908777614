import React from 'react';
import { useEffect, useState } from 'react';
import { Link } from 'react-router-dom'
import {
    Container,
    Row,
    Col,
    Card,
    Tab,
    Form,
    Button,
    Nav,
    Alert,
    Modal,
    ButtonGroup,
    Table
} from 'react-bootstrap'
import { useGetSavedAssessmentQuery, useUnbookmarkAssessmentMutation } from '../../../store/assesment/assesmentSlice';
import AssessmentTable from './AssessmentTable';
import Loading from '../Loading';
import './assessment.scss';

const MyAssessment = () => {
    const [searchTerm, setSearchTerm] = useState("")
      const { data: savedAssessment, isLoading} = useGetSavedAssessmentQuery();
         
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12}>
                    <Card>
                            <Card.Body className="d-flex justify-content-between grp-card-head" style={{ position: "relative" }}>
                                <div className="header-title" >
                                    <h4 className="grp-text">My Favourite</h4>
                                </div>

                                <div className="table_search">
                                    <Form action="#" className="searchbox">
                                        <div className="search-link">
                                            <span className="material-symbols-outlined">search </span>
                                        </div>
                                        <Form.Control
                                            type="text"
                                            className="text search-input bg-soft-primary connection-input"
                                            placeholder="Search..."
                                            onChange={(event) => setSearchTerm(event.target.value)}
                                        />
                                    </Form>
                                </div>
                                 <button type="button" className="btn btn-primary grp-btn">
                                    <Link to="/dashboards/app/all-assesment" style={{ color: "white" }} className="">
                                        All Assessment
                                    </Link>
                                </button> :
                            </Card.Body>
                        </Card>
                       {isLoading ? <Loading /> :  <div className={savedAssessment?.length > 0 ? 'd-block mt-3 assesmentTable' : "d-none"}>
                            <Table>
                                <thead>
                                    <tr>
                                        <th style={{
                                            color: "white",
                                            background: "#20639A",
                                            border: "1px solid white"
                                        }}>

                                        </th>
                                        <th style={{
                                            color: "white",
                                            background: "#20639A",
                                            border: "1px solid white",
                                            textTransform: "capitalize",
                                            fontSize: "18px"
                                        }}>
                                            Transition Planning Domains
                                        </th>
                                        <th style={{
                                            color: "white",
                                            background: "#20639A",
                                            border: "1px solid white",
                                            textTransform: "capitalize",
                                            fontSize: "18px"
                                        }}>
                                            Assessment Types
                                        </th>
                                    </tr>
                                </thead>
                                <tbody style={{ background: "white" }}>

                                    {savedAssessment?.filter((item)=>{
                                       if(searchTerm === ""){
                                        return item;
                                       } else if (item.assessment?.Transition_Planning_Domains.toLowerCase().includes(searchTerm.toLowerCase())){
                                         return item;
                                       }
                                    })?.map((item) => {
                                        return (
                                            <AssessmentTable data={item}/>
                                        )
                                    })}

                                </tbody>
                            </Table>
                        </div> }
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default MyAssessment