import { createSlice } from '@reduxjs/toolkit';
import { updateBodyClass, updateHtmlClass, updateHtmlAttr, updateTitle, updateColorRootVar, updateStorage, updateDomValueBySetting, getStorage } from '../../utilities/setting'
import _ from 'lodash'
const initialState = {
    data:{}
  }


export const userSlice = createSlice({
  name: 'user',
  initialState,
  reducers: {
    getUser: (state, action) => {
    
    },

    setUser: (state, action) => {
      state.userDetails=action.payload 
     },
     
     logoutUser: (state, action) => {
      state=[]
      
     },
   
  },
});



export default userSlice.reducer;
