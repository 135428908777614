import React, { useState, useEffect } from "react";
import { Row, Col, Container, Dropdown } from "react-bootstrap";
import Card from "../../components/Card";
import { Link,useHistory } from "react-router-dom";
import fetchApi from "../../store/apiUtil";
import { getDate } from "../../utilities/time";


const Activity = () => {
  const [allActivity, setAllActivity] = useState([]);
  const history = useHistory();

  const getActivity = async () => {
    const res = await fetchApi("users/get-notifications/1", "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      setAllActivity(res?.data);
    }
    // console.log("fetchApi",res)
  };

  useEffect(() => {
    getActivity();
  }, []);

  const handleActivityClick=(activityDetails)=>{
    console.log('Activity click',activityDetails)

  }
  return (
    <>
      <Container>
        <Row>
          <Col sm="12">
Activities          </Col>
          <Col sm="12">
            {allActivity?.map((activityDetails) => {
              let timeObj = getDate(activityDetails?.sent_at);
              return (
                <Card style={{backgroundColor:false ? "transparent" :"lightgrey"}} >
                  <Card.Body >
                    <ul className="notification-list m-0 p-0" onClick={()=>handleActivityClick(activityDetails)}>
                      <li className="d-flex align-items-center justify-content-between">
                        <div className="user-img img-fluid">
                          <img
                            src={activityDetails?.icon}
                            alt="story-img"
                            className="rounded-circle avatar-40"
                          />
                        </div>
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className=" ms-3">
                              <h6>{activityDetails?.title}</h6>
                              <p className="mb-0">
                                {timeObj.hours > 0
                                  ? timeObj.hours > 23
                                    ? `on ${timeObj?.timeStart.toDateString()}`
                                    : `${Math.floor(timeObj.hours)} hour ago`
                                  : `${Math.floor(timeObj.minutes)} min ago`}
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              {/* <Link to="#" className="me-3 iq-notify bg-soft-primary rounded">
                                       <i className="material-symbols-outlined md-18">
                                          military_tech
                                       </i>
                                       </Link> */}
                              {/* <div className="card-header-toolbar d-flex align-items-center">
                                          <Dropdown>
                                             <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                more_horiz
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu className="dropdown-menu-right">
                                                <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div> */}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
        </Row>
      </Container>
    </>
  );
};

export default Activity;
