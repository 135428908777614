import { apiSlice } from "../apiSlice";

export const globalSearchApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getSearch: builder.query({
      query: (data) => ({
        url: `search/search/${data}`,
        method: 'GET',
      })
    }),
})
})

export const {
useGetSearchQuery,
} = globalSearchApiSlice;
