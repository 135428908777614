import React from 'react'
import Header from '../components/partials/HeaderStyle/Header'
import Sidebar from '../components/partials/SidebarStyle/Sidebar'
import Footer from '../components/partials/FooterStyle/Footer'
import WithoutRightSidebarRouter from '../router/without-rightsidebar-router'
import ShareOffcanvas from '../components/ShareOffcanvas'

const WithoutRightSidebar = () => {
    return (
        <>
            <div className="wrapper">
                <Sidebar />
                <Header />
                <div id="content-page" className="content-page">
                    <WithoutRightSidebarRouter />
                </div>
            </div>
            <Footer />
            {/* <ShareOffcanvas /> */}
        </>
    )
}

export default WithoutRightSidebar