import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    groupDetail: []
}

const groupDetailSlice = createSlice({
    name: 'groupDetail',
    initialState,
    reducers: {
        groupDetail: (state, action) =>{
            state.groupDetail = action.payload
        }
    }
})


export default groupDetailSlice.reducer
export const {groupDetail,} = groupDetailSlice.actions 
