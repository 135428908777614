import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Loading from '../Loading';
import {
    useGetInvitationsQuery,
    useUpdateStatusMutation,
} from "../../../store/connection/connectionApiSlice";
import { useGetUserDetailsQuery } from "../../../store/user/userApiSlice";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";

const InviationCard = ({ user,
    displayInvitations,
    invitationCount,
    setInvitationCount,
    setDisplayInvitations,
    refetch,
    setUser,
    val }) => {
    const [loading, setLoading] = useState(false);
    const [updateStatus] = useUpdateStatusMutation();
    const userInfo = useSelector((state) => state.user.userDetails);
    const dispatch = useDispatch();
    const handleConfirm = async (id) => {
        // displayInvitations?.map((val)=>{
        //   if(val._id === id){
        //     setLoading(true)
        //   }
        // })
        setLoading(true);
        const res = await updateStatus({
            id: id,
            status: "active",
        });
        if (res?.data?.status === 1) {
            setLoading(false);
            let newList = displayInvitations.filter((val) => val._id != id);
            setDisplayInvitations(newList);
            // refetch()
            // userInfo.connection_count = userInfo.connection_count + 1;
            // userInfo.invitation_count = invitationCount - 1;
            setInvitationCount(invitationCount - 1);
            try {
                refetch();
            } catch (error) {
                console.log(error, "errr");
            }

            dispatch(
                setUser({
                    ...userInfo,
                    connection_count: userInfo.connection_count + 1,
                    invitation_count: invitationCount - 1,
                })
            );
        } else {
            setLoading(false);
        }
    };

    const handleReject = async (id) => {
        setLoading(true);
        const res = await updateStatus({
            id: id,
            status: "declined",
        });
        if (res?.data?.status === 1) {
            setLoading(false);
            try {
                let newList = displayInvitations.filter((val) => val._id != id);
                setDisplayInvitations(newList);
                // userInfo.invitation_count = userInfo.invitation_count - 1;
                setInvitationCount(invitationCount - 1);
                refetch();
                dispatch(
                    setUser({ ...userInfo, invitation_count: invitationCount - 1 })
                );
            } catch (error) {
                console.log(error);
            }
        } else {
            setLoading(false);
        }
    };
    return (
        <>
            {loading ? <Loading /> : <li className="d-flex align-items-center  justify-content-between flex-wrap">
                <div className="user-img img-fluid flex-shrink-0">
                    <Link to={`/dashboard/app/profile-view/${user?.ref}`}>
                        <img
                            src={user?.profile_picture ? user.profile_picture : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                            alt="story-img"
                            className="rounded-circle avatar-40"
                        />
                    </Link>
                </div>
                <div className="flex-grow-1 ms-3">
                    <Link to={`/dashboard/app/profile-view/${user?.ref}`}>
                        <h6 style={{fontWeight:"600"}}>{user.username}</h6>
                        {/* <p className="mb-0">40 friends</p> */}
                    </Link>
                </div>
                <div className="d-flex align-items-center mt-2 mt-md-0">
                    <div
                        className="confirm-click-btn"
                        onClick={() => handleConfirm(val._id)}
                    >
                        <div className="me-3 btn btn-primary rounded confirm-btn">
                            Confirm
                        </div>

                        <Link
                            to="#"
                            className="me-3 btn btn-primary rounded request-btn"
                            style={{ display: "none" }}
                        >
                            View All
                        </Link>
                    </div>
                    <div
                        onClick={() => handleReject(val._id)}
                        className="btn btn-secondary rounded"
                        data-extra-toggle="delete"
                        data-closest-elem=".item"
                    >
                        Reject
                    </div>
                </div>
            </li>}
        </>
    )
}

export default InviationCard