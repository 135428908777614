import React from 'react'
import Header from '../components/partials/HeaderStyle/Header'
import RightSidebar from '../components/partials/SidebarStyle/Rightsidebar'
import Footer from '../components/partials/FooterStyle/Footer'
import WithoutLeftSidebarRouter from '../router/without-leftsidebar-router'
import ShareOffcanvas from '../components/ShareOffcanvas'
import SettingOffCanvas from '../components/setting/SettingOffCanvas'

const WithoutLeftSidebar = () => {
    return (
        <>
            <div className="wrapper">
                <Header />
                <RightSidebar />
                <div id="content-page" className="content-page">
                    <WithoutLeftSidebarRouter />
                </div>
            </div>
            <Footer />
            <SettingOffCanvas name={true}/>
            {/* <ShareOffcanvas /> */}
        </>
    )
}

export default WithoutLeftSidebar