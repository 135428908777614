import React, { useState } from 'react'
import { Container, Row, Card, Col } from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import Loading from '../Loading';
const PeoplesCard = ({
    item,
    connection,
    invitation,
    userId,
    invitationMatch,
    createConnection,
    match,
    invitedUser,
    invited,
    getInvitationByUser
}) => {
    const [loading, setLoading] = useState(false)
    const handleSentInvite = async (id) => {
        setLoading(true)
        const res = await createConnection({
            user_id: id,
        });
        if (res.data.status === 1) {
            setLoading(false)
            getInvitationByUser()
        }
    };
    return (
        <>
            <Col lg={6} md={6}>
                <Card style={{ boxShadow: "6px 6px 5px #e5e5e5" }}>
                    <Card.Body>
                        {loading ? <Loading /> : <div className="d-flex align-items-center justify-content-between">
                            <Link to={`/dashboard/app/profile-view/${item?._id}`}>
                                <div className="">
                                    <img
                                        src={item?.profile_picture || "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                    />
                                </div>
                            </Link>
                            <Link to={`/dashboard/app/profile-view/${item?._id}`}>
                                <div className="">
                                    <h6>
                                        {item?.first_name} {item?.last_name}
                                    </h6>
                                </div>
                            </Link>
                            {connection?.map((val) => {
                                let user =
                                    val?.requester?.ref === userId
                                        ? val?.requested
                                        : val?.requester;
                                if (user?.ref === item?._id) {
                                    match = true
                                }
                            })}
                            {invitation?.map((val) => {
                                let user =
                                    val?.requester?.ref === userId
                                        ? val?.requested
                                        : val?.requester;
                                if (user?.ref === item._id) {
                                    invitationMatch = true
                                }
                            })}
                            {
                                invitedUser?.map((val) => {
                                    if (val.requested?.ref === item._id) {
                                        invited = true
                                    }
                                })
                            }
                            {invited ? <div>
                                <button type="submit"
                                    disabled
                                    className="btn btn-secondary d-block w-100 mt-1 sendInvite"
                                    style={{ padding: "2px 8px" }}>
                                    Requested
                                </button>
                            </div> :
                                <>
                                    {!invitationMatch ? <div>
                                        {!match ? <div> {userId !== item?._id ? <div><button className=" btn btn-primary rounded confirm-btn" type="submit"
                                            style={{ padding: "2px 8px" }}
                                            onClick={() => handleSentInvite(item?._id)}>
                                            Connect
                                        </button>
                                        </div> : ""}
                                        </div> : <div>
                                            <button type="submit"
                                                disabled
                                                className="btn btn-secondary d-block w-100 mt-1 sendInvite"
                                                style={{ padding: "2px 8px" }}>
                                                Connected
                                            </button>
                                        </div>}
                                    </div> : <button type="submit"
                                        disabled
                                        className="btn btn-secondary d-block w-100 mt-1 sendInvite"
                                        style={{ padding: "2px 8px" }}>
                                        Invited
                                    </button>}
                                </>}
                        </div>}
                    </Card.Body>
                </Card>
            </Col>
        </>
    )
}

export default PeoplesCard