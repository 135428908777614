import React from 'react'
import { Card, Col, Container, Form, Row,Spinner } from 'react-bootstrap';
import { Link, useParams, useHistory } from "react-router-dom";
import { useState, useEffect } from 'react';
import fetchApi from '../../store/apiUtil';
import PlusMinus from '../+-icon.png'
import { useCreateConnectionMutation } from '../../store/connection/connectionApiSlice';

const PeopleYouMayKnow = () => {
    const [allDisplayedUsers, setAllDisplayedUsers] = useState([]);
    const [searchTerm, setSearchTerm] = useState('')
    const [createConnection] = useCreateConnectionMutation();
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false); // Loading more data on scroll
  const [itemsToDisplay, setItemsToDisplay] = useState(12);
  const itemsPerScroll = 3;
    const getAllPeopleYouMayKnow = async () => {
        const res = await fetchApi("users/get-users", "GET");
        if (res && res?.status === 1 && res?.data?.length > 0) {
            setAllDisplayedUsers(res?.data);
        }
    };

    useEffect(() => {
        getAllPeopleYouMayKnow();
    }, []);

    const handleSentInvite = async (id) => {
        const res = await createConnection({
            user_id: id,
        });
        if (res?.data?.status === 1) {
            let newList = allDisplayedUsers?.filter((val) => val._id != id);
            setAllDisplayedUsers(newList);
        }
    };
    const handleScroll = () =>{
        const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
        console.log(scrollTop+clientHeight, scrollHeight,"sccccs")
        if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
          setLoadingMore(true);
          setTimeout(() => {
            setItemsToDisplay(prevItems => prevItems + itemsPerScroll);
            setLoadingMore(false);
          }, 1000); // Simulating loading delay, replace with your actual data fetching logic
        }
        };
        
useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    }, [loadingMore]);

    return (
        <>
            <Container >
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <div>
                                    <h4 className="table_heading_text">People You May Know</h4>
                                </div>
                                <div>
                                    <div className="table_search ">
                                        <Form action="#" className="searchbox">
                                            <Link className="search-link" to="#">
                                                <span
                                                    className="material-symbols-outlined"

                                                >
                                                    search
                                                </span>
                                            </Link>
                                            <Form.Control
                                                type="text"
                                                className="text search-input bg-soft-primary connection-input"
                                                placeholder="Search..."
                                                onChange={(e) => setSearchTerm(e.target.value)}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container>
                <Row className="d-flex">
                    {allDisplayedUsers?.filter((item) => {
                        if (searchTerm === "") {
                            return item;
                        } else if (item.first_name.toLowerCase().includes(searchTerm.toLocaleLowerCase())) {
                            return item;
                        }
                    })?.slice(0,itemsToDisplay).map((val, index) => {
                        var userType = "family"
                        if (val?.user_type === "family") {
                            userType = "Family/Caregiver"
                        } else if (val?.user_type === "agency") {
                            userType = "Agency/Community partner"
                        } else {
                            userType = val?.user_type
                        }
                        return (
                            <Col lg={4} md={4} sm={6}>
                                <Card style={{ boxShadow: "6px 6px 5px #e5e5e5" }}>
                                    <Card.Body style={{height: '120px', overflow: 'hidden',  texOverflow: 'ellipsis',
  whiteSpace: 'nowrap'}}>
                                        <div className="d-flex align-items-center justify-content-between w-100" style={{boxSizing:'border-box',gap:'2px'}}>
                                            <Link to={`/dashboard/app/profile-view/${val._id}`}style={{flex:1}}>
                                                <div className="">
                                                    <img
                                                        src={val.profile_picture ? val.profile_picture : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                                                        alt="story-img"
                                                        className="rounded-circle avatar-40"
                                                    />
                                                </div>
                                            </Link>
                                            <Link to={`/dashboard/app/profile-view/${val._id}`} style={{flex:3,display:'flex', alignItems:'center',justifyContent:'flex-start'}}>
                                                <div className="mt-3" style={{display:"flex",flexWrap:'wrap', flexDirection:"column",}}>
                                                    <h5 style={{
                                                        fontWeight: "600",
                                                        
                                                    }}>
                                                        {`${val.first_name}${" "}${val.last_name}`}
                                                    </h5>
                                                    <p className='d-flex flex-column justify-content-center align-items-center' style={{
                                                        fontSize: "12px",
                                                        marginBottom: "10px",
                                                        textTransform: "capitalize",
                                                        alignSelf:"flex-start"
                                                    }}>{userType}</p>
                                                </div>
                                            </Link>
                                            {/* <button className=" btn btn-primary rounded confirm-btn" type="submit"
                                                style={{ padding: "2px 8px" ,flex:1}}
                                                onClick={() => handleSentInvite(val._id)}
                                            > */}
                                                {/* Connect */}
                                               
                                                <div style={{flex:1, padding: "2px 8px",cursor:"pointer"}} 
                            onClick={() => handleSentInvite(val._id)}
                            className="d-flex justify-content-center align-items-center"
                          
                          >
                                               
                                                <img src={PlusMinus} height={25} width={25} /></div>
                                            {/* </button> */}
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                        )
                    })}
{loadingMore && itemsToDisplay< allDisplayedUsers.length &&  <Col lg={12} className="text-center mt-2 mb-3 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </Col>
}
                </Row>
            </Container>
        </>
    )
}

export default PeopleYouMayKnow