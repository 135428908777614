import React from 'react'
import { Modal } from 'react-bootstrap'
import './ShareModal.css'
import {
  EmailIcon,
  EmailShareButton,
  FacebookIcon,
  FacebookMessengerIcon,
  FacebookMessengerShareButton,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TelegramIcon,
  TelegramShareButton,
  WhatsappIcon,
  WhatsappShareButton,
  XIcon,
} from "react-share";

  
const ShareModal = ({showModal, setShowModal, postId}) => {
  const shareUrl = `${window.location.origin}/dashboards/app/post-detail/${postId}`

  return (
    <Modal show={showModal} onHide={()=>setShowModal(false)} centered>
        <Modal.Header className="d-flex justify-content-between">
          <Modal.Title id="post-modalLabel">Choose where to share Post</Modal.Title>
          <div onClick={()=>setShowModal(false)} style={{cursor: 'pointer'}}>
            <span className="material-symbols-outlined">close</span>
          </div>
        </Modal.Header>
        <Modal.Body><div className="Demo__container">
      <div className="Demo__some-network">
        <FacebookShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <FacebookIcon size={32} round />
        </FacebookShareButton>
      </div>

      <div className="Demo__some-network">
        <FacebookMessengerShareButton
          url={shareUrl}
          appId="521270401588372"
          className="Demo__some-network__share-button"
        >
          <FacebookMessengerIcon size={32} round />
        </FacebookMessengerShareButton>
      </div>

      <div className="Demo__some-network">
        <TelegramShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <TelegramIcon size={32} round />
        </TelegramShareButton>
      </div>

      <div className="Demo__some-network">
        <WhatsappShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <WhatsappIcon size={32} round />
        </WhatsappShareButton>
      </div>

      <div className="Demo__some-network">
        <LinkedinShareButton
          url={shareUrl}
          className="Demo__some-network__share-button"
        >
          <LinkedinIcon size={32} round />
        </LinkedinShareButton>
      </div>

      
      <div className="Demo__some-network">
        <EmailShareButton
          url={shareUrl}
          body="body"
          className="Demo__some-network__share-button"
        >
          <EmailIcon size={32} round />
        </EmailShareButton>
      </div>
    </div>
             </Modal.Body></Modal>
  )
}

export default ShareModal