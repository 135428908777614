import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setUser } from "../../store/user/action";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";
import { ToastContainer, toast } from 'react-toastify';

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

//img
import logo from "../../assets/images/main_logo.png";

import { signupvalidation } from "./FormValidation";
import { useSignupMutation } from "../../store/user/userApiSlice";
import config from "../../store/config.json";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const SignUp = () => {
  const [check, setCheck] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [parentEmail, setParentEmail] = useState("");
  const [isParentEmailValid, setIsParentEmailValid] = useState(false);
  const [userInput, setUserInput] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    confirm_password: "",
    phone: "",
    user_type: "student",
    public_vapid_key: 90,
    parent_email: ""
  });
  const [isValid, setIsValid] = useState({
    call: false,
    success: false,
    msg: "",
  });
  const user = useSelector((state) => state.user);
  let history = useHistory();
  const dispatch = useDispatch();
  const [signup] = useSignupMutation();

  const validateEmail = (email) => {
    return String(email)
      .toLowerCase()
      .match(
        /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
      );
  };

  const onHandleSearchChange = async (e) => {
    const isValidEmail = validateEmail(e.target.value)
    if (isValidEmail) {
      getEmailInfo(e.target.value)
    }
  };

  const getEmailInfo = (email) => {
    fetch(`${config.WEBPORTAL_URL}/api/auth/get-user-by-email/${email}`, {
      method: "GET",
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            if (data?.data[0]?.user_type === "family") {
              setIsParentEmailValid(true)
              setParentEmail(data?.data[0]?._id)
            } else {
              setParentEmail(data?.data[0]?._id)
              setIsParentEmailValid(false)
            }
            // dispatch(setUser({ ...userInfo, ...data?.data[0] }));
          });
        } else {
          setParentEmail("")
        }
      })
      .catch((error) => { });
  };

  const handlUserInput = (e) => {
    const { value, name } = e.target;
    setUserInput({ ...userInput, [name]: value });
    if (name === "check") {
      setCheck(e.target.checked);
    }
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmit(true);
    if (userInput?.user_type === "student") {
      if (!parentEmail) {
        setFormErrors({
          parent_email: "This email address is not associated with any account."
        })
        return
      }
      if (!isParentEmailValid) {
        setFormErrors({
          parent_email: "This email address is not associated with any parent."
        })
        return
      }


    }
    setFormErrors(signupvalidation(userInput));
    dispatch(setUser("data"));

    if (Object.keys(signupvalidation(userInput)).length === 0) {
      let payload = userInput
      if (userInput?.user_type === "student") {
        payload = { ...payload, parent_id: parentEmail }
        // setUserInput({...userInput,parent_id:parentEmail})
      }
      let res = await signup(payload);
      let data = res?.data;
      if (res?.data?.status === 1) {
        setIsValid({
          ...isValid,
          call: true,
          success: true,
          msg: data?.message,
        });
        dispatch(setUser(data.data));
        // history.push("/auth/confirm-mail");
        let msg = userInput?.user_type === "student" ? "Your parent must verify your account before you can log in." : "Account Created Successfully! Please Login."
        toast(msg, {
          position: "top-right",
          autoClose: 2500,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          type: "success",
        });
        history.push("/auth/sign-in");
        signupvalidation("clear error");
      } else {
        if (res?.error?.status === 400) {
          setIsValid({
            ...isValid,
            call: true,
            success: false,
            msg: res?.error?.data?.data[0].msg,
          });
        } else {
          res.json().then((data) => {
            setIsValid({
              ...isValid,
              call: true,
              success: false,
              msg: res?.data?.msg,
            });
          });
        }
      }
      signupvalidation("clear error");
    }
  };

  const handleVisible = (value) => {
    if (value === "password") {
      setVisiblePassword(!visiblePassword);
    }
    if (value === "confirm_password") {
      setVisibleConfirmPassword(!visibleConfirmPassword);
    }
  };

  return (
    <>
      <section className="sign-in-page">
        <div>
          <Row className="no-gutters align-items-center" style={{ width: "100%", height: "100vh", marginLeft: "0.1px" }}>
            <Col md="8" className="p-5 d-none d-lg-block">
              <h2 className="text-white fontBold">
                PA Secondary Transition - Public Square
              </h2>
              <h3 className="text-white mt-2 mb-3">
                Login and create an account to personalize your experience
              </h3>
              <h4 className="text-white mt-2 mb-3">
                Connect, Collect, Contribute
              </h4>
              <ul className="text-white listItems">

                <li>Connect with people & events within your community and across the state.</li>
                <li>Collect and save your favorite transition resources & assessments.</li>
                <li>Contribute by sharing resources, articles and stories.</li>
              </ul>
            </Col>
            <Col md="4" className="bg-white p-5 scrollInner" style={{ height: "auto", display: "flex", flexDirection: "column", justifyContent: "center" }}>

              {isValid.call &&
                (isValid.success ? (
                  <Alert variant="success">{isValid.msg}</Alert>
                ) : (
                  <Alert variant="danger">{isValid.msg}</Alert>
                ))}
              <div style={{ textAlign: "center" }}>
                <Image src={logo} className="img-fluid mb-2" alt="logo" />
              </div>
              <div className="d-block d-lg-none"><h2 className="textBlue text-center fontBold">
                Public Square
              </h2>

                <h5 className="textBlue text-center mb-2">
                  Connect, Collect, Contribute
                </h5><hr style={{ border: 'solid 1px #ddd' }} /></div>

              {/* <Link className="sign-in-logo mb-5" to="#"></Link> */}
              <h3 className="mb-0 text text-center" style={{ fontWeight: "600" }}>
                Sign Up
              </h3>
              <p className="text-center formsSubHeadings text-dark">Create an account to personalize your experience</p>
              <Form className="mt-4" noValidate onSubmit={handleSubmit}>
                <Form.Group className="form-group">
                  <Form.Label className="textBlue">User Role<span className="mandatory">*</span></Form.Label>
                  <select
                    className="form-select"
                    name="user_type"
                    id="choices-multiple-default"
                    onChange={handlUserInput}
                    required
                  >
                    <option value="student">Student</option>
                    <option value="educator">Educator</option>
                    <option value="family">Family/Caregiver</option>
                    <option value="agency">Agency/Community Partner</option>
                    {/* <option value="admin">Admin</option> */}
                  </select>
                  <p className="errortext">{formErrors.user_type}</p>
                </Form.Group>
                {userInput?.user_type === "student" && <Form.Group className="form-group">
                  <Form.Label className="textBlue">Parent Email<span className="mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    className="mb-0"
                    id="parent-email"
                    name="parent_email"
                    placeholder="Enter Parent Email"
                    onChange={(event) => onHandleSearchChange(event)}
                    required
                  />
                  <p className="errortext">{formErrors.parent_email}</p>
                  {/* <Form.Control
                      type="text"
                      className="text search-input bg-soft-primary connection-input"
                      placeholder="Search..."
                      onChange={(event) => onHandleSearchChange(event)}
                    /> */}
                </Form.Group>

                }
                <Form.Group className="form-group">
                  <Form.Label className="textBlue">First Name<span className="mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    className="mb-0"
                    id="first-name"
                    name="first_name"
                    placeholder="Enter First Name"
                    onChange={handlUserInput}
                    required
                  />
                  <p className="errortext">{formErrors.first_name}</p>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label className="textBlue">Last Name<span className="mandatory">*</span></Form.Label>
                  <Form.Control
                    type="text"
                    className="mb-0"
                    id="last-name"
                    name="last_name"
                    placeholder="Enter Last Name"
                    onChange={handlUserInput}
                    required
                  />
                  <p className="errortext">{formErrors.last_name}</p>
                </Form.Group>
                <Form.Group className="form-group">
                  <Form.Label className="textBlue">Email<span className="mandatory">*</span></Form.Label>
                  <Form.Control
                    type="email"
                    className="mb-0"
                    id="email"
                    name="email"
                    placeholder="Enter Email"
                    onChange={handlUserInput}
                    required
                  />
                  <p className="errortext">{formErrors.email}</p>
                </Form.Group>
                {/* <Form.Group className="form-group">
                    <Form.Label>Phone</Form.Label>
                    <Form.Control
                      type="number"
                      className="mb-0"
                      id="phone"
                      name="phone"
                      placeholder="Phone"
                      onChange={handlUserInput}
                    />
                  </Form.Group> */}
                <Form.Group className="form-group" style={{ position: "relative" }}>
                  <Form.Label className="textBlue">Password<span className="mandatory">*</span></Form.Label>
                  <Form.Control
                    type={visiblePassword ? "text" : "password"}
                    className="mb-0"
                    id="password"
                    name="password"
                    placeholder="Enter Password"
                    onChange={handlUserInput}
                    required
                  />
                  <div
                    style={{ position: "absolute", right: "4%", bottom: "8%", cursor: "pointer" }}
                  >
                    {(visiblePassword === false) ? <i onClick={() => { handleVisible("password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("password") }} class="ri-eye-off-line"></i>}
                  </div>
                </Form.Group>
                <p className="errortext">{formErrors.password}</p>
                <Form.Group className="form-group" style={{ position: "relative" }}>
                  <Form.Label className="textBlue">Confirm Password<span className="mandatory">*</span></Form.Label>
                  <Form.Control
                    type={visibleConfirmPassword ? "text" : "password"}
                    className="mb-0"
                    id="confirm-password"
                    name="confirm_password"
                    placeholder="Re-enter Password"
                    onChange={handlUserInput}
                    required
                  />
                  <div
                    style={{ position: "absolute", right: "4%", bottom: "8%", cursor: "pointer" }}
                  >
                    {(visibleConfirmPassword === false) ? <i onClick={() => { handleVisible("confirm_password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("confirm_password") }} class="ri-eye-off-line"></i>}
                  </div>
                </Form.Group>
                <p className="errortext">{formErrors.confirm_password}</p>
                <div className="d-inline-block w-100">
                  <Form.Check className="d-inline-block mt-2 pt-1">
                    <Form.Check.Input
                      required
                      type="checkbox"
                      className="me-2"
                      id="customCheck1"
                      name="check"
                      onChange={handlUserInput}
                    />
                    <Form.Check.Label>
                      I accept{" "}
                      <a href="https://pasecondarytransition.com/terms-conditions" target="_blank">
                        <span className="text">Terms and Conditions</span>
                      </a>
                    </Form.Check.Label>
                  </Form.Check>
                  <p className="errortext">{formErrors.check}</p>
                  <Button
                    type="submit"
                    className="btn-primary float-end"
                    defaultChecked
                    disabled={check ? "" : "disabled"}
                  >
                    Sign Up
                  </Button>
                </div>
                <hr></hr>
                <div className="sign-info text-center signUpButtonNew">
                  <span className="dark-color line-height-2 text-dark text-bold text-center mb-2 fontBold">
                    Already have an account?{" "} </span>
                  <Link to="/auth/sign-in" className="buttonLink text-center" style={{ display: "block" }}>Sign in</Link>

                </div>

              </Form>

            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default SignUp;