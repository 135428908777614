export const getDate = (created_at) => {
    var timeStart = new Date(created_at);
    var timeEnd = new Date();
    var hourDiff = timeEnd - timeStart; 
    var secDiff = hourDiff / 1000; 
    var minDiff = hourDiff / 60 / 1000; 
    var hDiff = hourDiff / 3600 / 1000; 
    var humanReadable = {};
    humanReadable.timeStart = timeStart;
    humanReadable.hours = Math.floor(hDiff);
    humanReadable.minutes = minDiff - 60 * humanReadable.hours;
    return humanReadable;
  };