import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import Header from '../components/partials/HeaderStyle/Header'
import RightSidebar from '../components/partials/SidebarStyle/Rightsidebar'
import Sidebar from '../components/partials/SidebarStyle/Sidebar'
import Footer from '../components/partials/FooterStyle/Footer'
import DefaultRouter from '../router/default-router'
import ShareOffcanvas from '../components/ShareOffcanvas'
import SettingOffCanvas from '../components/setting/SettingOffCanvas'
import {
    useLocation
} from "react-router-dom";

const Default = () => {
    const location = useLocation()

    useEffect(() => {

    }, [])

    const dispatch = useDispatch()

    useEffect(async () => {

    }, [])
    return (
        <>
            <Sidebar />
            <Header />
            <div className="main-content">
                {/* <div id="content-page" className="content-page"> */}
                    <DefaultRouter />
                {/* </div> */}
            </div>
            {location?.pathname !== "/dashboard/app/userList" && <RightSidebar />}
            <Footer />
            <SettingOffCanvas />
            {/* <ShareOffcanvas /> */}
        </>
    )
}

export default Default
