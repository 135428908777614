//router
import IndexRouters from "./router/index"

//--scss
import "./assets/scss/socialv.scss"
import "./assets/scss/customizer.scss"

import 'react-toastify/dist/ReactToastify.css';
import 'remixicon/fonts/remixicon.css'

import { ErrorBoundary } from "./views/errors/ErrorHandler"
import { ToastContainer } from "react-toastify";
function App() {
  return (
    <div className="App">
      <ErrorBoundary>
        <IndexRouters />
        <ToastContainer />
      </ErrorBoundary>
    </div>
  );
}

export default App;
