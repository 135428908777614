import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form } from "react-bootstrap";
import Card from "../../../components/Card";
import { useSelector } from "react-redux";
import {
  useGetConnectionQuery,
  useUpdateStatusMutation,
} from "../../../store/connection/connectionApiSlice";
import { useGetUserDetailsQuery } from "../../../store/user/userApiSlice";
import { setUser } from "../../../store/user/action";
import { useDispatch } from "react-redux";
import config from "../../../store/config.json";
import fetchApi from "../../../store/apiUtil";
import ConnectionCard from "./ConnectionCard";

const ConnectionList = () => {
  const dispatch = useDispatch();
  const [allConnections, setAllConnections] = useState([]);
  const [displayConnections, setDisplayConnections] = useState([]);
  const [connectionCount, setConnectionCount] = useState(0);
  const { data, refetch } = useGetConnectionQuery();
  const [updateStatus] = useUpdateStatusMutation();
  const { data: detailData } = useGetUserDetailsQuery("");

  const onHandleSearchChange = async (e) => {
    let connectionSearchData = e.target.value;
    e.preventDefault();
    var tempArr = [];
    allConnections.map((item) => {
      let fullName = item.requester?.username;
      const lData = fullName.toLowerCase();
      if (lData.includes(connectionSearchData.toLowerCase())) {
        tempArr.push(item);
      }
    });

    setDisplayConnections(tempArr);
  };
  const userInfo = useSelector((state) => state.user.userDetails);

  const getUserInfo = () => {
    fetch(`${config.WEBPORTAL_URL}/api/users/get-user-details`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // console.log(data?.data,"logger");
            dispatch(setUser({ ...userInfo, ...data?.data[0] }));
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  const getConnectionsInfo = async () => {
    const res = await fetchApi("connections/get-connections", "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      setAllConnections(res?.data);
      setDisplayConnections(res?.data);
    }
  };
  useEffect(() => {
    getUserInfo();
    getConnectionsInfo();
  }, []);

  useEffect(async () => {
    // const resp=await refetch()
    setConnectionCount(userInfo?.connection_count);
  }, [userInfo?.connection_count]);

  // console.log(userInfo)

  return (
    <>
      {/* <ProfileHeader title="Friend Lists" img={img3}/> */}

      <Container>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body className="d-flex justify-content-between align-item-center">
                <div className="">
                  <h4 className="table_heading_text">
                    Connections ({connectionCount})
                  </h4>
                </div>
                <div className="table_search">
                  <Form action="#" className="searchbox">
                    <div className="search-link">
                      <span className="material-symbols-outlined">search </span>
                    </div>
                    <Form.Control
                      type="text"
                      className="text search-input bg-soft-primary connection-input"
                      placeholder="Search..."
                      onChange={(event) => onHandleSearchChange(event)}
                    />
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="d-flex">


          {displayConnections?.map((val) => {
            let user =
              val.requester.ref === userInfo?._id
                ? val.requested
                : val?.requester;
            return (
              <>
                <Col lg={displayConnections?.length > 2 ? 4 : 12} >
                  {displayConnections?.length > 0 ? <Card >
                    <Card.Body >
                      {/* <ul className="request-list m-0 p-0"> */}
                        <ConnectionCard
                          user={user}
                          val={val}
                          updateStatus={updateStatus}
                          displayConnections={displayConnections}
                          setDisplayConnections={setDisplayConnections}
                          setUser={setUser}
                          userInfo={userInfo}
                          connectionCount={connectionCount}
                          refetch={refetch}
                        />
                      {/* </ul> */}
                    </Card.Body>
                  </Card> : ""}
                </Col>
              </>
            );
          })}


        </Row>
      </Container>
    </>
  );
};

export default ConnectionList;
