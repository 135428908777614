import React from 'react';
import { Table } from 'antd';


const GenericTable = ({ columns, dataSource }) => {
    const onChange = (pagination, filters, sorter, extra) => {
        console.log('params', pagination, filters, sorter, extra);
    };
    return <Table columns={columns} dataSource={dataSource} onChange={onChange} />
};
export default GenericTable;