import React from 'react'
import Header from '../components/partials/HeaderStyle/Header'
import RightSidebar from '../components/partials/SidebarStyle/Rightsidebar'
import Sidebar from '../components/partials/SidebarStyle/Sidebar'
import Footer from '../components/partials/FooterStyle/Footer'
import Layout1Router from '../router/layout1-router'
import ShareOffcanvas from '../components/ShareOffcanvas'
import SettingOffCanvas from '../components/setting/SettingOffCanvas'

const Layout1 = () => {
    return (
        <>
            <Sidebar />
            <Header />
            <div className="main-content">
                <div id="content-page" className="content-page">
                    <Layout1Router />
                </div>
            </div>
            <RightSidebar />
            <Footer />
            <SettingOffCanvas />
            {/* <ShareOffcanvas /> */}
        </>
    )
}

export default Layout1
