import React from 'react';
import { useEffect, useState } from 'react';
import { useHistory } from "react-router-dom";
import { useGetSavedAssessmentQuery, useSaveAssessmentMutation, useUnbookmarkAssessmentMutation } from '../../../store/assesment/assesmentSlice';

const AssessmentTable = ({ item, data }) => {
    const [bookmark, setBookmark] = useState(false)
    const [saveAssesment, { isLoading }] = useSaveAssessmentMutation()
    const { data: savedAssessment } = useGetSavedAssessmentQuery()
    const [unBookmarkAssessment, { isLoading: loading }] = useUnbookmarkAssessmentMutation()
    const history = useHistory()
    const handleBookmark = async (data) => {
        const payload = {
            assessment_id: data?.Assessment_id,
            assessment: data
        }
        if (!bookmark) {
            const result = await saveAssesment(payload);
            if (result?.data?.status === 1) {
                setBookmark(true)
                // setTimeout(() => {
                //     history.push('/dashboards/app/my-assesment')
                // }, 1500)
            }
        }
        if (bookmark) {
            savedAssessment?.map((ele) => {
                if (ele?.assessment?.Assessment_id === item?.Assessment_id) {
                    handleUnbookmark(ele?.assessment?.Assessment_id)
                }
            })
        }

    }

    const handleUnbookmark = async (id) => {
        const result = await unBookmarkAssessment(id);
        if (result?.data?.status === 1) {
            setBookmark(false)
            // setTimeout(() => {
            //     history.push('/dashboards/app/all-assesment')
            // }, 1200)

        }
    }

    useEffect(() => {
        savedAssessment?.map((ele) => {
            if (ele?.assessment?.Assessment_id === item?.Assessment_id) {
                setBookmark(true)
            }
        })
    }, [savedAssessment])

    return (
        <>
            <>
                <tr>
                    <td className='text-center' style={{ border: "1px solid #CED4DA" }}>
                        <img className='mt-1' src={item?.Icon || data?.assessment?.Icon} alt="" style={{ height: "100%", width: "32px", }} />
                    </td>
                    <td style={{ border: "1px solid #CED4DA" }}>
                        <a style={{
                            color: "#007bff",
                            fontSize: "18px"
                        }}
                            target="_blank"
                            href={item?.PDF_Link || data?.assessment?.PDF_Link
                            }>
                            {item?.Transition_Planning_Domains || data?.assessment?.Transition_Planning_Domains}
                        </a>
                    </td>
                    <td style={{ border: "1px solid #CED4DA", position: "relative" }}>
                        <ul style={{ fontSize: "18px" }}>
                            {item ? item?.Assessments_Types?.split(',')?.map((item) => {
                                return (
                                    <>
                                        <li>{item}</li>
                                    </>
                                )
                            }) : null}
                            {data ? data?.assessment?.Assessments_Types?.split(',')?.map((item) => {
                                return (
                                    <>
                                        <li>{item}</li>
                                    </>
                                )
                            }) : null}
                            <span onClick={() => item ? handleBookmark(item) : handleUnbookmark(data?.assessment?.Assessment_id)}
                            className='heart-icon'>
                                <i class={bookmark || data ? "ri-heart-fill" : "ri-heart-3-line"} >
                                </i>
                            </span>
                        </ul>
                    </td>
                </tr>
            </>
        </>
    )
}

export default AssessmentTable