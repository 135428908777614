import { apiSlice } from "../apiSlice";

export const assessmentSlice = apiSlice.injectEndpoints({
    endpoints: (builder) => ({
        getAssessmentArea: builder.query({
          query:()=>({
            url:'assessment/get-assessment-area',
          }),
          transformResponse: (response, meta, arg) => {
              return response?.data
            },
        }),
        getAssessmentFilter: builder.query({
            query:(data)=>({
              url:`assessment/get-assessment-details/${data?.assesment_area}/${data?.child_assessment}`,
            }),
            transformResponse: (response, meta, arg) => {
                return response?.data
              },
          }),
        saveAssessment: builder.mutation({
            query: (body) => ({
                url: `assessment/save-bookmark-assessment`,
                method: 'POST',
                body,
                msg: true
            }),
            invalidatesTags: (result, error, args) => {
                return [{
                  type: "savedAssessment",
                  id: "list",
                }]
              }
        }),
        getSavedAssessment: builder.query({
            query:()=>({
              url:'assessment/get-bookmark-assessment',
            }),
            transformResponse: (response, meta, arg) => {
                return response?.data
              },
              providesTags: (result, error, args) => {
                return [
                  ...result?.map(({ assessment_id }) => ({
                    type: "savedAssessment",
                    id: assessment_id,
                  })),
                  { type: "savedAssessment", id: "list" },
                ]
        
              }
          }),
          unbookmarkAssessment: builder.mutation({
            query: (id)=>({
                url:`assessment/unbookmark-assessment/${id}`,
                method: 'DELETE',
                msg: true
            }),
            invalidatesTags: (result, error, args) => {
                return [{
                  type: "savedAssessment",
                  id: args?.assessment_id,
                }]
              }
          })
    })
})

export const {
useGetAssessmentAreaQuery,
useGetAssessmentFilterQuery,
useSaveAssessmentMutation,
useGetSavedAssessmentQuery,
useUnbookmarkAssessmentMutation
} = assessmentSlice;