import React, { useState } from "react";
import { Row, Col, Container, Image, Button, Form } from "react-bootstrap";
import Alert from "react-bootstrap/Alert";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

//image
import logo from "../../assets/images/main_logo.png";

import { useSelector } from "react-redux";
import { validation } from "./FormValidation";
import { useConfirmMailMutation } from "../../store/user/userApiSlice";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const ConfirmMail = () => {
  const [otp, setOtp] = useState("");
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState({
    call: false,
    success: false,
    msg: "bbbb",
  });

  const user = useSelector((state) => state.user);
  let history = useHistory();
  const [confirmMail] = useConfirmMailMutation();


  const handleOtp = (e) => {
    setOtp(e.target.value);
  };
  const handleSubmit = async (event) => {
    event.preventDefault();
    setFormErrors(validation({ otp }));

    if (Object.keys(validation({ otp })).length === 0) {
      const res = await confirmMail({
        otp: otp,
        email: user?.userDetails?.email,
      });

      const data = res?.data;
      if (res?.data?.status === 1) {
        setIsValid({
          ...isValid,
          call: true,
          success: true,
          msg: data?.message,
        });
        // alert("Registration Completed");
        history.push("/auth/sign-in");
        validation("clear error");
      } else {
        if (res?.error?.status === 500) {
          setIsValid({
            ...isValid,
            call: true,
            success: false,
            msg: res?.error?.data?.message,
          });
        } else {
          res.json().then((data) => {
            setIsValid({
              ...isValid,
              call: true,
              success: false,
              msg: res?.data?.msg,
            });
            // console.log(error);;
          });
        }
      }

      validation("clear error");
    }
  };

  return (
    <>
       <section className="sign-in-page">
        <div>
          <Row className="no-gutters align-items-center" style={{ width: "100%", height:"100vh", marginLeft: "0.1px" }}>
            <Col md="8" className="p-5 d-none d-lg-block">
                <h2 className="text-white fontBold">
                  PA Secondary Transition - Public Square
                </h2>
                <h3 className="text-white mt-2 mb-3">
                 Login and create an account to personalize your experience
                </h3>
                <h4 className="text-white mt-2 mb-3">
                  Connect, Collect, Contribute
                </h4>
                <ul className="text-white listItems">
                
                <li>Connect with people & events within your community and across the state.</li>
                <li>Collect and save your favorite transition resources & assessments.</li>
                <li>Contribute by sharing resources, articles and stories.</li>
                </ul>
            </Col>
             <Col md="4" className="bg-white p-5" style={{  height:"100vh", display:"flex", flexDirection:"column",justifyContent: "center"}}>
              <div className="sign-in-from">
                {isValid.call &&
                  (isValid.success ? (
                    <Alert variant="success">{isValid.msg}</Alert>
                  ) : (
                    <Alert variant="danger">{isValid.msg}</Alert>
                  ))}
                {/* <Image src={mail} width="80"  alt=""/> */}
                <div style={{ textAlign: "center" }}>
                <Image src={logo} className="img-fluid mb-3" alt="logo" />
                </div>
                <h3 className="mb-0 text text-center" style={{ fontWeight: "600" }}>
                  Verify Mail !
                </h3>
                {user?.userDetails?.user_type === "student" ? <p className="text-center formsSubHeadings text-dark">
                  Your Registration is in the Process. Once your Parent approve your email, You will be able to sign in to the Application.
                <Link to="/auth/sign-in" className="buttonLink text-center" style={{display:"inline-block", background: '#48b8ab', width: '180px',    padding: '5px',    color: '#fff',    margin: '10px'}}>Go to Sign in Page</Link></p> : <p className="text-center formsSubHeadings text-dark">
                  A verification number has been sent to your {user?.userDetails?.email} Please
                  check your email.
                </p>}
                {user?.userDetails?.user_type !== "student" && <Form.Group>
                  <Form.Label className="textBlue">Enter 4 digit verification number</Form.Label>
                  <Form.Control
                    type="email"
                    className="mb-0"
                    id="exampleInputEmail1"
                    placeholder="Enter verification number"
                    onChange={handleOtp}
                  />
                  <p className="errortext">{formErrors.otp}</p>
                </Form.Group>}
                {user?.userDetails?.user_type !== "student" && <div className="d-inline-block w-100">
                  <Button
                    type="button"
                    onClick={handleSubmit}
                    variant="primary"
                    className="float-end"
                  >
                    <span className="d-flex align-items-center">
                      {/* <i className="material-symbols-outlined md-18 me-1">
                        home
                      </i> */}
                      Verify
                    </span>
                  </Button>
                </div>}
              </div>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default ConfirmMail;
