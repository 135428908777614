import React, { useState, useEffect } from 'react';
import { Alert, Container, Form, Col, Row } from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";
import Card from '../../../components/Card';
import defaultBg from "../../../assets/images/bg/bg.jpg";
import Loading from '../Loading';
import config from "../../../store/config.json"
import ResourceCard from './ResourceCard';
import { useDeleteResourcesMutation } from '../../../store/resourcesApi/resourceSlice';
// import { useGetResourcesQuery } from '../../../store/resourcesApi/resourceSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './resources.scss';

const MyResources = () => {
    const [myResources, setMyResources] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [loading, setLoading] = useState(true);
    const [deleteResource] = useDeleteResourcesMutation()
    const history = useHistory()

    const getSavedResource = () => {
        fetch(
            `${config.WEBPORTAL_URL}/api/bookmark/get-bookmark`,
            {
                method: "GET",
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        setMyResources(data)
                        setLoading(false);
                        // refetch()
                    });
                } else if (response?.status === 500) {
                    setLoading(false)
                }
            })
            .catch((error) => { });
    };

    useEffect(() => {
        getSavedResource();
    }, [])

    const handleUnsaveBookmark = async (id) => {
        let result = await deleteResource(id)
        if (result?.data?.status === 1) {
            toast(result?.data?.message, {
                position: "top-right",
                autoClose: 1000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                type: "success",
            });
            getSavedResource();
            // setTimeout(() => {
            //     history.push('/dashboards/app/resources/resources')
            // }, 1500);
        }
    }


    return (
        <>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body className="d-flex justify-content-between grp-card-head allResourceBox" style={{ position: "relative" }}>
                                <div className="allResource" style={{ position: "relative" }}>
                                    <h4 className="grp-text">My Favourites</h4>
                                </div>

                                <div className="table_search resourceSearch">
                                    <Form action="#" className="searchbox">
                                        <div className="search-link">
                                            <span className="material-symbols-outlined">search </span>
                                        </div>
                                        <Form.Control
                                            type="text"
                                            className="text search-input bg-soft-primary connection-input"
                                            placeholder="Search..."
                                            onChange={(event) => setSearchTerm(event.target.value)}
                                        />
                                    </Form>
                                </div>
                                <button type="button" className="btn btn-primary grp-btn">
                                    <Link to="/dashboards/app/resources/resources" style={{ color: "white" }} className="">
                                        All Resources
                                    </Link>
                                </button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <div className="container" style={{ color: "white" }}>
                <img
                    src="https://www.pasecondarytransition.com/assets21/webpimages/resources-ban.webp"
                    style={{ width: "100%", height: "auto", borderRadius: "5px" }}
                />
            </div>

            <div id="content-page" className="content-page resourcesTxt">
                <Container>
                    {loading ? <Loading /> : <>
                        <div className="d-grid gap-lg-2 gap-xl-4 gap-xxl-4 d-grid-template-19rem-19rem mb-3 justify-content-center"> 
                            {myResources?.data?.filter((item) => {
                                if (searchTerm === "") {
                                    return item;
                                } else if (item?.bookmarks?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())) {
                                    return item;
                                }
                            })?.map((item, i) => {
                                if (item?.length < 1) {
                                    setLoading(false)
                                }
                                return (
                                    <div className="mb-lg-0 mb-lg-2 mb-md-2 mb-2 resourcesBoxItem"
                                        style={{
                                            height: "16.4rem",
                                            border: "1px solid #CED4DA",
                                            borderRadius: "10px",
                                            backgroundColor: "#F7F7F7",
                                            position: "relative"
                                        }}>
                                        <a href={item?.bookmarks?.pdf_link} target="_blank">
                                            <div className="bg-image" style={{ height: "12rem", position: "relative", }}>
                                                <img
                                                    src={item?.bookmarks?.image}
                                                    className="img-fluid w-100"
                                                    style={{
                                                        height: "100%",
                                                        borderRadius: "10px 10px 0 0"
                                                    }}
                                                    alt="group-bg"
                                                />
                                            </div>
                                        </a>
                                        <p className=""
                                            style={{
                                                position: "absolute",
                        background: "rgb(45 205 178)",
                        color: "rgb(255, 255, 255)",
                        top: "10px",
                        fontWeight: "bold",
                        padding: "2px 10px",
                        fontSize: "12px",
                        boxShadow: "-1px 4px 4px 1px #07070724",
                    }}>
                                            {item?.bookmarks?.resource_type}
                                        </p>
                                        <a href={item?.bookmarks?.pdf_link} target="_blank">
                                        <p className='text-center'
                                             style={{
                                                fontWeight: "600",
                                                padding: "10px 10px 0px 10px",
                                                color: "#20639A",
                                                fontSize: "17px",
                                                lineHeight:  "22px",
                                            }}>
                                            {item?.bookmarks?.title}
                                        </p>
                                        </a>
                                        <span onClick={() => handleUnsaveBookmark(item?._id)}
                                            style={{
                                                fontSize: "25px",
                                                color: "rgb(255 255 255)",
                                                position: "absolute",
                                                left: "270px",
                                                top: "0px"
                                            }}>
                                            <i className={"ri-heart-3-fill"}
                                            style={{
                                                cursor: "pointer",
                                            }}
                                            >
                                            </i>
                                        </span>
                                    </div>
                                )
                            })}
                        </div>
                    </>}
                    <ToastContainer />
                </Container>
            </div>
        </>
    )
}

export default MyResources