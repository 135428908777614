import React, { useState } from 'react'
import { Card } from 'react-bootstrap';
import { Link, useHistory } from "react-router-dom";
import Loading from '../Loading';
import config from "../../../store/config.json"
import { useDispatch } from "react-redux";
import { groupDetail as groupDetailActions } from "../../../store/groups/groupDetailSlice";
import { ToastContainer, toast } from 'react-toastify';

const MemberCard = ({ user, addMember, removeMember, groupDetail }) => {
  const [loading, setLoading] = useState(false)
  const history = useHistory()
  const group_id=groupDetail[0]?._id
  const dispatch = useDispatch();

  const getGroupMembers = async () => {
    let response = await fetch(
      `${config.WEBPORTAL_URL}/api/groups/get-group-members/${group_id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
    if (response) {
      if (response.ok) {
        return response.json()

      } else {
        toast(response?.error?.error || "Group members fetched failed!"
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
      }
    }

  };

  const getGroupDetail = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/groups/get-group-details-by-groupId/${group_id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then(async (data) => {
            let membersRes = await getGroupMembers()
            let allMembersRes = membersRes?.data.map((item) => {
              return {
                image
                  :
                  item?.user?.profile_picture,
                ref
                  :
                  item?.user?._id,
                username
                  :
                  item?.user?.first_name + " " + item?.user?.last_name,
                _id
                  :
                  item?._id
              }
            })
            data.data[0].members_subset=allMembersRes
            // setGroupdetailData(data.data[0])
            dispatch(groupDetailActions(data.data));
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  const memberAdd = async (member_id) => {
    setLoading(true)
    let result = await addMember({
      group_id: groupDetail[0]?._id,
      member_id,
    });
    if (result?.data?.status === 1) {
      getGroupDetail()
      setLoading(false)
      // history.push(`/dashboards/app/group-detail/${groupDetail[0]?._id}`);

    }
  };

  const memberRemove = async (member_id) => {
    setLoading(true)
    let result = await removeMember({
      group_id: groupDetail[0]?._id,
      member_id,
    });
    if (result?.data?.status === 1) {
      setLoading(false)
      getGroupDetail()
      // history.push(`/dashboards/app/group-detail/${groupDetail[0]?._id}`);
    }
  };

  return (
    <>
      {loading ? <Loading /> :
        <>{user && <Card>
          <Card.Body>
            {/* <ul className="request-list list-inline m-0 p-0"> */}
              <li className="d-flex align-items-center justify-content-between flex-wrap">
                <div className="user-img img-fluid flex-shrink-0">
                  <Link to={`/dashboard/app/profile-view/${user?.ref}`}>
                    <img
                      src={
                        user?.profile_picture
                          ? user.profile_picture
                          : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                      }
                      alt="story-img"
                      className="rounded-circle avatar-40"
                    />
                  </Link>
                </div>
                <div className="flex-grow-1 ms-3">
                  <Link to={`/dashboard/app/profile-view/${user?.ref}`}>
                    <h6 style={{fontWeight:"600"}}>{user?.username}</h6>
                  </Link>
                  {/* <p className="mb-0">40 friends</p> */}
                </div>
                <div className="d-flex align-items-center mt-2 mt-md-0">
                  <div className="confirm-click-btn">
                    {groupDetail[0]?.members_subset.filter(
                      (ele) => ele.ref === user.ref
                    ).length > 0 ? (
                      <div
                        className="btn btn-secondary rounded"
                        data-extra-toggle="delete"
                        data-closest-elem=".item"
                        onClick={() => memberRemove(user.ref)}
                      >
                        Remove
                      </div>
                    ) : (
                      <div
                        className="btn btn-primary rounded confirm-btn"
                        onClick={() => memberAdd(user.ref)}
                      >
                        Add in Group
                      </div>
                    )}
                  </div>
                </div>
              </li>
            {/* </ul> */}
          </Card.Body>
        </Card>}</>}
    </>
  )
}

export default MemberCard