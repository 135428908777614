import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form ,Spinner} from "react-bootstrap";
import Card from "../../../components/Card";
import {
  useGetInvitationsQuery,
  useUpdateStatusMutation,
} from "../../../store/connection/connectionApiSlice";
import { useGetUserDetailsQuery } from "../../../store/user/userApiSlice";
import { useSelector } from "react-redux";
import config from "../../../store/config.json";

// img

//Sweet alert
import Swal from "sweetalert2";
import { setUser } from "../../../store/user/action";
import { useDispatch } from "react-redux";
import InviationCard from "./InviationCard";

const FriendReqest = () => {
  const userInfo = useSelector((state) => state.user.userDetails);
  const dispatch = useDispatch();
  const [allInvitations, setAllInvitations] = useState([]);
  const [displayInvitations, setDisplayInvitations] = useState([]);
  const [invitationCount, setInvitationCount] = useState(0);
  const { data, refetch } = useGetInvitationsQuery();
  const { data: detailData, currentData } = useGetUserDetailsQuery("");
  const [loadingMore, setLoadingMore] = useState(false); // Loading more data on scroll
  const [itemsToDisplay, setItemsToDisplay] = useState(10);
  const itemsPerScroll = 2;
  // const history = useHistory();

  // const { data: detailData } = useGetUserDetailsQuery(null);

  const getUserInfo = () => {
    fetch(`${config.WEBPORTAL_URL}/api/users/get-user-details`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            // console.log(data?.data,"logger");
            dispatch(setUser({ ...userInfo, ...data?.data[0] }));
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  const getAllInvitations = () => {
    fetch(`${config.WEBPORTAL_URL}/api/connections/get-invitation-count`, {
      method: "GET",
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    })
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setAllInvitations(data?.data?.data);
            setDisplayInvitations(data?.data?.data);
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getAllInvitations();
    getUserInfo();
  }, []);

  useEffect(() => {
    // const resp=await refetch()
    setInvitationCount(userInfo?.invitation_count);
  }, [userInfo?.invitation_count]);

  // useEffect(() => {
  //   if (data && data?.status === 1 && data?.data?.data?.length > 0) {
  //     setAllInvitations(data?.data?.data);
  //     setDisplayInvitations(data?.data?.data);
  //   }
  // }, [data]);

  const onHandleSearchChange = async (e) => {
    let invitationSearchData = e.target.value;
    e.preventDefault();
    var tempArr = [];
    allInvitations?.map((item) => {
      let fullName = item.requester?.username;
      const lData = fullName.toLowerCase();
      if (lData.includes(invitationSearchData.toLowerCase())) {
        tempArr.push(item);
      }
    });

    setDisplayInvitations(tempArr);
  };

  // const questionAlert = () => {
  //   const swalWithBootstrapButtons = Swal.mixin({
  //     customClass: {
  //       cancelButton: "btn btn-outline-primary btn-lg ms-2",
  //       confirmButton: "btn btn-primary btn-lg",
  //     },
  //     buttonsStyling: false,
  //   });

  //   swalWithBootstrapButtons
  //     .fire({
  //       title: "Are you sure?",
  //       text: "You won't be able to revert this!",
  //       icon: "warning",
  //       showCancelButton: true,
  //       cancelButtonText: "cancel",
  //       confirmButtonText: "Yes, delete it!",

  //       reverseButtons: false,
  //       showClass: {
  //         popup: "animate__animated animate__zoomIn",
  //       },
  //       hideClass: {
  //         popup: "animate__animated animate__zoomOut",
  //       },
  //     })
  //     .then((result) => {
  //       if (result.isConfirmed) {
  //         swalWithBootstrapButtons.fire({
  //           title: "Deleted!",
  //           text: "Your Request has been deleted.",
  //           icon: "success",
  //           showClass: {
  //             popup: "animate__animated animate__zoomIn",
  //           },
  //           hideClass: {
  //             popup: "animate__animated animate__zoomOut",
  //           },
  //         });
  //       } else if (
  //         /* Read more about handling dismissals below */
  //         result.dismiss === Swal.DismissReason.cancel
  //       ) {
  //         swalWithBootstrapButtons.fire({
  //           title: "Your Request is safe!",
  //           showClass: {
  //             popup: "animate__animated animate__zoomIn",
  //           },
  //           hideClass: {
  //             popup: "animate__animated animate__zoomOut",
  //           },
  //         });
  //       }
  //     });
  // };
  const handleScroll = () =>{
  const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
  if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
    setLoadingMore(true);
    setTimeout(() => {
      setItemsToDisplay(prevItems => prevItems + itemsPerScroll);
      setLoadingMore(false);
    }, 1000); // Simulating loading delay, replace with your actual data fetching logic
  }
  };
  
  useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
  }, [loadingMore]);

  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body className="d-flex justify-content-between align-item-center">
                <div className="">
                  <h4 className="grp-text">
                    Invitations ({invitationCount < 0 ? 0 : invitationCount})
                  </h4>
                </div>
                <div className="table_search">
                  <Form action="#" className="searchbox">
                    <div className="search-link">
                      <span className="material-symbols-outlined">search</span>
                    </div>
                    <Form.Control
                      type="text"
                      className="text search-input bg-soft-primary connection-input"
                      placeholder="Search..."
                        onChange={(event) => onHandleSearchChange(event)}
                    />
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row>

          {displayInvitations?.slice(0,itemsToDisplay).map((val) => {
            let user = val.requester.ref === userInfo?._id ? val.requested : val?.requester
            return (<>
              <Col lg={displayInvitations?.length > 1 ? 6 : 12}>
                {displayInvitations?.length > 0 ? <Card>
                  <Card.Body >
                    {/* <ul className="request-list list-inline m-0 p-0"> */}
                      <InviationCard
                        user={user}
                        displayInvitations={displayInvitations}
                        invitationCount={invitationCount}
                        setInvitationCount={setInvitationCount}
                        setDisplayInvitations={setDisplayInvitations}
                        refetch={refetch}
                        dispatch={dispatch}
                        setUser={setUser}
                        val={val} />
                    {/* </ul> */}
                  </Card.Body>
                </Card> : " "}
              </Col>
            </>
            )
          })}
                    {loadingMore && itemsToDisplay<displayInvitations.length && <Col lg={12} className="text-center mt-3 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </Col>
}

        </Row>
      </Container>
    </>
  );
};

export default FriendReqest;
