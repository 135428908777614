import React, { useEffect, useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./index.scss";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../store/user/action";
import { useLoginMutation } from "../../store/user/userApiSlice";
//swiper

import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

//img
import logo from "../../assets/images/main_logo.png";

import { subscribeUser } from "../../subscription";

import { validation } from "./FormValidation";

SwiperCore.use([Navigation, Autoplay]);

const SignIn = () => {
  const [formErrors, setFormErrors] = useState({});
  const [isSubmit, setIsSubmit] = useState(false);
  const [visiblePassword, setVisiblePassword] = useState(false);
  const postDetailUrl = localStorage.getItem('urlRedirect')
  const [userInput, setUserInput] = useState({
    email: "",
    password: "",
  });
  const [isValid, setIsValid] = useState({
    call: false,
    success: false,
    msg: "bbbb",
  });
  const user = useSelector((state) => state.user);

  let history = useHistory();
  const [login] = useLoginMutation();
  const handleUserInput = (e) => {
    const { value, name } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };

  let dispatch = useDispatch();
  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setIsSubmit(true);
    setFormErrors(validation(userInput));

    if (Object.keys(validation(userInput)).length === 0) {
      const res = await login(userInput);
      console.log("ressss",res)
      const data = res?.data;
      if (res?.data?.status === 1) {
        subscribeUser(
          data?.data.public_vapid_key,
          data?.data._id
        );
        setIsValid({
          ...isValid,
          call: true,
          success: true,
          msg: data?.message,
        });
        localStorage.setItem("token", data?.data?.token);
        dispatch(setUser(data?.data));
        localStorage.setItem("userDetail", JSON.stringify(data?.data));
        if(postDetailUrl){
          localStorage.removeItem('urlRedirect');
          history.push(postDetailUrl)
        } else {
        history.push("/");
        }
        validation("clear error");
      } else {
        if (res?.error?.status === 500) {
          setIsValid({
            ...isValid,
            call: true,
            success: false,
            msg: res?.error?.data?.message,
          });
          console.log(res?.error?.data?.message);
        } else {
          res?.json().then((data) => {
            setIsValid({
              ...isValid,
              call: true,
              success: false,
              msg: res?.error?.data?.message
                ? res?.error?.data?.message
                : "user is blocked by admin",
            });
          });
        }
      }
      validation("clear error");
    }
  };

  // useEffect(() => {
  //  console.log(user,"user")
  // }, [user?.data]);

  // useEffect(() => {
  //   if (Object.keys(formErrors).length === 0 && isSubmit) {
  //   }
  // }, [formErrors]);

  const handleVisible = (value) => {
    setVisiblePassword(!visiblePassword);
  };

  return (
    <>
      <section className="sign-in-page">
        <div>
          <Row className="no-gutters align-items-center" style={{ width: "100%", height:"100vh", marginLeft: "0.1px" }}>
            <Col md="8" className="p-5 d-none d-lg-block">
                <h2 className="text-white fontBold">
                  PA Secondary Transition - Public Square
                </h2>
                <h3 className="text-white mt-2 mb-3">
                 Login and create an account to personalize your experience
                </h3>
                <h4 className="text-white mt-2 mb-3">
                  Connect, Collect, Contribute
                </h4>
                <ul className="text-white listItems">
                
                <li>Connect with people & events within your community and across the state.</li>
                <li>Collect and save your favorite transition resources & assessments.</li>
                <li>Contribute by sharing resources, articles and stories.</li>
                </ul>
            </Col>
            <Col md="4" className="bg-white p-5" style={{  height:"100vh", display:"flex", flexDirection:"column",justifyContent: "center"}}>
                {isValid.call &&
                  (isValid.success ? (
                    <Alert variant="success">{isValid.msg}</Alert>
                  ) : (
                    <Alert variant="danger">{isValid.msg}</Alert>
                  ))}
                <div style={{ textAlign: "center" }}>
                  <Image src={logo} className="img-fluid mb-2" alt="logo" />
                </div>
                <div className="d-block d-lg-none"><h2 className="textBlue text-center fontBold">
                  Public Square
                </h2>
              
                <h5 className="textBlue text-center mb-2">
                  Connect, Collect, Contribute
                </h5><hr style={ {border: 'solid 1px #ddd'}} /></div>
                
                <h3 className="mb-0 text text-center" style={{ fontWeight: "600" }}>
                  Sign in
                </h3>
                <p className="text-center formsSubHeadings text-dark">
                  Login and create an account to personalize your experience
                </p>
                <Form className="mt-4" noValidate onSubmit={handleSubmit}>
                  <Form.Group className="form-group">
                    <Form.Label className="textBlue">Email / Username</Form.Label>
                    <Form.Control
                      required
                      type="email"
                      className="mb-0"
                      id="exampleInputEmail1"
                      placeholder="Enter Email / Username"
                      name="email"
                      onChange={handleUserInput}
                    />
                    <p className="errortext">{formErrors.email}</p>
                  </Form.Group>
                  <Form.Group className="form-group" style={{ position: "relative" }}>
                    <Form.Label className="textBlue">Password</Form.Label>
                    <Link to="/auth/forgotpw" className="float-end">
                      Forgot Password?
                    </Link>
                    <Form.Control
                      required
                      type={visiblePassword ? "text" : "password"}
                      className="mb-0"
                      id="exampleInputPassword1"
                      placeholder="Enter Password"
                      name="password"
                      onChange={handleUserInput}
                    />
                    <div
                      style={{ position: "absolute", right: "4%", bottom: "7%", cursor: "pointer" }}
                    >
                      {(visiblePassword === false) ? <i onClick={() => { handleVisible("current_password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("current_password") }} class="ri-eye-off-line"></i>}
                    </div>
                  </Form.Group>
                  <p className="errortext">{formErrors.password}</p>
                  <div className="d-inline-block w-100">
                    <Form.Check className="d-inline-block mt-2 pt-1">
                      <Form.Check.Input
                        type="checkbox"
                        className="me-2"
                        id="customCheck11"
                      />
                      <Form.Check.Label>Remember Me</Form.Check.Label>{" "}
                    </Form.Check>
                    <Button
                      variant="primary"
                      type="submit"
                      className="float-end"
                    >
                      Sign In
                    </Button>
                  </div>
                  <hr></hr>
                  <div className="sign-info text-center signUpButtonNew">
                    <span className="dark-color line-height-2 text-dark text-bold text-center mb-2 fontBold">
                      Don't have an account?{" "} </span>
                      <Link to="/auth/sign-up" className="buttonLink text-center" style={{display:"block"}}>Sign Up</Link>
                   
                  </div>
                </Form>
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default SignIn;