import React, { useState, useRef, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../store/user/action";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Alert,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import {
  useEditUserDetailsMutation,
  useFileUploadMutation,
  useChangePasswordMutation,
  useGetUserDetailsQuery,
} from "../../store/user/userApiSlice";
import img1 from "../../assets/images/user/11.png";

const UserProfileEdit = () => {
  const dispatch = useDispatch();
  const [userData, setUserData] = useState({});
  const [passwordData, setPasswordData] = useState({});
  const [detail,setDetails] = useState()
  const [error, setError] = useState("");
  const [userError, setUserError] = useState("");
  const [showA1, setShowA1] = useState(false);
  const [profilePic, setProfilePic] = useState({ icon: "", url: "" });
  const [profileBgPic, setProfileBgPic] = useState({ icon: "", url: "" });
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);
  const inputRef = useRef(null);
  const profileBgRef = useRef(null);
  const localStorageUser = localStorage.getItem("userDetail");
  const [editUserDetails] = useEditUserDetailsMutation();
  const [fileUpload] = useFileUploadMutation();
  const [changePassword] = useChangePasswordMutation();
  const { data: detailData } = useGetUserDetailsQuery(null);
  const history = useHistory();

  const userInfo = useSelector((state) => state.user.userDetails);

  useEffect(() => {
    if (detailData) {
      setDetails(detailData.data[0]);
      dispatch(setUser({ ...userInfo, ...detailData.data[0] }));
    }
  }, [detailData]);

  useEffect(() => {
    setUserData({
      first_name: userInfo?.first_name,
      last_name: userInfo?.last_name,
      user_type: userInfo?.user_type,
      email: userInfo?.email,
      phone: userInfo?.phone,
      profile_picture: detail?.profile_picture,
      backgournd_picture: detail?.backgournd_picture,
    });
  }, [detail,userInfo]);

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (!userData?.first_name) {
      setUserError("First name is required");
      return;
    }
    if (!userData?.last_name) {
      setUserError("Last name is required");
      return;
    }
    // if (!userData?.phone) {
    //   setUserError("Phone number is required");
    //   return;
    // }

    // if (userData?.phone.length < 10) {
    //   setUserError("Phone number should be greater than 10 numbers");
    //   return;
    // }
    const res = await editUserDetails({
      first_name: userData?.first_name,
      last_name: userData?.last_name,
      // user_type: "trainer",
      // phone: userData?.phone,
      profile_picture: userData?.profile_picture,
      backgournd_picture: userData?.backgournd_picture,
      location: userData?.location,
      work: userData?.work
    });
    if (res?.data?.status === 1) {
      setShowA1(true)
      dispatch(setUser({ ...userInfo, ...userData }))
      localStorage.setItem("token", res?.data?.data?.token);
      setTimeout(() => {
        history.push("/dashboard/app/profile");
      }, 1200)
    }
  };

  const UserPlaceholder = useSelector((state) => state.user.userDetails)


  const handlePasswordSubmit = async (e) => {
    e.preventDefault();

    if (!passwordData?.current_password) {
      setError("Current Password is required");
      return;
    }
    if (!passwordData?.new_password) {
      setError("New Password is required");
      return;
    }
    if (!passwordData?.confirm_password) {
      setError("Verify Password is required");
      return;
    }
    if (
      passwordData?.current_password.length < 6 ||
      passwordData?.new_password.length < 6 ||
      passwordData?.confirm_password.length < 6
    ) {
      setError("Password should be greater than 6 characters");
      return;
    }
    if (passwordData?.new_password !== passwordData?.confirm_password) {
      setError("New Password is not equal to Verify Password");
      return;
    }

    const res = await changePassword(passwordData);
    if (res?.data?.status === 1) {
      localStorage.clear();
      history.push("/auth/sign-in");
    } else {
      if (res?.error?.status === 500) {
        setError(res?.error?.data?.message);
      } else {
        setError(res?.error?.data?.data[0]?.msg);
      }
    }
  };

  const handleChange = (value) => {
    setUserError("");
    setUserData({ ...userData, [value.target.id]: value.target.value });
    setShowA1(false)
  };

  const handlePasswordChange = (value) => {
    setError("");
    setPasswordData({ ...passwordData, [value.target.id]: value.target.value });
  };

  const handleUpload = async (event) => {
    if (event.target.id === "profile-pic") {
      let url = "";
      let multerData = new FormData();
      multerData.append("file", event.target.files[0]);
      let result = await fileUpload(multerData);
      if (result?.data?.status === 1) {
        url = result?.data?.data?.data;
        setUserData({ ...userData, profile_picture: url });
      }
      setProfilePic({
        icon: event.target.files[0],
        url: url,
      });
      setUserData({ ...userData, profile_picture: url, });
    }
    if (event.target.id === "profile-Bg-pic") {
      let url = "";
      let multerData = new FormData();

      multerData.append("file", event.target.files[0]);
      let result = await fileUpload(multerData);
      if (result?.data?.status === 1) {
        url = result?.data?.data?.data;
        setUserData({ ...userData, backgournd_picture: url });
      }
      setProfileBgPic({
        icon: event.target.files[0],
        url: url,
      });
      setUserData({ ...userData, backgournd_picture: url });
    }
  };
  const handleVisible = (value) => {
    if (value === "current_password") {
      setShowPassword(!showPassword);
    }
    if (value === "new_password") {
      setShowNewPassword(!showNewPassword);
    }
    if (value === "confirm_password") {
      setShowConfirmPassword(!showConfirmPassword);
    }
  };
  var userType = "family"
  if(UserPlaceholder?.user_type === "family"){
    userType="family/caregiver"
  }else if(UserPlaceholder?.user_type === "agency"){
    userType = "agency/community partner"
  }else{
    userType= UserPlaceholder?.user_type
  }
  return (
    <>
      <Container>
        <Tab.Container defaultActiveKey="first">
          {/* <UiAlerts/> */}
          <Row>
            <Col lg="12">
              <Card>
                <Card.Body className="p-0">
                  <div>
                    <Nav
                      as="ul"
                      variant="pills"
                      className="iq-edit-profile row " 
                    >
                      <Nav.Item as="li" className="col-md-3 p-0">
                        <Nav.Link eventKey="first" role="button">
                          Personal Information
                        </Nav.Link>
                      </Nav.Item>
                      <Nav.Item as="li" className="col-md-3 p-0">
                        <Nav.Link eventKey="second" role="button">
                          Change Password
                        </Nav.Link>
                      </Nav.Item>
                      {/* <Nav.Item as="li" className="col-md-3 p-0">
                                      <Nav.Link  eventKey="third" role="button">
                                          Email and SMS
                                      </Nav.Link>
                                  </Nav.Item>
                                  <Nav.Item as="li" className="col-md-3 p-0">
                                      <Nav.Link eventKey="fourth" role="button">
                                          Manage Contact
                                      </Nav.Link>
                                  </Nav.Item> */}
                    </Nav>
                  </div>
                </Card.Body>
              </Card>
            </Col>
            <Col lg={12}>
              {/* <div className="iq-edit-list-data"> */}
              <Tab.Content>
                <Tab.Pane eventKey="first" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Personal Information</h4>
                      </div>
                    </Card.Header>

                    <Card.Body>
                      <Form onSubmit={(e) => handleSubmit(e)}>
                        <Col lg={12}>
                          <Row className="align-items-center">
                            <Form.Group className="form-group col-sm-6">
                              <div>Profile Picture : </div>
                              <div className="profile-img-edit">
                                <img style={{ height: "150px", width: "150px" }}
                                  className="profile-pic"
                                  src={profilePic?.url ? profilePic?.url : userData?.profile_picture ? userData?.profile_picture : img1}
                                  alt="profile-pic"
                                />
                                <div className="p-image">
                                  <i
                                    className="ri-pencil-line upload-button text-white"
                                    onClick={() => inputRef.current?.click()}
                                  ></i>
                                  <input
                                    className="file-upload"
                                    id="profile-pic"
                                    type="file"
                                    ref={inputRef}
                                    accept="image/*"
                                    onChange={(event) => handleUpload(event)}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                            <Form.Group className="form-group col-sm-6">
                              <div>Cover Picture : </div>
                              <div className="" >
                                <img
                                  className=""
                                  style={{ width: "100%", height: "auto", borderRadius: "5px", marginTop: "", position: "relative" }}
                                  src={profileBgPic?.url ? profileBgPic?.url : userData?.backgournd_picture ? userData?.backgournd_picture : img1}
                                  alt="profile-pic"
                                />
                                <div className="p-image" style={{ position: "absolute", bottom: "59%", right: "3%" }}>
                                  <i
                                    className="ri-pencil-line upload-button text-white"
                                    onClick={() => profileBgRef.current?.click()}
                                  ></i>
                                  <input
                                    className="file-upload"
                                    id="profile-Bg-pic"
                                    type="file"
                                    ref={profileBgRef}
                                    accept="image/*"
                                    onChange={(event) => handleUpload(event)}
                                  />
                                </div>
                              </div>
                            </Form.Group>
                          </Row>
                        </Col>
                        <Row className="align-items-center">
                          {userError && (
                            <p className="errortext">{userError}</p>
                          )}
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label
                              htmlFor="first_name"
                              className="form-label"
                            >
                              First Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="first_name"
                              defaultValue={UserPlaceholder?.first_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label
                              htmlFor="last_name"
                              className="form-label"
                            >
                              Last Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="last_name"
                              defaultValue={UserPlaceholder?.last_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label htmlFor="uname" className="form-label">
                              User Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="uname"
                              placeholder={UserPlaceholder?.email}
                              disabled
                            />
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6 ">
                            <Form.Label htmlFor="email" className="form-label">
                              Email:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder={UserPlaceholder?.email}
                              onChange={handleChange}
                              disabled
                            />
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label className="form-label">
                              User Role:
                            </Form.Label>
                            <Form.Select
                              className="form-select"
                              aria-label="Default select example 2"
                              disabled
                              style={{textTransform:"capitalize"}}
                            >
                              <option >{userType}</option>
                              <option>63 </option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6 ">
                            <Form.Label htmlFor="location" className="form-label">
                              Location/Region:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="location"
                              defaultValue={UserPlaceholder?.location}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {userInfo?.user_type === "educator" || userInfo?.user_type === "agency" ? <Form.Group className="form-group col-sm-6 ">
                            <Form.Label htmlFor="work" className="form-label">
                              Work:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="work"
                              defaultValue={UserPlaceholder?.work}
                              onChange={handleChange}
                            />
                          </Form.Group> : ""}
                        </Row>
                        <Alert
                          variant="alert alert-success"
                          show={showA1}
                          onClose={() => setShowA1(false)}
                          dismissible
                        >
                          <span>
                            <i className="fas fa-thumbs-up"></i>
                          </span>
                          <span> Profile edit successfully!</span>
                        </Alert>
                        <Button type="submit" className="btn btn-primary me-2" >
                          Submit
                        </Button>
                        <Button type="reset" className="btn border-0 bg-success cancel-btn" onClick={()=>history.goBack()}>
                          Cancel
                        </Button>
                      </Form>

                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="second" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="iq-header-title">
                        <h4 className="card-title">Change Password</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      {error && <p className="errortext">{error}</p>}
                      <Form onSubmit={(e) => handlePasswordSubmit(e)}>
                        <Form.Group className="form-group" style={{ position: "relative" }}>
                          <Form.Label
                            htmlFor="current_password"
                            className="form-label"
                          >
                            Current Password:
                          </Form.Label>
                          {/* <Link to="#" className="float-end">
                            Forgot Password
                          </Link> */}

                          <Form.Control
                            type={showPassword ? "text" : "password"}
                            className="form-control"
                            id="current_password"
                            onChange={handlePasswordChange}
                            defaultValue=""
                          />
                          <div
                            style={{ position: "absolute", right: "1.5%", bottom: "7%", cursor: "pointer" }}
                          >
                            {(showPassword === false) ? <i onClick={() => { handleVisible("current_password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("current_password") }} class="ri-eye-off-line"></i>}
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group" style={{ position: "relative" }}>
                          <Form.Label
                            htmlFor="new_password"
                            className="form-label"
                          >
                            New Password:
                          </Form.Label>
                          <Form.Control
                            type={showNewPassword ? "text" : "password"}
                            className="form-control"
                            id="new_password"
                            defaultValue=""
                            onChange={handlePasswordChange}
                          />
                          <div
                            style={{ position: "absolute", right: "1.5%", bottom: "7%", cursor: "pointer" }}
                          >
                            {(showNewPassword === false) ? <i onClick={() => { handleVisible("new_password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("new_password") }} class="ri-eye-off-line"></i>}
                          </div>
                        </Form.Group>
                        <Form.Group className="form-group" style={{ position: "relative" }}>
                          <Form.Label
                            htmlFor="confirm_password"
                            className="form-label"
                          >
                            Verify Password:
                          </Form.Label>
                          <Form.Control
                            type={showConfirmPassword ? "text" : "password"}
                            className="form-control"
                            id="confirm_password"
                            defaultValue=""
                            onChange={handlePasswordChange}
                          />
                          <div
                            style={{ position: "absolute", right: "1.5%", bottom: "7%", cursor: "pointer" }}
                          >
                            {(showConfirmPassword === false) ? <i onClick={() => { handleVisible("confirm_password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("confirm_password") }} class="ri-eye-off-line"></i>}
                          </div>
                        </Form.Group>
                        <Button type="submit" className="btn btn-primary me-2">
                          Submit
                        </Button>
                        <Button type="reset" className="btn border-0 bg-success" onClick={()=>history.goBack()}>
                          Cancel
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="third" className="fade show">
                  <Card>
                    <Card.Header className="card-header d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Email and SMS</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label
                            className="col-md-3"
                            htmlFor="emailnotification"
                          >
                            Email Notification:
                          </Form.Label>
                          <Form.Check className="col-md-9 form-check form-switch">
                            <Form.Check.Input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked11"
                              defaultChecked
                            />
                            <Form.Check.Label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckChecked11"
                            >
                              Checked switch checkbox input
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label
                            className="col-md-3"
                            htmlFor="smsnotification"
                          >
                            SMS Notification:
                          </Form.Label>
                          <Form.Check className="col-md-9 form-check form-switch">
                            <Form.Check.Input
                              className="form-check-input"
                              type="checkbox"
                              id="flexSwitchCheckChecked12"
                              defaultChecked
                            />
                            <Form.Check.Label
                              className="form-check-label"
                              htmlFor="flexSwitchCheckChecked12"
                            >
                              Checked switch checkbox input
                            </Form.Check.Label>
                          </Form.Check>
                        </Form.Group>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label className="col-md-3" htmlFor="npass">
                            When To Email
                          </Form.Label>
                          <Col md="9">
                            <Form.Check className="form-check">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="flexCheckDefault12"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="flexCheckDefault12"
                              >
                                You have new notifications.
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="email02"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email02"
                              >
                                You're sent a direct message
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                type="checkbox"
                                className="form-check-input"
                                id="email03"
                                defaultChecked
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email03"
                              >
                                Someone adds you as a connection
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Form.Group>
                        <Form.Group className="form-group row align-items-center">
                          <Form.Label className="col-md-3" htmlFor="npass">
                            When To Escalate Emails
                          </Form.Label>
                          <Col md="9">
                            <Form.Check className="form-check">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="email04"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email04"
                              >
                                Upon new order.
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                className="form-check-input"
                                type="checkbox"
                                defaultValue=""
                                id="email05"
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email05"
                              >
                                New membership approval
                              </Form.Check.Label>
                            </Form.Check>
                            <Form.Check className="form-check d-block">
                              <Form.Check.Input
                                type="checkbox"
                                className="form-check-input"
                                id="email06"
                                defaultChecked
                              />
                              <Form.Check.Label
                                className="form-check-label"
                                htmlFor="email06"
                              >
                                Member registration
                              </Form.Check.Label>
                            </Form.Check>
                          </Col>
                        </Form.Group>
                        <Button type="submit" className="btn btn-primary me-2">
                          Submit
                        </Button>
                        <Button type="reset" className="btn bg-soft-danger" onClick={()=>history.goBack()}>
                          Cancel
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
                <Tab.Pane eventKey="fourth" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Manage Contact</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="cno" className="form-label">
                            Contact Number:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="cno"
                            defaultValue="001 2536 123 458"
                          />
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="email" className="form-label">
                            Email:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="email"
                            defaultValue="Bnijone@demo.com"
                          />
                        </Form.Group>
                        <Form.Group className="form-group">
                          <Form.Label htmlFor="url" className="form-label">
                            Url:
                          </Form.Label>
                          <Form.Control
                            type="text"
                            className="form-control"
                            id="url"
                            defaultValue="https://getbootstrap.com"
                          />
                        </Form.Group>
                        <Button type="submit" className="btn btn-primary me-2">
                          Submit
                        </Button>
                        <Button type="reset" className="btn bg-soft-danger" onClick={()=>history.goBack()}>
                          Cancel
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
              {/* </div> */}
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default UserProfileEdit;
