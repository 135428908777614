import React from 'react';

function RenderMetaURL({ title, description, image, url }) {
    return (
        <a href={url} target="_blank" style={{ textDecoration:"none"}}>
            <div className="scrapperBox" style={{border: "0.5px solid #e5e5e5", borderRadius: "5px"}}>
               {image &&  <div >
                    <img src={image} style={{ width: "100%", height: "auto" }}></img>
                </div>}
                <div className="scrtDx" style={{ padding: "10px", backgroundColor: "#f7f7f7"}}>
                    {title && <div className="scrtTitle" style={{ fontWeight: "600", color: "black" }}>{title}</div>}
                    {description && <div className="scrtDescription" style={{ color: "#55505f" }}>{description}</div>}
                </div>
            </div>
        </a>
    )
}

export default RenderMetaURL;