import { createApi, fetchBaseQuery } from "@reduxjs/toolkit/query/react"
import config from "./config.json"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

const baseQuery = fetchBaseQuery({
    baseUrl: `${config.WEBPORTAL_URL}/api`,

    prepareHeaders: (headers, { getState }) => {
        const token = localStorage.getItem('token')

        if (token) {
            headers.set('authorization', `Bearer ${token}`)
        }
        return headers

    }
})

export const toastHandler = (msg, type) => {
    toast(msg, {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type
    });

}
const baseQueryWithReauth = async (args, api, extraOptions) => {

    const result = await baseQuery(args, api, extraOptions)
    if (result?.error?.status === 401) {
        localStorage.clear();
        window.location.href = window.location.origin;
        window.location.reload(false);
    }
    if (result?.meta?.response?.ok && args.msg) {
        toastHandler(result?.data?.message, "success")
    } else if (result?.meta?.response?.status === 500) {
        toastHandler(result?.data?.message, "error")
    } else if (!result?.meta?.response?.ok) {
        toastHandler(result?.data?.message, "warning")
    }
    return result;
}

export const apiSlice = createApi({
    reducerPath: 'api',
    baseQuery: baseQueryWithReauth,
    tagTypes: ["Group", "savedAssessment", "User", "invitationByUserId"],
    keepUnusedDataFor: 2,
    refetchOnMountOrArgChange: 10,
    refetchOnFocus: false,
    refetchOnReconnect: true,
    endpoints: (builder) => ({}),
})
