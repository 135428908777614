import React from 'react'
import { Card } from 'react-bootstrap'

const Document = ({ item, docIcon, pdfIcon }) => {
    return (
        <div>
            <Card>
                <Card.Body>
                    <div style={{ height: "" }}>
                        <img
                            src={item?.file_extension === "pdf" ? pdfIcon : docIcon}
                            style={{ width: "100%", height: "" }}
                        />
                        <a href={item?.file_url}>
                            <p className="pt-2">{item?.file_name}</p>
                        </a>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Document