import React, { useState, useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link } from "react-router-dom";
import Loading from "../Loading";
const ConnectionCard = ({
    user,
    val,
    updateStatus,
    displayConnections,
    setDisplayConnections,
    setUser,
    userInfo,
    connectionCount,
    refetch }) => {
    const [loading, setLoading] = useState(false);
    const [block, setBlock] = useState(false)
    const dispatch = useDispatch();

    const relatedParent = userInfo?.user_type === "student" ? userInfo?.relation?.map((user) => user._id) : []

    useEffect(() => {
        if (userInfo?.user_type === "student" && relatedParent?.includes(user?.ref)) {
            setBlock(true)
        }
    }, [])

    const handleBlock = async (id) => {
        setLoading(true)
        const res = await updateStatus({
            id: id,
            status: "blocked",
        });
        if (res?.data?.status === 1) {
            setLoading(false)
            try {
                let newList = displayConnections.filter((val) => val._id != id);
                setDisplayConnections(newList);
                // userInfo.connection_count =  userInfo.connection_count - 1;
                dispatch(
                    setUser({ ...userInfo, connection_count: connectionCount - 1 })
                );
                refetch();
            } catch (error) {
                console.log(error);
            }
        } else {
            setLoading(false);
        }
    };
    return (
        <>
            {loading ? <Loading /> : <li className="d-flex align-items-center  justify-content-between flex-wrap">
                <div className="user-img img-fluid flex-shrink-0">
                    <Link to={`/dashboard/app/profile-view/${user?.ref}`}>
                        <img
                            src={
                                user?.profile_picture
                                    ? user.profile_picture
                                    : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                            }
                            alt="story-img"
                            className="rounded-circle avatar-40"
                        />
                    </Link>
                </div>
                <div className="flex-grow-1 ms-3">
                    <Link to={`/dashboard/app/profile-view/${user?.ref}`}>
                        <h6 style={{
                            fontWeight: "600",
                        }}>{user?.username}</h6>
                    </Link>
                </div>
                <div className="d-flex align-items-center mt-2 mt-md-0" style={{ width: "79.57px" }}>
                    {!block ? <div
                        className="confirm-click-btn"
                        onClick={() => handleBlock(val?._id)}
                    >
                        <div className="me-3 btn btn-primary rounded confirm-btn" style={{ width: "100%" }}>
                            Block
                        </div>
                    </div> : ""}
                </div>
            </li>}
        </>
    )
}

export default ConnectionCard