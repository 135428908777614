import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useParams, useHistory } from "react-router-dom";
import CommentInput from "../components/CommentInput";
import {
  Row,
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";

import { stateToHTML } from "draft-js-export-html";
import RenderComment from "../views/app/RenderComment";
import { convertFromRaw } from "draft-js";
import { Link } from "react-router-dom";
import Card from "./Card";
import RenderLike from "../views/ui-kit/Like";
import RenderFile from "./RenderFile";
import PostModal from "./PostModal";
import user2 from "../assets/images/user/02.jpg";
import user3 from "../assets/images/user/03.jpg";

import p1 from "../assets/images/page-img/p1.jpg";
import disabled from "../assets/images/page-img/icons8-hide-50-new.png";
import enabled from "../assets/images/page-img/icons8-eye-50-new.png";

import p2 from "../assets/images/page-img/p2.jpg";
import p3 from "../assets/images/page-img/p3.jpg";

import { getDate } from "../utilities/time";
import { get_url_extension } from "../utilities/common";
import { useGetPostsQuery } from "../store/post/postApiSlice";
import RenderMetaURL from "./RenderMetaURL";
import { IoIosShareAlt } from "react-icons/io";
import ShareModal from "./ShareModal";


const RenderURLDetails = ({ urlDetails, key }) => {
  return (
    <div key={`key-${urlDetails?.url}-${key}`} >
      {urlDetails && (
        <a href={urlDetails?.url} target="_blank"  >
          <div>
            <img src={urlDetails?.ogImage}></img>
          </div>
          <div className="scrtDx">
            <div className="scrtTitle">{urlDetails?.title}</div>
            <div className="scrtDescription">{urlDetails?.description}</div>
          </div>
        </a>
      )}
    </div>
  );
};

function FeedBlock({
  postDetails,
  handleLike,
  handleComment,
  handleCommentLike,
  handleReply,
  getPost,
  handleDelete,
  isViewFullPost,
  deleteComment,
  editComment,
  page,
  getAllMedia,
  handleDisable
}) {
  let post_id = postDetails?._id;
  const isAdminUserProfilePage = window.location.pathname.includes("profile-view");
  const [show, setShow] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const userInfo = useSelector((state) => state.user.userDetails);
  const handleShow = () => {
    setShow(true);
  };
  const allRelatedStudents = userInfo?.user_type === "family" ? userInfo?.relation?.map((user) => user?._id) : []
  const onSubmit = () => { };
  let timeObj = getDate(postDetails?.createdAt);

  const convertFromJSONToHTML = (text) => {
    try {
      return { __html: stateToHTML(convertFromRaw(text)) };
    } catch (exp) {
      return { __html: "Error" };
    }
  };

  const getUpdatedHtml = (textEditorData) => {
    function replaceURLs(message) {
      if (!message) return;
      var urlRegex = /(((https?:\/\/)|(www\.))[^\s]+)/g;

      return message.replace(urlRegex, function (url) {
        var hyperlink = url;
        hyperlink = hyperlink.replace("</p>", "");
        if (!hyperlink.match("^https?://")) {
          hyperlink = "http://" + hyperlink;
        }
        return (
          '<a href="' +
          hyperlink +
          '" target="_blank" rel="noopener noreferrer">' +
          url +
          "</a>"
        );
      });
    }
    let text = textEditorData
      ? convertFromJSONToHTML({ entityMap: {}, ...textEditorData })
      : "";
    let urlss = text?.__html?.includes("</a>")
      ? null
      : replaceURLs(text.__html);
    if (urlss) {
      text.__html = urlss;
    }
    return text;
  };

  const handleDeletePost = () => {
    handleDelete(postDetails?._id, postDetails);
  };

  const handleDisablePost = () => {
    //For admin this actually works like disable post
    handleDisable(postDetails?._id, postDetails);
  };

  const canEditPost = () => {
    if (postDetails?.created_by?.ref === userInfo?._id) {
      return true
    } else if (userInfo?.user_type === "family" && allRelatedStudents?.includes(postDetails?.created_by?.ref)) {
      return true
    } else {
      return false
    }
  }

  const canDeletePost = () => {
    if (postDetails?.created_by?.ref === userInfo?._id) {
      return true
    } else if (userInfo?.user_type === "family" && allRelatedStudents?.includes(postDetails?.created_by?.ref)) {
      return true
    }
    // else if (userInfo?.user_type === "admin" ) {
    //   return true
    // } 
    else {
      return false
    }
  }

  const canDisablePost = () => {
    if (postDetails?.created_by?.ref === userInfo?._id) {
      return false
    }
    else if (userInfo?.user_type === "admin") {
      return true
    } else {
      return false
    }
  }
  console.log(postDetails,"xyz")
  return (
    <Col sm={12}>
      <Card className=" card-block card-stretch card-height">
        <Card.Body>
          <div className="user-post-data">
            <div className="d-flex ">
              <Link
                to={userInfo?._id === postDetails?.created_by?.ref ? `/dashboard/app/profile` : `/dashboard/app/profile-view/${postDetails?.created_by?.ref}`}
              >
                <div className="me-3" style={{ width: "60px", height: "60px" }}>
                  <img
                    className="avatar-50 rounded-circle img-fluid"
                    src={
                      postDetails?.created_by?.profile_picture ||
                      "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                    }
                    alt=""
                  />
                </div>
              </Link>
              <div className="w-100">
                <div
                  className="d-flex justify-content-between"
                  style={{ position: "relative" }}
                >
                  <div>
                    {postDetails?.group_identifier?.name ? (
                      <h5 className="mb-0 d-inline-block"
                        style={{
                          fontWeight: "600",
                        }}>
                        {postDetails?.group_identifier?.name} by{" "}
                        <Link
                          to={userInfo?._id === postDetails?.created_by?.ref ? `/dashboard/app/profile` : `/dashboard/app/profile-view/${postDetails?.created_by?.ref}`}
                        >
                          {postDetails?.created_by?.username}
                        </Link>
                      </h5>
                    ) : (
                      <Link to={userInfo?._id === postDetails?.created_by?.ref ? `/dashboard/app/profile` : `/dashboard/app/profile-view/${postDetails?.created_by?.ref}`}>
                        <h5 className="mb-0 d-inline-block"
                          style={{
                            fontWeight: "600",
                          }}>
                          {postDetails?.created_by?.username}
                        </h5>
                      </Link>
                    )}
                    <p className="mb-0 text-primary"
                      style={{
                        fontSize: "12px",
                      }}>
                      {timeObj.hours > 0
                        ? timeObj.hours > 23
                          ? `on ${timeObj?.timeStart.toDateString()}`
                          : `${Math.floor(timeObj.hours)} hour ago`
                        : `${Math.floor(timeObj.minutes)} min ago`}
                    </p>
                  </div>

                  {canDisablePost() && handleDisable && (
                    postDetails?.is_deleted ?
                      <OverlayTrigger placement="top" overlay={<Tooltip>Click to enable</Tooltip>}>
                        <img src={disabled}
                          style={{ height: "22px" }}
                          onClick={handleDisablePost}
                          alt="" />
                      </OverlayTrigger>
                      :
                      <OverlayTrigger placement="top" overlay={<Tooltip>click to disable</Tooltip>}>
                         <img src={enabled}
                        style={{ height: "22px" }}
                        //  className="img-fluid rounded mb-2" 
                        onClick={handleDisablePost}
                        alt="" />
                      </OverlayTrigger>
                     
                  )}

                  {canEditPost() && (
                    <button
                      className="p-image"
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "48px",
                        border: "none",
                        backgroundColor: "transparent"
                      }}
                      onClick={handleShow}
                    >
                      <i className="ri-pencil-line upload-button " style={{ color: "#45505f" }}></i>
                    </button>
                  )}

                  {canDeletePost() && !isAdminUserProfilePage && (
                    <button
                      className="p-image"
                      style={{
                        position: "absolute",
                        top: "5px",
                        border: "none",
                        backgroundColor: "transparent"
                      }}
                      onClick={handleDeletePost}
                    >
                      <i className="ri-delete-bin-6-line" style={{ color: "#45505f", fontSize: "20px" }}></i>
                    </button>
                  )}
                </div>
                {/* {postDetails?.created_by?._id === userInfo?.ref && (
                  <button onClick={handleShow}>edit</button>
                )} */}
              </div>
            </div>
          </div>
          <div className="mt-2">
            {postDetails?.text_editor_data ? (
              <>
                <div
                  dangerouslySetInnerHTML={getUpdatedHtml(
                    postDetails?.text_editor_data
                  )}
                ></div>
                <RenderURLDetails />
              </>
            ) : (
              <p>{postDetails?.plain_text}</p>
            )}
            {/* {postDetails?.files?.map((file) => {
              return (
                <div className=" d-grid grid-rows-2 grid-flow-col gap-3">
                  <div className="row-span-2 row-span-md-1">
                    <RenderFile file={file?.url} filetype={file?.file_type} />
                  </div>
                </div>
              );
            })} */}
          </div>
          <PostModal
            show={show}
            setShow={setShow}
            postDetails={postDetails}
            getPost={getPost}
            page={page}
            getAllMedia={getAllMedia}
          />
          <div className="user-post">
            {postDetails?.files.map((file) => {
              return (
                <div className=" d-grid grid-rows-2 grid-flow-col gap-3">
                  <div className="row-span-2 row-span-md-1">
                    <RenderFile file={file?.url} filetype={file?.file_type} />
                  </div>
                </div>
              );
            })}
            {postDetails?.url_meta_data && (
              <RenderMetaURL
                title={postDetails?.url_meta_data?.meta?.title}
                description={postDetails?.url_meta_data?.meta?.description ? postDetails?.url_meta_data?.meta?.description : postDetails?.url_meta_data?.og?.description}
                image={postDetails?.url_meta_data?.og?.image}
                url={postDetails?.url_meta_data?.meta?.url}
              />
            )}
          </div>
          {!isAdminUserProfilePage && <div className="comment-area mt-3">
            <div className="d-flex justify-content-between align-items-center flex-wrap">
              <div className="like-block position-relative d-flex align-items-center">
                <div className="d-flex align-items-center">
                  {postDetails && <RenderLike handleLike={handleLike} data={postDetails} like_count={postDetails?.like_count} />}
                </div>
                <div className="total-comment-block">
                <i class="ri-chat-3-line"></i>
                  {
                  `
                   Comments
                   `}
                  
                
                </div>
                <div onClick={()=>setShowModal(true)} className="ms-2"
  style={{
    cursor: 'pointer'
  }}
> 

{/* <i className="ri-share-forward-line me-2"></i>
Share */}
</div>
        
              </div>
            </div>

            <hr />
            {postDetails?.comments?.map((item, index) => {
              return (
                <RenderComment
                  key={`comment--${index}`}
                  objData={item}
                  handleReply={handleReply}
                  handleCommentLike={handleCommentLike}
                  post_id={post_id}
                  isViewFullPost={isViewFullPost}
                  deleteComment={deleteComment}
                  editComment={editComment}
                />
              );
            })}

            <CommentInput handleComment={handleComment} data={postDetails} />
            <ShareModal showModal = {showModal} setShowModal={setShowModal} postId={postDetails?._id}/>

            {!isViewFullPost && <div className="text-center">
              <Link
                to={`/dashboards/app/post-detail/${postDetails?._id}`}
                className=" btn text-primary"
              >
                View full post
              </Link>
            </div>}
          </div>}
        </Card.Body>
      </Card>
    </Col>
  );
}

export default FeedBlock;