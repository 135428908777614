import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Alert,
  Modal
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
import ReactCrop from "react-image-crop";
import 'react-image-crop/dist/ReactCrop.css';
import { validateFileSize, validateFileType, dataUrlToFile, } from "../../../utilities/helper";

//image
import img1 from "../../../assets/images/user/11.png";
import { useFileUploadMutation } from "../../../store/user/userApiSlice";
import { useEditGroupDetailMutation } from "../../../store/group/groupApiSlice";
import defaultBg from "../../../assets/images/bg/bg.jpg";
import { toast, ToastContainer } from "react-toastify";


const EditGroupDetail = () => {
  const [editGroup, setEditGroup] = useState({});
  const [showA1, setShowA1] = useState(false);



  const { groupDetail } = useSelector(state => state.groupDetail)
  useEffect(() => {
    setEditGroup(groupDetail[0])
  }, [groupDetail])


  const [groupPic, setGroupPic] = useState({ icon: "", url: "" });
  const [grpCoverPic, setGrpCoverPic] = useState({ icon: "", url: "" });

  const inputRef = useRef(null);
  const bg_ImageRef = useRef(null);

  const history = useHistory();
  const [fileUpload] = useFileUploadMutation();
  const [grpDetail] = useEditGroupDetailMutation();

  const goBack = () => {
    history.goBack();
  }

  // cropper states for group profile pic
  const [showModal, setShowModal] = useState(false);
  const [cropProfile, setCropProfile] = useState({ aspect: 1 });
  const [imageProfile, setImageProfile] = useState(null);
  const [refresh, setRefresh] = useState(false);
  const [result, setResult] = useState({ url: "" });


  const handleChange = (value) => {
    setEditGroup({ ...editGroup, [value.target.id]: value.target.value, ["group_id"]: editGroup?._id });
    if (value.target.value === "public") {
      setEditGroup({ ...editGroup, is_public: "true", ["group_id"]: editGroup?._id });
    }
    if (value.target.value === "private") {
      setEditGroup({ ...editGroup, is_public: "false", ["group_id"]: editGroup?._id });
    }
  };


  const getCroppedImg = () => {
    const canvas = document.createElement("canvas");
    const scaleX = imageProfile.naturalWidth / imageProfile.width;
    const scaleY = imageProfile.naturalHeight / imageProfile.height;
    canvas.width = cropProfile.width;
    canvas.height = cropProfile.height;
    const ctx = canvas.getContext("2d");

    // New lines to be added
    const pixelRatio = window.devicePixelRatio;
    canvas.width = cropProfile.width * pixelRatio;
    canvas.height = cropProfile.height * pixelRatio;
    ctx.setTransform(pixelRatio, 0, 0, pixelRatio, 0, 0);
    ctx.imageSmoothingQuality = "high";

    ctx.drawImage(
      imageProfile,
      cropProfile.x * scaleX,
      cropProfile.y * scaleY,
      cropProfile.width * scaleX,
      cropProfile.height * scaleY,
      0,
      0,
      cropProfile.width,
      cropProfile.height
    );
    const base64Image = canvas.toDataURL('image/jpeg');
    // const croppedFile = dataUrlToFile(base64Image, "output.png");

    setResult({
      url: base64Image,
    });

    setShowModal(false);
  }


  const handleProfileUpload = async (event) => {
    // setGroupPic({url:URL.createObjectURL(event.target.files[0])})
    // setGroupPic({url:event.target.files[0]})
    const image = event.target.files[0]
    let url = "";
    let multerData = new FormData();

    multerData.append("file", event.target.files[0]);
    let result = await fileUpload(multerData);
    if (result?.data?.status === 1) {
      url = result?.data?.data?.data;
      setEditGroup({ ...editGroup, group_image: url, ["group_id"]: editGroup?._id });
    }

    setGroupPic({
      icon: event.target.files[0],
      url: url,
    });
    setEditGroup({ ...editGroup, group_image: url, ["group_id"]: editGroup?._id });
    // setShowModal(true)
  };
  const handleBackGroundUpload = async (event) => {

    let url = "";
    let multerData = new FormData();

    multerData.append("file", event.target.files[0]);
    let result = await fileUpload(multerData);
    if (result?.data?.status === 1) {
      url = result?.data?.data?.data;
      setEditGroup({ ...editGroup, background_image: url, ["group_id"]: editGroup?._id });
    }

    setGrpCoverPic({
      icon: event.target.files[0],
      url: url,
    });
    setEditGroup({ ...editGroup, background_image: url, ["group_id"]: editGroup?._id });
  }

  const handleClose = (e) => {
    e.preventDefault();
    setShowModal(false);

    setRefresh(!refresh);
  };

  const updateGroupdetail = async (e) => {
    e.preventDefault();

    grpDetail({ ...editGroup });

    // toast('Group Updated Successfully', {
    //   position: "top-right",
    //   autoClose: 3000,
    //   hideProgressBar: false,
    //   closeOnClick: true,
    //   pauseOnHover: true,
    //   draggable: true,
    //   theme: "colored",
    //   type: "success"
    // });

    setTimeout(() => {
      history.push("/dashboards/app/groups")
    }, 1000);
    // setShowA1(true)
  }



  return (
    <>
      <Container>
        <div className="container" style={{ height: "200px", color: "white" }}>
          <img
            src="https://www.pasecondarytransition.com/assets21/webpimages/Practices-ban.webp"
            style={{ width: "100%", height: "100%", borderRadius: "5px" }}
          />
        </div>
        <Tab.Container defaultActiveKey="first">
          <Row className="mt-3">
            <Col lg={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Update Group Details</h4>
                      </div>
                      <Button onClick={goBack}
                        className="btn btn-primary me-2"

                      >
                        Back
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Row className="align-items-center">
                          <Form.Group className="form-group col-sm-6 ">
                            <div>Group Picture : </div>
                            <div className="profile-img-edit">
                              <img style={{ width: "150px", height: "150px" }}
                                className="profile-pic"
                                // src={groupPic?.url ? groupPic?.url : img1}
                                src={groupPic.url ? groupPic.url : editGroup?.group_image ? editGroup?.group_image : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                                // src={result.url}
                                alt="Group-Pic"
                                accept=".png,.jpeg,.jpg"
                              />
                              <div className="p-image">
                                <i
                                  className="ri-pencil-line upload-button text-white"
                                  onClick={() => inputRef.current?.click()}
                                ></i>
                                <input
                                  id="grp-image"
                                  className="file-upload"
                                  type="file"
                                  ref={inputRef}
                                  accept="image/*"
                                  onChange={(event) => handleProfileUpload(event)}
                                />
                              </div>
                            </div>
                          </Form.Group>

                          <Modal style={{
                            height: "100%", width: "100%", zIndex: "1050",
                          }}
                            fullscreen={true}
                            show={showModal}
                            onHide={(event) => handleClose(event)}
                          >
                            <Modal.Header style={{}}>

                              <Modal.Title>
                                Change Group Picture
                                <Col xs={12}>
                                  <Button className="modalBG " onClick={getCroppedImg}>
                                    Crop Pic
                                  </Button>
                                  <Button className="modalBG" style={{ marginLeft: "20px" }} onClick={() => setShowModal(false)}>
                                    Close
                                  </Button>
                                </Col>
                              </Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                              <Col xs={12}>
                                <ReactCrop
                                  src={groupPic.icon}
                                  onImageLoaded={setImageProfile}
                                  crop={cropProfile}
                                  onChange={setCropProfile}
                                />
                                {/*  */}
                              </Col>
                            </Modal.Body>
                          </Modal>

                          <Form.Group className="form-group col-sm-6">
                            <div>Cover Picture : </div>
                            <div className="profile-bg-img-edit">
                              <img
                                className="bg-grp-image"
                                src={grpCoverPic.url ? grpCoverPic.url : editGroup?.background_image
                                  ? editGroup?.background_image
                                  : defaultBg}
                                alt="profile-pic"
                              />
                              <div className="p-image">
                                <i
                                  className="ri-pencil-line upload-button text-white"
                                  onClick={() => bg_ImageRef.current?.click()}
                                ></i>
                                <input
                                  id="bg-image"
                                  className="file-upload"
                                  type="file"
                                  ref={bg_ImageRef}
                                  accept="image/*"
                                  onChange={(event) => handleBackGroundUpload(event)}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </Row>

                        <Row className="align-items-center">
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label
                              htmlFor="first_name"
                              className="form-label"
                            >
                              Group Title:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              value={editGroup?.title}
                              className="form-control"
                              id="title"
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="form-group col-sm-6">
                            <Form.Label className="form-label">
                              Group Type:
                            </Form.Label>
                            <Form.Select
                              className="form-select"
                              aria-label="Default select example 2"
                              id="is_public"
                              onChange={handleChange}
                              value={typeof editGroup?.is_public === 'string' ? editGroup?.is_public === "true" ?  "public" : "private" : editGroup?.is_public === true ?  "public" : "private"}
                            >
                              <option value="public">Public</option>
                              <option value="private">Private</option>

                            </Form.Select>
                          </Form.Group>

                          <Form.Group className="form-group col-sm-12">
                            <Form.Label
                              htmlFor="last_name"
                              className="form-label"
                            >
                              Group Description:
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              value={editGroup?.description}
                              className="form-control"
                              id="description"
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Row>
                        {/* <Alert
                          variant="alert alert-success"
                          show={showA1}
                          onClose={() => setShowA1(false)}
                          dismissible
                        >
                          <span>
                            <i className="fas fa-thumbs-up"></i>
                          </span>
                          <span> Group updated successfully!</span>
                        </Alert> */}
                        <Button
                          className="btn btn-primary me-2"
                          onClick={updateGroupdetail}
                        >
                          Update
                        </Button>
                        <Button
                          type="reset"
                          style={{ backgroundColor: "rgb(68,175,157)" }}
                          className="btn btn-secondry me-1"
                          onClick={goBack}
                        >
                          Cancel
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        {/* <ToastContainer /> */}
      </Container>
    </>
  );
};

export default EditGroupDetail;
