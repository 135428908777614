import React from 'react'
import { Switch, Route } from 'react-router-dom'
import Groups from '../views/app/group/Groups';
import GroupDetail from '../views/app/group/GroupDetail';
import PostDetail from '../views/app/PostDetail';
import EditGroupDetail from '../views/app/group/EditGroupDetail';
import FriendList from '../views/app/FriendList'
import ConnectionList from '../views/app/connection/ConnectionList'
import FriendRequest from '../views/app/invitation/InvitationList'
import FollowersList from '../views/app/FollowersList'
import ProfileImages from '../views/app/ProfileImages';
import ProfileVideos from '../views/app/ProfileVideos';
import ProfileEvents from '../views/app/ProfileEvents';
import EventDetail from '../views/app/EventDetail';
import Calendar from '../views/app/Calendar';
import Birthday from '../views/app/Birthday';
import Weather from '../views/app/Weather'
import Music from '../views/app/Music';
import CatergoryGrid from '../views/store/StoreCategoryGrid';
import CatergoryList from '../views/store/StoreCategoryList';
import StoreDetail from '../views/store/StoreDetail';
import StoreCheckout from '../views/store/StoreCheckout';
import Market1 from '../views/market-place/Market1';
import Market2 from '../views/market-place/Market2';
import Profile1 from '../views/profiles/Profile1';
import Profile2 from '../views/profiles/Profile2';
import Profile3 from '../views/profiles/Profile3';
import CreateGroup from '../views/app/group/CreateGroup';
import AddMemberGroup from '../views/app/group/add-member-group';
import MemberList from '../views/app/group/MemberList';
import Resources from '../views/app/resources/Resources';
import MyResources from '../views/app/resources/MyResources';
import AllAssesment from '../views/app/assesment/AllAssesment';
import MyAssessment from '../views/app/assesment/MyAssessment';
import PeopleYouMayKnow from '../views/app/PeopleYouMayKnow';

const Layout1Router = () => {
    return (
        <>
            <Switch>
                <Route path="/dashboards/app/resources/resources" component={Resources}></Route>
                <Route path="/dashboards/app/resources/my-resources" component={MyResources}></Route>
                <Route path="/dashboards/app/all-assesment" component={AllAssesment}></Route>
                <Route path="/dashboards/app/my-assesment" component={MyAssessment}></Route>
                <Route path="/dashboards/app/people-you-may-know" component={PeopleYouMayKnow}></Route>
                <Route path="/dashboards/app/groups" component={Groups}></Route>
                <Route path="/dashboards/app/create-group" component={CreateGroup}></Route>
                <Route path="/dashboards/app/group-detail/:group_id" component={GroupDetail}></Route>
                <Route path="/dashboards/app/edit-group-detail/:group_id" component={EditGroupDetail}></Route>
                <Route path="/dashboards/app/post-detail/:id" component={PostDetail}></Route>
                <Route path="/dashboards/app/add-member-group" component={AddMemberGroup}></Route>
                <Route path="/dashboards/app/member-list" component={MemberList}></Route>
                <Route path="/dashboards/app/invitation-list" component={FriendRequest} />
                <Route path="/dashboards/app/friends-list" component={FriendList} />
                <Route path="/dashboards/app/connection-list" component={ConnectionList} />
                <Route path="/dashboards/app/follower-list" component={ConnectionList} />
                <Route path="/dashboards/app/followers-list" component={FollowersList} />
                <Route path="/dashboards/app/profile-images" component={ProfileImages} />
                <Route path="/dashboards/app/profile-videos" component={ProfileVideos} />
                <Route path="/dashboards/app/profile-events" component={ProfileEvents} />
                <Route path="/dashboards/app/event-detail" component={EventDetail} />
                <Route path="/dashboards/app/calendar" component={Calendar} />
                <Route path="/dashboards/app/birthday" component={Birthday} />
                <Route path="/dashboards/app/weather" component={Weather} />
                <Route path="/dashboards/app/music" component={Music} />
                <Route path="/dashboards/store/store-category-grid" component={CatergoryGrid} />
                <Route path="/dashboards/store/store-category-list" component={CatergoryList} />
                <Route path="/dashboards/store/store-detail" component={StoreDetail} />
                <Route path="/dashboards/store/store-checkout" component={StoreCheckout} />

                <Route path="/dashboards/market-place/market1" component={Market1} />
                <Route path="/dashboards/market-place/market2" component={Market2} />
                <Route path="/dashboards/profiles/profile1" component={Profile1} />
                <Route path="/dashboards/profiles/profile2" component={Profile2} />
                <Route path="/dashboards/profiles/profile3" component={Profile3} />
            </Switch>
        </>
    )
}

export default Layout1Router
