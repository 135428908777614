import React from 'react'

import SimpleRouter from '../router/simple-router'

const Simple = () => {
  return (
    <>
      <div className="wrapper">
        <SimpleRouter />
      </div>
    </>
  )
}

export default Simple
