import React, { useEffect, useState } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Dropdown,
  Button,
  Modal,
  OverlayTrigger,
  Tooltip,
  Form,
  Tabs,
  Tab
} from "react-bootstrap";
import defaultBg from "../../../assets/images/bg/bg.jpg";
import { Link, useParams, useHistory } from "react-router-dom";
import { groupDetail as groupDetailActions } from "../../../store/groups/groupDetailSlice";
import {
  useAddMemberMutation,
  useDeleteGroupMutation,
  useDisableGroupMutation
} from "../../../store/group/groupApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  useCreateGroupPostMutation,
  useGetPostsQuery,
  useFeedUploadMutation,
  useGetUrlMetaMutation,
  useGetPostsForGroupQuery,
  useLikeCommentMutation,
  useLikePostMutation,
  useCreateCommentMutation,
  useDeletePostMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useCommentUploadMutation
} from "../../../store/post/postApiSlice";
import { useRemoveMemberMutation } from "../../../store/group/groupApiSlice";

import TextEditor from "../../../components/TextEditor";
import RenderFile from "../../../components/RenderFile";
import FeedBlock from "../../../components/FeedBlock";
import pdfIcon from "../../../assets/images/pdfIcon.png";
import docIcon from "../../../assets/images/docImg.png";
import config from "../../../store/config.json"
import Loading from "../Loading";
import { ToastContainer, toast } from 'react-toastify';
import Swal from "sweetalert2";
import 'react-toastify/dist/ReactToastify.css';

//image

import user7 from "../../../assets/images/user/11.jpg";
import Video from "../Media/Video";
import Image from "../Media/Image";
import Document from "../Media/Document";

const GroupDetail = () => {
  const [show, setShow] = useState(false);
  const [showMedia, setShowMedia] = useState(false);
  const [loading, setLoading] = useState(false);
  const [mediaShow, setMediaShow] = useState(false)
  const [counter, setCounter] = useState(1)
  const [mediaSrc, setMediaSrc] = useState()
  const handleClose = () => {
    setTextEditorData(null);
    setUrlDetails({});
    setFile(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { groupDetail } = useSelector((state) => state.groupDetail);
  const dispatch = useDispatch();
  const { group_id } = useParams();
  const [createGroupPost] = useCreateGroupPostMutation();
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;
  const [deleteGroup] = useDeleteGroupMutation();
  const [removeMember] = useRemoveMemberMutation()

  const [disableGroup] = useDisableGroupMutation()
  const [feedUpload] = useFeedUploadMutation();
  // const getPost = useGetPostsQuery();
  const getGroupPosts = useGetPostsForGroupQuery({ group_id: group_id, counter: counter });
  const [file, setFile] = useState(null);
  const [textEditorData, setTextEditorData] = useState(null);
  const [urlDetails, setUrlDetails] = useState({});
  const [allPosts, setAllPosts] = useState([]);
  const [allMedia, setAllMedia] = useState([]);
  const [joinBtn, setJoinBtn] = useState(true);
  const [emptyMedia, setEmptyMedia] = useState(false)
  const [groupDetailData, setGroupdetailData] = useState(null);

  const [likePost] = useLikePostMutation();
  const [likeComment] = useLikeCommentMutation();
  const [commentPost] = useCreateCommentMutation();
  const [addMember] = useAddMemberMutation();
  const [deletePost] = useDeletePostMutation();
  const [delComment] = useDeleteCommentMutation();
  const [updateComment] = useEditCommentMutation();
  const [commentUpload] = useCommentUploadMutation()

  const [getUrlMeta] = useGetUrlMetaMutation();
  const detailGroup = groupDetail[0];
  const history = useHistory();

  let actionType = groupDetailData?.is_deleted ? "enable" : "disable"

  const fetchUrlDetails = async (url) => {
    const result = await getUrlMeta({
      url: url,
    });
    if (result?.data?.status === 1 && result?.data?.data) {
      setUrlDetails(result?.data?.data);
    }
  };

  const getGroupMembers = async () => {
    let response = await fetch(
      `${config.WEBPORTAL_URL}/api/groups/get-group-members/${group_id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
    if (response) {
      if (response.ok) {
        return response.json()

      } else {
        toast(response?.error?.error || "Group members fetched failed!"
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
      }
    }

  };

  const getGroupDetail = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/groups/get-group-details-by-groupId/${group_id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then(async (data) => {
            let membersRes = await getGroupMembers()
            let allMembersRes = membersRes?.data.map((item) => {
              return {
                image
                  :
                  item?.user?.profile_picture,
                ref
                  :
                  item?.user?._id,
                username
                  :
                  item?.user?.first_name + " " + item?.user?.last_name,
                _id
                  :
                  item?._id
              }
            })
            data.data[0].members_subset=allMembersRes
            setGroupdetailData(data.data[0])
            dispatch(groupDetailActions(data.data));
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getGroupDetail();
    groupDetailData?.members_subset?.map((item) => {
      if (userId === item.ref) {
        setJoinBtn(false)
      }
    })
  }, [])

  useEffect(() => {
    groupDetailData?.members_subset?.map((item) => {
      if (userId === item.ref) {
        setJoinBtn(false)
      }
    })
  }, [groupDetailData])

  const getAllMedia = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/files/get-file-in-group/kgdai/${group_id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setAllMedia(data)
            if (data?.message === "Files Not Found") {
              setEmptyMedia(true)
            }
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getAllMedia()
  }, [])
  

  useEffect(() => {
    setAllPosts(getGroupPosts?.data?.data);
  }, [getGroupPosts?.data?.data, counter]);

  const goBack = () => {
    history.goBack();
  };
  const memberRemove = async () => {
    
    let result = await removeMember({
      group_id: group_id,
      member_id:userId,
    });
    if (result?.data?.status === 1) {
      history.push(`dashboards/app/groups`);
    }
  };


  const handleDeleteGroup = async () => {
    Swal.fire({
      title: "Are you sure you want to delete this Group?",
      text: "You will not be able to recover this Group!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await deleteGroup({ group_id: group_id });
        if (result.data.status === 1) {
          toast('Group Deleted Successfully', {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
          setTimeout(() => {
            history.push("/dashboards/app/groups")
          }, 1500);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Group is safe!", "error");
      }
    })

  };

  const handleDisableGroup = async () => {
    Swal.fire({
      title: `Are you sure you want to ${actionType} this Group?`,
      // text: "You will not be able to recover this Group!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, ${actionType} it!`,
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await disableGroup({ group_id: group_id, status: groupDetailData?.is_deleted });
        if (result.data.status === 1) {
          toast(`Group ${actionType} Successfully`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
          setTimeout(() => {
            history.push("/dashboards/app/groups")
          }, 1500);
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Group is safe!", "error");
      }
    })

  };

  const uploadMediaHandle = (fileData) => {
    setFile(fileData);
  };

  const handleLike = async (id) => {
    const result = await likePost({
      post_id: id,
    });
    if (result?.data?.status === 1) {
      getGroupPosts.refetch();
    }
  };

  const handleCommentLike = async (post_id, comment_id) => {
    const result = await likeComment({
      post_id: post_id,
      comment_id: comment_id,
    });
  };

  const handleComment = async (id, comment, commentFile) => {
    let payload = {
      post_id: id,
      comment_text: comment,
      parent_comment: "",
    }
    if (commentFile) {
      let multerData = new FormData();
      multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }

    const result = await commentPost(payload);
    if (result?.data?.status === 1) {
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
      getGroupPosts.refetch();
    }
  };

  const handleReply = async (comment_id, post_id, text, commentFile) => {
    let payload = {
      parent_comment: comment_id,
      post_id: post_id,
      comment_text: text,
    }

    if (commentFile) {
      let multerData = new FormData();
      multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }

    const result = await commentPost(payload);
    if (result?.data?.status === 1) {
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
      getGroupPosts.refetch();
    }
  };

  const handleInfiniteScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 > document.documentElement.scrollHeight) {
      setCounter((prev) => prev + 1)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("scroll", handleInfiniteScroll)
    return () => window.removeEventListener("scroll", handleInfiniteScroll)
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    let multerData = new FormData();

    multerData.append("file", file);

    let payload;
    let multerPayload = {
      multerData: multerData,
      type: group_id ? "group" : "main",
    };
    if (group_id) {
      multerPayload.group_id = group_id;
    }
    let url_meta_data = {};
    if (urlDetails) {
      url_meta_data = { ...urlDetails };
    }

    if (file) {
      const feedRes = await feedUpload(multerPayload);
      let feedUploadData = feedRes?.data?.status === 1 ? feedRes?.data?.data : null
      payload = {
        text_editor_data: textEditorData,
        file_id: feedRes?.data?.data?._id,
        file_url: feedRes?.data?.data?.file_url,
        file_type: file?.type,
        file_details: feedUploadData,
        group_id: groupDetailData?._id,
      };
    } else {
      payload = {
        text_editor_data: textEditorData,
        group_id: groupDetailData?._id,
      };
    }
    const result = await createGroupPost({
      ...payload,
      url_meta_data: url_meta_data,
    });
    if (result?.data?.status === 1) {
      // console.warn('post', result?.data?.message)
      getGroupPosts.refetch();
      getAllMedia();
      setFile(null);
      handleClose();
      setLoading(false);
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success"
      });
    } else {
      // console.warn('post', result?.error?.data?.message)
      setLoading(false);
      getGroupPosts.refetch();
      setFile(null);
      handleClose();
      toast(userInfo?.user_type === "student" ? result?.error?.data?.message : "Only group member create a post!", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "error"
      });
    }
  };


  const memberAdd = async () => {
    let result = await addMember({
      group_id,
      member_id: userId,
    });
    if (result?.data?.status === 1) {
      history.push(`/dashboards/app/groups`);
    }
  };

  const handleDelete = async (id, postDetail) => {
    Swal.fire({
      title: "Are you sure you want to delete this Post?",
      text: "You will not be able to recover this Post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let studentId =
          userInfo?.user_type === "family" &&
            userInfo?.relation
              ?.map((userdetails) => userdetails._id)
              ?.includes(postDetail?.created_by?.ref)
            ? postDetail?.created_by?.ref
            : "";
        let isAdmin = userInfo?.user_type === "admin"

        let result = await deletePost({ id, studentId, isAdmin });
        if (result.data.status === 1) {
          getGroupPosts.refetch();
          getAllMedia();
          toast("Post deleted successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Post is safe!", "error");
      }
    });

  };

  const handleDisable = async (id, postDetail) => {
    Swal.fire({
      title: "Are you sure you want to disable this Post?",
      // text: "You will not be able to recover this Post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disable it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let studentId =
          userInfo?.user_type === "family" &&
            userInfo?.relation
              ?.map((userdetails) => userdetails._id)
              ?.includes(postDetail?.created_by?.ref)
            ? postDetail?.created_by?.ref
            : "";
        let isAdmin = userInfo?.user_type === "admin"

        let result = await deletePost({ id, studentId, isAdmin });
        if (result.data.status === 1) {
          getGroupPosts.refetch();
          getAllMedia();
          toast("Post disable successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Post is safe!", "error");
      }
    });

  };

  const deleteComment = async (comment_id, post_id) => {
    Swal.fire({
      title: "Are you sure you want to delete this Comment?",
      text: "You will not be able to recover this Comment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await delComment({
          comment_id: comment_id,
          post_id: post_id,
        });
        if (result.data.status === 1) {
          getGroupPosts.refetch();
          toast(result?.data?.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success",
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Comment is safe!", "error");
      }
    })

  };

  const editComment = async (comment_id, post_id, comment, commentFile) => {
    let payload = {
      post_id: post_id,
      comment_text: comment,
      parent_comment: "",
      comment_id: comment_id
    }

    if (commentFile) {
      let multerData = new FormData();
      multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }

    const result = await updateComment(payload);
    if (result?.data?.status === 1) {
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
      getGroupPosts.refetch();
    }
  };

  const handleImage = (src) => {
    setMediaSrc(src)
    setMediaShow(true)
  }
  return (
    <>
      <Container>
  <div id="content-page" className="content-page">
    <img
      src={groupDetailData?.background_image || defaultBg}
      style={{ width: "100%", height: "auto", borderRadius: "5px" }}
    />
    <Row>
      <Col lg="12">
        <div className="d-flex align-items-center justify-content-between mb-3 mt-3 flex-wrap">
          <div className="group-info d-flex align-items-center">
            <div className="me-3">
              <img
                className="rounded-circle img-fluid avatar-70"
                src={
                  groupDetailData?.group_image ||
                  "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                }
                alt=""
              />
            </div>
            <div className="info">
              <h4 className="groupName">{groupDetailData?.title}</h4>
              <p className="mb-0">
                <i className="ri-lock-fill pe-2"></i>
                {groupDetailData?.is_public ? "Public Group" : "Private Group"} . <Link to="/dashboards/app/member-list"> {groupDetailData?.members_subset.length} Members </Link>
              </p>
              <div style={{ display: "flex" }}>
                {groupDetailData?.owner?.ref === userId || userInfo?.user_type === "admin" ? (
                  <button
                    className="btn btn-primary btn-sm d-block me-1"
                    onClick={handleDeleteGroup}
                  >
                    Delete Group
                  </button>
                ) : null}
                {"  "}
                {userInfo?.user_type === "admin" ? (
                  <button
                    className="btn btn-primary btn-sm d-block"
                    onClick={handleDisableGroup}
                  >
                    {`${groupDetailData?.is_deleted ? "Enable Group" : "Disable Group"} `}
                  </button>
                ) : null}
              </div>
            </div>
          </div>
          <div mt-md="0" mt="2" className="group-member d-flex align-items-center groupButtons">
            {joinBtn ? <Button
              variant="primary"
              className="mb-2 me-2 btn-sm"
              onClick={memberAdd}
            >
              Join
            </Button> : <Button
              variant="primary"
              className="mb-2 me-2 btn-sm"
              onClick={memberRemove}
            >
              Leave Group
            </Button>}
            {!joinBtn ? <Link to="/dashboards/app/add-member-group">
              <Button variant="primary" className="mb-2 me-2 btn-sm">
                Add/Remove Members
              </Button>
            </Link> : ""}
            <Button variant="primary" className="mb-2 me-2 btn-sm" onClick={() => setShowMedia((prev) => !prev)}>
              {showMedia ? "Show Post" : "Show Media"}
            </Button>
            <Button
              onClick={goBack}
              className="btn btn-primary me-1 mb-2 btn-sm"
            >
              Back
            </Button>
          </div>
        </div>
      </Col>

      <Col lg="8" className={showMedia ? "d-none" : "d-block"}>
        <Card id="post-modal-data">
          <Card.Body>
            <div className="d-flex align-items-center">
              <Link to="/dashboard/app/profile">
                <div className="user-img">
                  <img
                    src={
                      userInfo?.profile_picture
                        ? userInfo?.profile_picture
                        : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                    }
                    alt="user1"
                    className="avatar-50 rounded-circle"
                  />
                </div>
              </Link>
              <form
                className="post-text ms-3 w-100 "
                onClick={handleShow}
              >
                <input
                  type="text"
                  className="form-control rounded"
                  placeholder="Write something here..."
                  style={{ border: "none" }}
                />
              </form>
            </div>
            <hr />
          </Card.Body>
          <Modal show={show} onHide={handleClose} size="lg">
            <Modal.Header className="d-flex justify-content-end">
              {/* <Modal.Title id="post-modalLabel">Create Post</Modal.Title> */}
              <Link to="#" className="lh-1" onClick={handleClose}>
                <span className="material-symbols-outlined">Close</span>
              </Link>
            </Modal.Header>
            <Modal.Body>
              <div className="d-flex align-items-center">
                <div className="user-img" style={{ height: "60px", width: "60px" }}>
                  <img
                    src={
                      userInfo?.profile_picture
                        ? userInfo?.profile_picture
                        : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                    }
                    alt="user1"
                    className="avatar-50 rounded-circle "
                  />
                </div>
                <form
                  className="post-text ms-3 w-100 "
                  data-bs-toggle="modal"
                  data-bs-target="#post-modal"
                >
                  <TextEditor
                    textEditorData={textEditorData}
                    onChange={setTextEditorData}
                    urlDetails={urlDetails}
                    fetchUrlDetails={fetchUrlDetails}
                  />
                </form>
              </div>
              <hr />
              {loading ? <Loading /> : <>
                <ul className="d-flex flex-column align-items-center list-inline m-0 p-0">
                  <li className="col-md-6 mb-3">
                    <div
                      className="bg-soft-primary rounded p-2 pointer"
                      style={{ textAlign: "center" }}
                    >
                      <label className="shareOption">
                        <span style={{ cursor: "pointer", fontWeight: "600", color: "#000" }}>
                          Choose Photo, Video or Document
                        </span>

                        <input
                          style={{ display: "none" }}
                          type="file"
                          id="file"
                          onChange={(e) => {
                            uploadMediaHandle(e.target.files[0]);
                          }}
                        />
                      </label>
                    </div>
                  </li>
                  <RenderFile file={file} filetype={"filetype"} />
                </ul>
                <hr />
                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 mt-3 btn-sm"
                  onClick={handleSubmit}
                >
                  Post
                </button>
              </>}
            </Modal.Body>
          </Modal>
        </Card>

        {allPosts?.map((val) => (
          <FeedBlock
            postDetails={val}
            handleLike={handleLike}
            handleComment={handleComment}
            handleCommentLike={handleCommentLike}
            handleReply={handleReply}
            handleDelete={handleDelete}
            deleteComment={deleteComment}
            editComment={editComment}
            getPost={getGroupPosts}
            getAllMedia={getAllMedia}
            handleDisable={handleDisable}
          />
        ))}
      </Col>

      <Col lg="8" className={showMedia ? "d-block" : "d-none"}>
        <Card>
          <Card.Body className=" p-0">
            <Tabs defaultActiveKey="image" id="noanim-tab-example" className="d-flex justify-content-center">
              <Tab eventKey="image" title="Image" className="">
                <Container>
                  <Row>
                    {emptyMedia ? <p className="text-center">Not any image yet.</p> : <>
                      {allMedia?.data?.filter((item) => {
                        if (item?.file_type === "image") {
                          return item;
                        }
                      })?.map((item) => {
                        return (
                          <Col lg={4} onClick={() => handleImage(item?.file_url)} style={{ cursor: "pointer" }}>
                            <Image data={item?.file_url} />
                          </Col>
                        )
                      })}
                    </>}
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="videos" title="Videos">
                <Container>
                  <Row>
                    {emptyMedia ? <p className="text-center">Not any video yet.</p> : <>
                      {allMedia?.data?.filter((item) => {
                        if (item?.file_type === "video") {
                          return item;
                        }
                      })?.map((item) => {
                        return (
                          <Col lg={6}>
                            <Video data={item?.file_url} />
                          </Col>
                        )
                      })}
                    </>}
                  </Row>
                </Container>
              </Tab>
              <Tab eventKey="documents" title="Documents" >
                <Container>
                  <Row>
                    {emptyMedia ? <p className="text-center">Not any document yet.</p> : <>
                      {allMedia?.data?.filter((item) => {
                        if (item?.file_type === "application") {
                          return item;
                        }
                      })?.map((item) => {
                        return (
                          <Col lg={3} >
                            <Document
                              item={item}
                              pdfIcon={pdfIcon}
                              docIcon={docIcon}
                            />
                          </Col>
                        )
                      })}
                    </>}
                  </Row>
                </Container>
              </Tab>
            </Tabs>
          </Card.Body>
        </Card>
      </Col>

      <Modal show={mediaShow} onHide={mediaShow} size="xl">
        <Modal.Header className="d-flex justify-content-end">
          <Link to="#" className="lh-1" onClick={() => setMediaShow(false)}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div>
            <img src={mediaSrc} style={{ width: "100%" }} />
          </div>
          <hr />
          <button
            type="submit"
            className="btn btn-primary d-block w-100 mt-3 btn-sm"
            onClick={() => setMediaShow(false)}
          >
            Close
          </button>
        </Modal.Body>
      </Modal>

      <Col lg="4">
        {userInfo?.user_type !== "family" ? (
          <Card>
            <Card.Header className="card-header d-flex justify-content-between">
              <Link to="/dashboards/app/create-group">
                <button
                  type="submit"
                  className="btn btn-primary d-block w-100 btn-sm"
                >
                  <i className="ri-add-line pe-2"></i>Create New Group
                </button>
              </Link>
              {groupDetailData?.owner?.ref === userId ? (
                <Link to={`/dashboards/app/edit-group-detail/${group_id}`}>
                  <button className="btn btn-primary d-block w-100 btn-sm">
                    Edit Group
                  </button>
                </Link>
              ) : null}
            </Card.Header>
          </Card>
        ) : (
          ""
        )}
        <Card>
          <Card.Header className="card-header d-flex justify-content-between">
            <div className="header-title">
              <h4 className="card-title">About</h4>
            </div>
          </Card.Header>
          <Card.Body>
            <ul className="list-inline p-0 m-0">
              <li className="mb-3">
                <p className="mb-0">{groupDetailData?.title}</p>
              </li>
              <li className="mb-3">
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="material-symbols-outlined">lock</i>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6>Group Type</h6>
                    <p className="mb-0">
                      {groupDetailData?.is_public ? "Public" : "Private"}
                    </p>
                  </div>
                </div>
              </li>
              <li className="mb-3">
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="material-symbols-outlined">group</i>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6>Group Owner</h6>
                    <Link
                      to={groupDetailData?.owner?.ref === userId ? `/dashboard/app/profile` :
                        `/dashboard/app/profile-view/${groupDetailData?.owner?.ref}`}
                    >
                      <p className="mb-0">
                        {groupDetailData?.owner.username}
                      </p>
                    </Link>
                  </div>
                </div>
              </li>
              <li>
                <div className="d-flex">
                  <div className="flex-shrink-0">
                    <i className="material-symbols-outlined">
                      description
                    </i>
                  </div>
                  <div className="flex-grow-1 ms-3">
                    <h6>Group Description</h6>
                    <p className="mb-0">
                      {groupDetailData?.description ||
                        "Description of group will appear here"}
                    </p>
                  </div>
                </div>
              </li>
            </ul>
          </Card.Body>
        </Card>
      </Col>
    </Row>
    <ToastContainer />
  </div>
</Container>
    </>
  );
};

export default GroupDetail;
