import React, { useState, useEffect } from 'react';
import { useHistory } from "react-router-dom";
import { useSaveResourcesMutation, useDeleteResourcesMutation } from '../../../store/resourcesApi/resourceSlice';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import './resources.scss';

const ResourceCard = ({ item, myResources }) => {
    const [bookmark, setBookmark] = useState(false);
    const [userResource] = useSaveResourcesMutation();
    const [deleteResource] = useDeleteResourcesMutation()
    const history = useHistory();
    const handleBookmark = async (data) => {
        const payload = {
            resource: {
                ...data
            },
            id: data?.id,
        }
        if (bookmark === false) {
            let result = await userResource(payload)
            if (result?.data?.status === 1) {
                setBookmark(true)
                toast(result?.data?.message, {
                    position: "top-right",
                    autoClose: 2000,
                    hideProgressBar: false,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    theme: "colored",
                    type: "success",
                });
                // setTimeout(() => {
                //     history.push('/dashboards/app/resources/my-resources')
                // }, 1500);
            }
        }
        if (bookmark === true) {
            myResources?.data?.map((ele) => {
                if (ele?.id === data?.id) {
                    handleUnsaveBookmark(ele?._id)
                }
            })
        }
    }

    const handleUnsaveBookmark = async (id) => {
        let result = await deleteResource(id)
        if (result?.data?.status === 1) {
            setBookmark(false)
            toast(result?.data?.message, {
                position: "top-right",
                autoClose: 2000,
                hideProgressBar: false,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                theme: "colored",
                type: "success",
            });
            // setTimeout(() => {
            //     history.push('/dashboards/app/resources/my-resources')
            // }, 1500);
        }
    }


    useEffect(() => {
        myResources?.data?.map((ele) => {
            if (ele?.id === item?.id) {
                setBookmark(true)
            }
        })
    }, [myResources])
    const truncateText = (text, maxLength) => {
        if (text.length <= maxLength) return text;
        const truncatedText = text.substring(0, maxLength);
        return truncatedText + '...';
    };
    return (
        <>
            <div className="mb-lg-0 mb-lg-2 mb-md-2 mb-2 resourcesBoxItem "
                style={{
                    height: "16.4rem",
                    border: "1px solid #CED4DA",
                    borderRadius: "10px",
                    backgroundColor: "#F7F7F7",
                    position: "relative",
                    // overflow:"hidden",
                    // whiteSpace:"nowrap",
                    // textOverflow:"ellipsis"

                }}>
                <a href={item?.pdf_link ? item.pdf_link : item.website_link ? item?.website_link: item?.video_link} target="_blank">
                    <div className="bg-image" style={{ height: "12rem", position: "relative", }}>
                        <img
                            src={item?.image}
                            className="img-fluid w-100"
                            style={{ height: "100%", borderRadius: "10px 10px 0 0" }}
                            alt="group-bg"
                        />
                    </div>
                </a>
                <p className=""
                    style={{
                        position: "absolute",
                        background: "rgb(45 205 178)",
                        color: "rgb(255, 255, 255)",
                        top: "10px",
                        fontWeight: "bold",
                        padding: "2px 10px",
                        fontSize: "12px",
                        boxShadow: "-1px 4px 4px 1px #07070724",
                    }}>
                    {item?.resource_type}
                </p>
                <a href={item?.pdf_link ? item.pdf_link : item.website_link ? item?.website_link: item?.video_link} target="_blank">
                    <p className='text-center '
                        style={{
                            fontWeight: "600",
                            padding: "10px 10px 0px 10px",
                            color: "#20639A",
                            fontSize: "17px",
                            lineHeight:  "22px",
                        
                    
                        }}>
                        {truncateText( item?.title,50)}
                    </p>
                </a>
                <span onClick={() => handleBookmark(item)}
                    style={{
                        fontSize: "25px",
                        color: "rgb(255 255 255)",
                        position: "absolute",
                        left: "270px",
                        top: "0px"
                    }}>
                    <i class={bookmark ? "ri-heart-3-fill" : "ri-heart-3-line"}
                     style={{
                        cursor: "pointer",
                    }}
                    >
                    </i>
                </span>
            </div>
        </>
    )
}

export default ResourceCard;