import Button from 'react-bootstrap/Button';
import Spinner from 'react-bootstrap/Spinner';

function Loading() {
    return (
        <div className="d-flex align-items-center justify-content-center gap-1">
            <Spinner animation="border" role="status">
            </Spinner>
            <span className="fs-4">Loading...</span>
        </div>
    );
}

export default Loading;