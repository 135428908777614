import React, { useState, useContext, useEffect } from "react";
import { useGetUserDetailsQuery } from "../../../store/user/userApiSlice";
// import {  useSelector } from "react-redux";
import { useDispatch, useSelector } from "react-redux";
import { logoutUser } from "../../../store/user/action" ;

//router
import { Link, useLocation, useHistory } from "react-router-dom";

//react-bootstrap
import {
    Accordion,
    useAccordionButton,
    AccordionContext,
    Nav,
    Tooltip,
    OverlayTrigger,
} from "react-bootstrap";

// import {setInvitation} from '../../../../store/connection/action'

function CustomToggle({ children, eventKey, onClick }) {
    const { activeEventKey } = useContext(AccordionContext);

    const decoratedOnClick = useAccordionButton(eventKey, (active) =>
        onClick({ state: !active, eventKey: eventKey })
    );

    const isCurrentEventKey = activeEventKey === eventKey;


    return (
        <Link
            to="#"
            aria-expanded={isCurrentEventKey ? "true" : "false"}
            className="nav-link"
            role="button"
            onClick={(e) => {
                decoratedOnClick(isCurrentEventKey);
            }}
        >
            {children}
        </Link>
    );
}

const VerticalNav = React.memo(() => {
    const [activeMenu, setActiveMenu] = useState(false);
    const [active, setActive] = useState("");
    const [details, setDetails] = useState({});
    //location
    let location = useLocation();
    const history = useHistory();
    const userType = JSON.parse(localStorage.getItem('userDetail'))?.user_type || "";
    // const {data : detailsData} = useGetUserDetailsQuery(null);
    const sidebarResponsive = document.querySelector('[data-sidebar="responsive"]')
    const isDrawerOpen = sidebarResponsive?.classList?.contains('sidebar-mini') === undefined ? true : sidebarResponsive?.classList?.contains('sidebar-mini');

    // const isDrawerOpen= document.getElementsByTagName("ASIDE")[0]?.classList.toggle("sidebar-mini");
    // console.log(isDrawerOpen, "isDrawerOpen", sidebarResponsive?.classList?.contains('sidebar-mini'))

    // useEffect(()=>{
    //     if(detailsData && detailsData.status ===1){
    //         setDetails(detailsData.data[0]);
    //     }
    // }, [detailsData]);


    // console.log(document);
    const dispatch = useDispatch();

    const minisidebar = () => {
        document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
      };

    return (
        <React.Fragment>
            <Accordion as="ul" className="navbar-nav iq-main-menu" id="sidebar-menu">
                {/* <li className="nav-item static-item">
                    <Link className="nav-link static-item disabled" to="#" tabIndex="-1">
                        <span className="default-icon">Menu</span>
                        <span
                            className="mini-icon"
                            data-bs-toggle="tooltip"
                            title="Social"
                            data-bs-placement="right"
                        >

                        </span>
                    </Link>
                </li> */}
                <li
                    className={`${location.pathname === "/" ? "active" : ""} nav-item `}
                >
                    <Link
                        className={`${location.pathname === "/" ? "active" : ""} nav-link `}
                        aria-current="page"
                        to="/dashboard/app/profile"
                    

                    >
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Profile</Tooltip>}
                        >
                            <div className="sidebar-label">
                                <i className="icon material-symbols-outlined">person</i>

                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Profiles
                                </div>}
                            </div>

                        </OverlayTrigger>

                        <span className="item-name">Profile</span>
                    </Link>
                </li>
                <Accordion.Item as="li" eventKey="resources-menu" bsPrefix="nav-item">
                    <CustomToggle
                        eventKey="resources-menu"
                        onClick={(activeKey) => setActiveMenu(activeKey)}
                    >
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Resources</Tooltip>}
                        >
                            <div className="sidebar-label">

                                <i class="ri-book-3-line" style={{ fontSize: "1.2rem" }}></i>
                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Resources
                                </div>}
                            </div>
                        </OverlayTrigger>
                        <span className="item-name">Resources</span>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="resources-menu">
                        <ul className="sub-nav">
                            <Nav.Item as="li">
                                <Link
                                    className={`${location.pathname === "/dashboards/app/resources/resources"
                                        ? "active"
                                        : ""
                                        } nav-link`}
                                    to="/dashboards/app/resources/resources"
                                    // onClick={minisidebar}

                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>All Resources</Tooltip>}
                                    >
                                        <div className="sidebar-label">
                                            <i class="ri-book-2-line"></i>
                                            {isDrawerOpen && <div className="sidebar-labelTxt">
                                                All Resources
                                            </div>}
                                        </div>
                                    </OverlayTrigger>
                                    <span className="item-name">All Resources</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link
                                    className={`${location.pathname === "/dashboards/app/resources/my-resources"
                                        ? "active"
                                        : ""
                                        } nav-link`}
                                    to="/dashboards/app/resources/my-resources"
                                    // onClick={minisidebar}

                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>My Favourites</Tooltip>}
                                    >
                                        <div className="sidebar-label">
                                            <i class="ri-book-mark-line"></i>
                                            {isDrawerOpen && <div className="sidebar-labelTxt">
                                                My Favourites
                                            </div>}
                                        </div>
                                    </OverlayTrigger>
                                    <span className="item-name">My Favourites</span>
                                </Link>
                            </Nav.Item>
                        </ul>
                    </Accordion.Collapse>
                </Accordion.Item>
               
                <Accordion.Item as="li" eventKey="assesment-menu" bsPrefix="nav-item">
                    <CustomToggle
                        eventKey="assesment-menu"
                        onClick={(activeKey) => setActiveMenu(activeKey)}
                    >
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Assessment</Tooltip>}
                        >
                            <div className="sidebar-label">

                                <i class="ri-survey-line" style={{ fontSize: "1.2rem" }}></i>
                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Assessment
                                </div>}
                            </div>
                        </OverlayTrigger>
                        <span className="item-name">Assessment</span>
                    </CustomToggle>
                    <Accordion.Collapse eventKey="assesment-menu">
                        <ul className="sub-nav">
                            <Nav.Item as="li">
                                <Link
                                    className={`${location.pathname === "/dashboards/app/all-assesment"
                                        ? "active"
                                        : ""
                                        } nav-link`}
                                    to="/dashboards/app/all-assesment"
                                    // onClick={minisidebar}
                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>All Assessment</Tooltip>}
                                    >
                                        <div className="sidebar-label">
                                            <i class="ri-clipboard-line"></i>
                                            {isDrawerOpen && <div className="sidebar-labelTxt">
                                                All Assessment
                                            </div>}
                                        </div>
                                    </OverlayTrigger>
                                    <span className="item-name">All Assessment</span>
                                </Link>
                            </Nav.Item>
                            <Nav.Item as="li">
                                <Link
                                    className={`${location.pathname === "/dashboards/app/my-assesment"
                                        ? "active"
                                        : ""
                                        } nav-link`}
                                    to="/dashboards/app/my-assesment"
                                    // onClick={minisidebar}
                                >
                                    <OverlayTrigger
                                        placement="right"
                                        overlay={<Tooltip>My Favourites</Tooltip>}
                                    >
                                        <div className="sidebar-label">
                                            <i class="ri-file-mark-line"></i>
                                            {isDrawerOpen && <div className="sidebar-labelTxt">
                                                My Favourites
                                            </div>}
                                        </div>
                                    </OverlayTrigger>
                                    <span className="item-name">My Favourites</span>
                                </Link>
                            </Nav.Item>
                        </ul>
                    </Accordion.Collapse>
                </Accordion.Item>
                <Nav.Item as="li">
                <Link
                        className={`${location.pathname === "/dashboards/app/groups" ? "active" : ""
                            } nav-link `}
                        aria-current="page"
                        to="/dashboards/app/groups"
                        // onClick={minisidebar}

                    >
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Groups</Tooltip>}
                        >
                            <div className="sidebar-label">

                                <i className="icon material-symbols-outlined">groups</i>
                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Groups
                                </div>}
                            </div>
                        </OverlayTrigger>
                        <span className="item-name">Groups</span> 
                    </Link>
                </Nav.Item>
                
                <Nav.Item as="li">
                    <Link
                        className={`${location.pathname === "/dashboard/app/notification"
                            ? "active"
                            : ""
                            } nav-link `}
                        aria-current="page"
                        to="/dashboard/app/notification"
                    

                    >
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Notifications</Tooltip>}
                        >
                            <div className="sidebar-label">

                                <i className="icon material-symbols-outlined">notifications</i>
                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Notifications
                                </div>}
                            </div>
                        </OverlayTrigger>
                        <span className="item-name">Notifications</span>
                    </Link>
                </Nav.Item>
                <Nav.Item as="li">
                    <Link
                        className={`${location.pathname === "/dashboard/app/event"
                            ? "active"
                            : ""
                            } nav-link `}
                        aria-current="page"
                        to="/dashboard/app/event"
                    

                    >
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Event</Tooltip>}
                        >
                            <div className="sidebar-label">

                                <i className="icon material-symbols-outlined">Event</i>
                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Event
                                </div>}
                            </div>
                        </OverlayTrigger>
                        <span className="item-name">Event</span>
                    </Link>
                </Nav.Item>
                
                {userType === 'admin' && <Nav.Item as="li">
                    <Link className={`${location.pathname === '/dashboard/app/userList' ? 'active' : ''} nav-link `} aria-current="page" to="/dashboard/app/userList"                         
>
                        <OverlayTrigger placement="right" overlay={<Tooltip>Users</Tooltip>}>
                            <div className="sidebar-label">
                                <i className="icon material-symbols-outlined">
                                    groups
                                </i>
                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Users
                                </div>}
                            </div>
                        </OverlayTrigger>
                        <span className="item-name">Users</span>
                    </Link>
                </Nav.Item>}
                <Nav.Item as="li">
                    <Link
                    to="/auth/sign-in"
                        className={` nav-link `}
                        aria-current="page"
                        onClick={() => {
                            localStorage.clear();
                            history.push("/auth/sign-in");
                        }}
                        // onClick={ () => {
                        //      dispatch(logoutUser());
                        //     localStorage.clear();
                            // history.push("/auth/sign-in");
                        //   }}
                    >
                        <OverlayTrigger
                            placement="right"
                            overlay={<Tooltip>Sign Out</Tooltip>}
                        >
                            <div className="sidebar-label">
                                <i className="icon material-symbols-outlined">login</i>
                                {isDrawerOpen && <div className="sidebar-labelTxt">
                                    Sign Out
                                </div>}
                            </div>
                        </OverlayTrigger>
                        <span className="item-name">Log out</span>
                    </Link>
                </Nav.Item>
            </Accordion>
        </React.Fragment>
    );
});

export default VerticalNav;
