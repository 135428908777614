import React, { useEffect, useState } from 'react';
import { useHistory, Link } from "react-router-dom";
import {
    Container,
    Row,
    Col,
    Card,
    Tab,
    Form,
    Button,
    Nav,
    Alert,
    Modal,
    ButtonGroup,
    Table
} from 'react-bootstrap'
import './assessment.scss';
import { useGetAssessmentAreaQuery, useGetAssessmentFilterQuery, useGetSavedAssessmentQuery, useSaveAssessmentMutation } from '../../../store/assesment/assesmentSlice'
import AssessmentTable from './AssessmentTable';

const AllAssesment = () => {
    const [assessmentArea, setAssesmentArea] = useState()
    const [assesment, setAssesment] = useState();
    const [childAssesment, setChildAssesement] = useState()
    const [filterData, setFilterData] = useState()
    const [resultData, setResultData] = useState()
    const { data: assessmentAreaData } = useGetAssessmentAreaQuery()
    const { data: filterAssessmentData } = useGetAssessmentFilterQuery(filterData, {
        skip: !filterData
    })
    const { data: savedAssessment } = useGetSavedAssessmentQuery()
    const [uniqueArray, setUniqueArray] = useState([]);
    useEffect(() => {
        if (filterAssessmentData) {
            const uniqueItems = [];
            const uniqueIds = new Set();
            filterAssessmentData.forEach(item => {
                if (!uniqueIds.has(item.Transition_Planning_Domains
                    )) {
                    uniqueItems.push(item);
                    uniqueIds.add(item.Transition_Planning_Domains);
                }
            });
            setUniqueArray(uniqueItems);
        }
    }, [filterAssessmentData]);



    const handleParentData = (e) => {
        let value = e.target.value;
        setAssesmentArea(value)
        assessmentAreaData?.filter((item) => {
            if (value === item?._id) {
                return item
            }
        })?.map((item) => {
            setAssesment(item?.Assessment)
            setChildAssesement(item?.Assessment[0])
        })
        if (value === "assessment-area") {
            setAssesment([])
            setAssesmentArea(false)
        }
    }

    const handleChildData = (e) => {
        let value = e.target.value
        setChildAssesement(value)

    }

    const handleSearch = () => {
        setFilterData({
            assesment_area: assessmentArea,
            child_assessment: childAssesment
        })
    }
    console.log("filterassessment",filterAssessmentData)

    
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12}>
                        <div className='main-header assessmentsHeader'>
                            <div className='d-flex inner-header'>
                                <h2 className='d-flex justify-content-start'>
                                    Assessments
                                </h2>
                                <Link to="/dashboards/app/my-assesment"
                                    className='d-flex justify-content-end'>
                                    <button className='btn btn-primary'>
                                        My Favourites
                                    </button>
                                </Link>
                            </div>
                            <p className="text-start"
                            style={{
                                
        fontSize: "15px",
    }}
                            >
                                REFINE YOUR SEARCH
                            </p>
                            <div className='d-flex justify-content-between assesmentSearch'>
                                <Form.Group className="form-group col-sm-4 assessment-area-container">
                                    <Form.Label className="form-label">
                                        <b className='assessment-area'>
                                            Assessment Area
                                        </b>
                                    </Form.Label>
                                    <Form.Select
                                        className="form-select"
                                        aria-label="Default select example 2"
                                        id="is_public"
                                        onChange={handleParentData}
                                    >
                                        <option value="assessment-area">Select Assessment Area</option>
                                        {assessmentAreaData?.map((item, index) => {
                                            return (
                                                <>
                                                    <option value={item?._id}>{item?._id}</option>
                                                </>
                                            )
                                        })}
                                    </Form.Select>
                                </Form.Group>
                                <Form.Group className="form-group col-sm-4 assessment-container">
                                    <Form.Label className="form-label">
                                        <b className='assessment'>
                                            Assessment
                                        </b>
                                    </Form.Label>
                                    <Form.Select
                                        className="form-select"
                                        aria-label="Default select example 2"
                                        id="is_public"
                                        onChange={handleChildData}
                                        disabled={!assessmentArea}
                                    >
                                        {
                                            assesment?.map((item) => {
                                                return (
                                                    <>
                                                        <option value={item}>{item}</option>
                                                    </>
                                                )
                                            })
                                        }
                                    </Form.Select>
                                </Form.Group>
                                <Col sm={2} className='d-flex align-items-center mt-2 ml-2'>
                                    <button className='btn btn-primary search-btn' onClick={handleSearch}>
                                        Search
                                    </button>
                                </Col>
                            </div>
                        </div>
                        {assessmentArea ? <div>
                            <Row className={filterAssessmentData?.length ? "d-block my-4 d-flex justify-content-center align-items-center" : "d-none"}>
                                <div className='col-lg-8'>
                                    <h2 className='assessment-text'>
                                        Assessment - {filterData?.child_assessment}
                                    </h2>
                                </div>
                                <div className='col-lg-3 d-flex justify-content-center learn-more'>
                                    <a 
                                        href={filterAssessmentData?.length ? filterAssessmentData[0].PDF_Link : ""}
                                        target="_blank"
                                    >
                                        <button>
                                            Learn More
                                        </button>
                                    </a>
                                </div>
                            </Row>
                            <div className={filterAssessmentData?.length ? "d-block mt-1 assesmentTable" : "d-none"}>
                                <Table>
                                    <thead>
                                        <tr>
                                            <th style={{
                                                color: "white",
                                                background: "#20639A",
                                                border: "1px solid white"
                                            }}>
                                            </th>
                                            <th style={{
                                                color: "white",
                                                background: "#20639A",
                                                border: "1px solid white",
                                                textTransform: "capitalize",
                                                fontSize: "18px"
                                            }}>
                                                Transition Planning Domains
                                            </th>
                                            <th style={{
                                                color: "white",
                                                background: "#20639A",
                                                border: "1px solid white",
                                                textTransform: "capitalize",
                                                fontSize: "18px"
                                            }}>
                                                Assessment Types
                                            </th>
                                        </tr>
                                    </thead>
                                    <tbody style={{ background: "white" }}>
                                        {uniqueArray?.map((item) => {
                                            return (
                                                <AssessmentTable item={item} />
                                            )
                                        })}
                                    </tbody>
                                </Table>
                            </div>
                        </div> : ""}
                    </Col>
                </Row>
            </Container>
        </>
    )
}

export default AllAssesment;