import React, { useEffect, useState } from "react";
import {
    Row,
    Col,
    // Card,
    Form,
    Container,
    Nav,
    Button,
    Table,
    Tab,
    ToggleButton
} from "react-bootstrap";
import Card from "../../components/Card";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./index.scss";
import config from "../../store/config.json"


// images

import user04 from "../../assets/images/user/04.jpg";
import { preventDefault } from "@fullcalendar/react";
import { useDispatch, useSelector } from "react-redux";
import { useGetUserListByAdminQuery } from "../../store/userList/userListByAdminApiSlice";
import { userListByAdmin } from "../../store/userList/action";
import GenericTable from "../table/GenericDataTable";

const RenderCard = ({ title, value }) => {
    return (
        <Card >

            <Card.Body>
                <h4 className="table_heading_text">{title} </h4>
                <h6 style={{ textAlign: "center" }}>{value}</h6>
            </Card.Body>
        </Card>
    );
}


const UserList = () => {
    const [searchSelect, setSearchSelect] = useState("first_name");
    const [searchedUser, setSearchedUser] = useState([])
    const { data, refetch } = useGetUserListByAdminQuery()
    const userInfo = useSelector((state) => state.user.userDetails);

    const [userList, setUserList] = useState([]);
    const [usersCount, setUsersCount] = useState({
        "student": 0, "family": 0, "educator": 0, "admin": 0, "agency": 0
    });

    const history = useHistory();
    const dispatch = useDispatch();

    const columns = [
        {
            title: 'Name',
            // dataIndex: 'first_name',
            sorter:(a, b) =>a.first_name.localeCompare(b.first_name),
            render: (item) =>{
                return  <div className="img_name_div">
                <Link to={`/dashboard/app/profile-view/${item._id}`}>
                  <img
                    height={30}
                    width={30}
                    loading="lazy"
                    className="rounded-circle"
                    src={item.profile_picture ? item.profile_picture : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                    alt=''
                  />
                </Link>
                <div className=" ms-1">
                  <h6 className="mb-0">
                    <Link to={`/dashboard/app/profile-view/${item._id}`}>
                      <div className="tbl_name" style={{display:'flex',flexWrap:'wrap'}}>
                        <span> {item.first_name} </span>
                        &nbsp;
                        <span> {item.last_name}</span>
                      </div>
                    </Link>
                  </h6>
                </div>
              </div>
                // item?.first_name + " " + item?.last_name
            },
        },
        {
            title: 'Email',
            dataIndex: 'email',
            sorter: {
                compare: (a, b) => a.email.localeCompare(b.email),
            },
        },
        {
            title: 'Status',
            dataIndex: 'is_active',
            render: (text) =>{ 
               return text ? <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><div style={{height:'10px',width:'10px',borderRadius:'50%',backgroundColor:'green'}}></div></div> : 
               <div style={{display:'flex',alignItems:'center',justifyContent:'center'}}><div style={{height:'10px',width:'10px',borderRadius:'50%',backgroundColor:'red'}}></div></div>
            },
            sorter: {
                compare: (a, b) => JSON.stringify(a.is_active).length - JSON.stringify(b.is_active).length,
            },
            //   sorter: {
            //     compare: (a, b) => a.chinese - b.chinese,
            //     multiple: 3,
            //   },
        },
        {
            title: 'Verified Status',
            dataIndex: 'is_otp_verified',
            render: (text) =>{ 
               return text ? "Verified" : "Unverified"
            },
            sorter: {
                compare: (a, b) => JSON.stringify(b.is_otp_verified).length - JSON.stringify(a.is_otp_verified).length,
            },
            //   sorter: {
            //     compare: (a, b) => a.chinese - b.chinese,
            //     multiple: 3,
            //   },
        },
        {
            title: 'User Role',
            dataIndex: 'user_type',
            sorter: {
                compare: (a, b) => a.user_type?.localeCompare(b.user_type),
            },
            //   sorter: {
            //     compare: (a, b) => a.chinese - b.chinese,
            //     multiple: 3,
            //   },
        },
        {
            title: 'Account Created',
            dataIndex: 'createdAt',
            render: (text) =>{ 
                return text ? text?.split("T")[0] :"-"
             },
             sorter:{
                compare:(a,b)=>new Date(a.createdAt) - new Date(b.createdAt)
             }
            //   sorter: {
            //     compare: (a, b) => a.chinese - b.chinese,
            //     multiple: 3,
            //   },
        },
        {
            title: 'Last Login',
            dataIndex: 'last_login',
            render: (text) =>{ 
                return text ? text.split("T")[0] :"-"
                
             },
             sorter:{
                compare:(a,b)=>new Date(a.last_login) - new Date(b.last_login)
             }
            //   sorter: {
            //     compare: (a, b) => a.chinese - b.chinese,
            //     multiple: 3,
            //   },
        },
        {
            title: 'Action',
            render: (item) =>{ 
                return   <select
                className="form-select"
                name="choices-multiple-default"
                id="choices-multiple-default"
                value="none"
                onChange={(e) => handleAction(e, item._id,item)}
                required
              >
                <option value="none"></option>
                {/* <option value="view">View</option> */}
                <option value="edit">Edit</option>
                <option value="view">View</option>
                <option value="delete">
                  {item.is_active
                    ? "Deactivate"
                    : "Activate"}
                </option>
                {/* {!item?.is_otp_verified && <option value="verify">Verify mail</option>} */}

              </select>
                
             },
        },

    ];
    const data2 = [
        {
            key: '1',
            name: 'John Brown',
            chinese: 98,
            math: 60,
            english: 70,
        },
        {
            key: '2',
            name: 'Jim Green',
            chinese: 98,
            math: 66,
            english: 89,
        },
        {
            key: '3',
            name: 'Joe Black',
            chinese: 98,
            math: 90,
            english: 70,
        },
        {
            key: '4',
            name: 'Jim Red',
            chinese: 88,
            math: 99,
            english: 89,
        },
    ];

    const getuserList = () => {
        refetch()
    };

    const handleSearch = (e) => {
        e.preventDefault()
        let searchText = e.target.value
        // console.log("item",searchText)
        // var tempArr = []
        // searchedUser?.map((item) => {
        //     console.log('item',item)
        //     if (item?.first_name
        //         ?.toLowerCase == searchText.toLowerCase() || item?.email?.toLowerCase == searchText.toLowerCase() ) {
        //             console.log("hiii")
        //         tempArr.push(item)
        //     }
        // })
        const searchedData= searchedUser.filter(item=> item.first_name.toLowerCase().includes(searchText.toLowerCase()) || item.email.toLowerCase().includes(searchText.toLowerCase()))
        setUserList(searchedData)
    }


    const handleActive = (data, id) => {
        fetch(
            `${config.WEBPORTAL_URL}/api/users/active-deactive-user/${id}/${data}`,
            {
                method: "PATCH",
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        refetch()
                    });
                } else {
                }
            })
            .catch((error) => { });
    };

    const handleVerify = (id) => {
        fetch(
            `${config.WEBPORTAL_URL}/api/users/user_active-deactive`,
            {
                method: "PUT",
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
                body: JSON.stringify({
                    "user_id": id,
                    "status": true
                })
            }
        )
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        refetch()
                    });
                } else {
                }
            })
            .catch((error) => { });
    };

    const handleAction = (e, id, item) => {
        const value = e?.target.value;
        if (value === "view") {
            history.push(`/dashboard/app/profile-view/${id}`);
        }
        if (value === "edit") {
            history.push(`/dashboard/app/profile-edit-by-admin/${id}`);
        }
        if (value === "delete") {
            handleActive(item.is_active ? 0 : 1, id);
        }
        if (value === "verify") {
            handleVerify(id);
        }
    };

    const handleSelection = (e) => {
        const value = e?.target.value;
        setSearchSelect(value)
    };

    const handleUsersCount = (allUsers) => {
        let counts = {
            "student": 0, "family": 0, "educator": 0, "admin": 0, "agency": 0
        }
        allUsers.map(value => {
            counts[value.user_type] = counts[value.user_type] + 1

        })
        setUsersCount(counts)
    }
    useEffect(() => {
        if (userInfo?.user_type !== "admin") {
            history.push(`/dashboards`);
        }

        if (data && data?.status === 1 && data?.data?.length > 0) {
            let fetcedUserList = data?.data.map((item, index) => {
                return { key: index + 1, ...item }
            })
            setSearchedUser(fetcedUserList)
            setUserList(fetcedUserList);
            dispatch(userListByAdmin(fetcedUserList))
            handleUsersCount(fetcedUserList)
        }
    }, [data]);

    const RenderCheckboxVerified = ({ isVerified }) => {
        const [verified, setVerified] = useState(isVerified)
        return <Form>
            <Form.Check // prettier-ignore
                type="switch"
                id="custom-switch"
                value={verified}
                onChange={e => setVerified(e.target.value)}
            />

        </Form>
    }
    console.log(userList, "userList");
    return (
        <>
            <Container style={{ maxWidth: "67.5rem" }}>
                <Row>
                    <Col lg={12} style={{ display: "flex", justifyContent: "space-between" }}>
                        <RenderCard title={"Total Users"} value={searchedUser?.length} />
                        <RenderCard title={"Students"} value={usersCount?.student} />
                        <RenderCard title={"Admin"} value={usersCount?.admin} />
                        <RenderCard title={"Family/Caregiver"} value={usersCount?.family} />
                        <RenderCard title={"Educator"} value={usersCount?.educator} />
                        <RenderCard title={"Agency/Community Partner"} value={usersCount?.agency} />

                    </Col>
                    <Col lg={12} style={{ display: "flex", justifyContent: "space-between" }}>

                    </Col>
                    <Col lg={12}>
                        <Card>
                            <Card.Body className="d-flex align-items-center justify-content-between">
                                <div>
                                    <h4 className="table_heading_text">Portal Users</h4>
                                </div>

                                <div style={{ display: "flex", justifyContent: "space-between", gap: "4px" }}>
                                    {/* <select
                                        className="form-select"
                                        name="choices-multiple-default"
                                        id="choices-multiple-default"
                                        value={searchSelect}
                                        onChange={(e) => handleSelection(e)}
                                        required
                                    >
                                        <option value="first_name">Name</option>
                                        <option value="email">Email</option>
                                        <option value="user_type">User Type</option>

                                    </select> */}
                                    <div className="table_search ">
                                        <Form action="#" className="searchbox">
                                            <Link className="search-link" to="#">
                                                <span
                                                    className="material-symbols-outlined"
                                                    onClick={() => getuserList()}
                                                >
                                                    search
                                                </span>
                                            </Link>
                                            <Form.Control
                                                type="text"
                                                className="text search-input bg-soft-primary connection-input"
                                                placeholder="Search..."
                                                onChange={handleSearch}
                                            />
                                        </Form>
                                    </div>
                                </div>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>
            <Container style={{ maxWidth: "67.5rem" }}>
                <div className="table_container">
                    <Row>
                        <Col lg={12} sm={12}>
                            <div className="">
                                {/* <div id="left-tabs-example" defaultActiveKey="first">
            <div>
              <div className="">
                <div eventKey="first"> */}
                                <Card>
                                    <Card.Body className="p-0">
                                        <GenericTable columns={columns} dataSource={userList} />
                                    </Card.Body>
                                </Card>
                                {/* </div>
              </div>
            </div>
          </div> */}
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </>
    );
};

export default UserList;
