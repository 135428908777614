import { useEffect } from "react";
import { Alert, Container, Form, Col, Row,Spinner } from "react-bootstrap";
import Card from "../../../components/Card";
import { Link, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import {
  useAddMemberMutation,
  useGetGroupsByUserIdQuery,
  useGetGroupsQuery,
} from "../../../store/group/groupApiSlice";
// images
import defaultBg from "../../../assets/images/bg/bg.jpg";
import user05 from "../../../assets/images/user/05.jpg";
import { useState } from "react";
import { Last } from "react-bootstrap/esm/PageItem";
const Groups = () => {
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;
  const [group, setGroup] = useState([]);
  const [pvtGroup, setPvtGroup] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [allPvtGroup, setAllPvtGroup] = useState([]);
  const [counter, setCounter] = useState(1);
  const [allGroup, setAllGroup] = useState([]);
  const [addAlert, setAddAlert] = useState(false);
  const { data, refetch } = useGetGroupsQuery(counter);
  const getPrivateGrp = useGetGroupsByUserIdQuery(userId);
  const [addMember] = useAddMemberMutation();
  const [loadingMore, setLoadingMore] = useState(false); // Loading more data on scroll
  const [itemsToDisplay, setItemsToDisplay] = useState(6);
  const itemsPerScroll = 3;
  const history = useHistory();
  let isAdmin = userInfo?.user_type === "admin"

  const memberAdd = async (group_id) => {
    console.log("click")
    let result = await addMember({
      group_id,
      member_id: userId,
    });
    console.log("click",result)
    if (result?.data?.status === 1) {
      history.push(`/dashboards/app/group-detail/${group_id}`);
    }
  };
  console.log('lengthww',group.length)

  useEffect(() => {
    if (data && data.status === 1) {
      // setAllGroup(data?.data)
      setGroup(data.data);
    }
  }, [data, counter]);
  useEffect(() => {
    if (getPrivateGrp && getPrivateGrp?.data?.status === 1) {
      // setAllPvtGroup(getPrivateGrp?.data?.data)
      setPvtGroup(getPrivateGrp?.data?.data);
    }
  }, [getPrivateGrp]);

  const handleInfiniteScroll = () => {
    if (
      window.innerHeight + document.documentElement.scrollTop + 1 >
      document.documentElement.scrollHeight
    ) {
      setCounter((prev) => prev + 1);
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    window.addEventListener("scroll", handleInfiniteScroll);
    return () => window.removeEventListener("scroll", handleInfiniteScroll);
  }, []);
const handleScroll = () =>{
  const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
  if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
    setLoadingMore(true);
    setTimeout(() => {
      setItemsToDisplay(prevItems => prevItems + itemsPerScroll);
      setLoadingMore(false);
    }, 1000); // Simulating loading delay, replace with your actual data fetching logic
  }
  };
  
  useEffect(() => {
  window.addEventListener('scroll', handleScroll);
  return () => {
    window.removeEventListener('scroll', handleScroll);
  };
  }, [loadingMore]);
  
  return (
    <>
      <Container>
        <Row>
          <Col lg={12}>
            <Card>
              <Card.Body
                className="d-flex justify-content-between grp-card-head allResourceBox"
                style={{ position: "relative" }}
              >
                <div className="allResource" style={{ position: "relative" }}>
                  <h4 className="grp-text">Groups</h4>
                </div>

                <div className="table_search resourceSearch">
                  <Form action="#" className="searchbox">
                    <div className="search-link">
                      <span className="material-symbols-outlined">search </span>
                    </div>
                    <Form.Control
                      type="text"
                      className="text search-input bg-soft-primary connection-input"
                      placeholder="Search..."
                      onChange={(event) => setSearchTerm(event.target.value)}
                    />
                  </Form>
                </div>
                {userInfo?.user_type === "student" ||
                  userInfo?.user_type === "family" ? (
                  ""
                ) : (
                  <button type="button" className="btn btn-primary grp-btn">
                    <Link
                      to="/dashboards/app/create-group"
                      style={{ color: "white" }}
                      className=""
                    >
                      CREATE GROUP
                    </Link>
                  </button>
                )}
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>

      <div className="container" style={{ color: "white" }}>
        <img
          src="https://www.pasecondarytransition.com/assets21/webpimages/Practices-ban.webp"
          style={{ width: "100%", height: "auto", borderRadius: "5px" }}
        />
      </div>

      <div id="content-page" className="content-page">
        <Container>
          {/* <div className="d-grid gap-3 d-grid-template-19rem-19rem mb-3 justify-content-between"> */}
          <div className="d-grid gap-lg-2 gap-xl-4 gap-xxl-4 d-grid-template-19rem-19rem mb-3 justify-content-center">

            {group
              ?.filter((item) => {
                if (searchTerm === "") {
                  return item;
                } else if (
                  item.title.toLowerCase().includes(searchTerm.toLowerCase())
                ) {
                  return item;
                }
              })
              ?.slice(0,itemsToDisplay).map((item, i) => {
                var match = false;
                return (
                  <Card className={`mb-0 grp-card ${item?.is_deleted ? "opacity-25" : ""}`} key={i}>
                    <Link to={!item?.is_deleted || isAdmin ? `/dashboards/app/group-detail/${item._id}` : '#'} >
                      <div className="top-bg-image">
                        <img
                          src={
                            item.background_image
                              ? item.background_image
                              : defaultBg
                          }
                          className="img-fluid w-100"
                          style={{ height: "150px", objectFit: "cover" }}
                          alt="group-bg"
                        />
                      </div>
                    </Link>
                    <Card.Body className=" text-center" key={i}>
                      <Link to={!item?.is_deleted || isAdmin ? `/dashboards/app/group-detail/${item._id}` : '#'}>
                        <div className="group-icon">
                          <img
                            src={
                              item.group_image ||
                              "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                            }
                            alt="profile-img"
                            className="rounded-circle img-fluid avatar-80 avatarCircle"
                          />
                        </div>
                      </Link>
                      <Link to={!item?.is_deleted || isAdmin ? `/dashboards/app/group-detail/${item._id}` : '#'}>
                        <div className="group-info pt-2 pb-2">
                          <h4 className="groupName">
                            <Link
                              to={!item?.is_deleted || isAdmin ? `/dashboards/app/group-detail/${item._id}` : '#'}
                              style={{ color: "#45505f" }}
                            >
                              {item.title}
                            </Link>
                          </h4>
                          {item.is_public ? <p>Public</p> : <p>Private</p>}
                        </div>

                        <div className="group-details pb-3 w-100 ">
                          {/* <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0"> */}
                            <ul className="ps-0">
                            <li className="" style={{display: 'flex',alignItems:'flex-start',justifyContent:'space-between'}}>
                              <p className="mb-0" style={{width:'50%',display:'flex',alignItems:'flex-start'}}>Created By</p>
                              <h5 className="mb-0" style={{width:'50%',display:'flex',alignItems:'flex-start',textAlign:'left'}}>{item.owner.username}</h5>
                            </li>
                            {/* <li className="pe-1 ps-1">
                              <p className="mb-0">Member</p>
                              <h6>{item?.members_subset?.length}</h6>
                            </li> */}
                          </ul>
                        </div>
                      </Link>

                      {item.members_subset.map((item) => {
                        if (item.ref === userId) {
                          match = true;
                        }
                        return;
                      })}

                      {match ? (
                        <button
                          type="submit"
                          className="btn btn-primary d-block w-100 btn-col"
                          style={{ backgroundColor: "#35aba0", border: "none" }}
                        >
                          Joined
                        </button>
                      ) : (
                        <>
                          {!item?.is_deleted && <button
                            type="submit"
                            className="btn btn-primary d-block w-100 btn-col"
                            onClick={() => memberAdd(item._id)}
                          >
                            Join
                          </button>}
                        </>
                      )}
                    </Card.Body>
                  </Card>
                );
              })}

            {/* ===== pvt group ======== */}
            {itemsToDisplay>group.length  && pvtGroup
              ?.filter((item) => {
                if (item?.group?.is_public === false && searchTerm === "") {
                  return item;
                } else if (
                  item?.group?.is_public === false &&
                  item.group.title
                    .toLowerCase()
                    .includes(searchTerm.toLowerCase())
                ) {
                  return item;
                }
              })
              ?.map((item, i) => {
                var match = false;
                return (
                  <Card className="mb-0 grp-card" key={i}>
                    <Link to={`/dashboards/app/group-detail/${item.group._id}`}>
                      <div className="top-bg-image">
                        <img
                          src={
                            item.group.background_image
                              ? item.group.background_image
                              : defaultBg
                          }
                          className="img-fluid w-100"
                          style={{ height: "150px", objectFit: "cover" }}
                          alt="group-bg"
                        />
                      </div>
                    </Link>
                    <Card.Body className=" text-center" key={i}>
                      <Link
                        to={`/dashboards/app/group-detail/${item.group._id}`}
                      >
                        <div className="group-icon">
                          <img
                            src={
                              item.group.group_image ||
                              "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                            }
                            alt="profile-img"
                            className="rounded-circle img-fluid avatar-80 avatarCircle"
                          />
                        </div>
                      </Link>
                      <Link
                        to={`/dashboards/app/group-detail/${item.group._id}`}
                      >
                        <div className="group-info pt-2 pb-2">
                        <h4 className="groupName">
                            <Link
                              to={`/dashboards/app/group-detail/${item.group._id}`}
                              style={{ color: "#45505f" }}
                            >
                              {item.group.title}
                            </Link>
                          </h4>
                          {item.group.is_public ? (
                            <p>Public</p>
                          ) : (
                            
                            <p>Private</p>
                          )}
                          <div className="group-details pb-3 w-100 ">
                          {/* <ul className="d-flex align-items-center justify-content-between list-inline m-0 p-0"> */}
                            <ul className="ps-0">
                            <li className="" style={{display: 'flex',alignItems:'flex-start',justifyContent:'space-between'}}>
                              <p className="mb-0" style={{width:'50%',display:'flex',alignItems:'flex-start'}}>Created By</p>
                              <h5 className="mb-0" style={{width:'50%',display:'flex',alignItems:'flex-start',textAlign:'left'}}>{item.group.owner.username}</h5>
                            </li>
                            {/* <li className="pe-1 ps-1">
                              <p className="mb-0">Member</p>
                              <h6>{item?.members_subset?.length}</h6>
                            </li> */}
                          </ul>
                        </div>
                        </div>
                      </Link>

                      {item.group.members_subset.map((item) => {
                        if (item.ref === userId) {
                          match = true;
                        }
                        return;
                      })}
                      {match ? (
                        <button
                          type="submit"
                          className="btn btn-primary d-block w-100 btn-col"
                          style={{ backgroundColor: "#35aba0", border: "none" }}
                        >
                          Joined
                        </button>
                      ) : (
                        <>
                          <button
                            type="submit"
                            className="btn btn-primary d-block w-100 btn-col"
                            onClick={() => memberAdd(item.group._id)}
                          >
                            Join
                          </button>
                        </>
                      )}
                    </Card.Body>
                  </Card>
                );
              })
            }
              
          </div>
          {loadingMore && itemsToDisplay<group.length && <Col lg={12} className="text-center mt-3 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </Col>
}
        </Container>
      </div>
    </>
  );
};

export default Groups;
