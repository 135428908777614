import { apiSlice } from "../apiSlice";

export const notificationApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    updateNotificationCount: builder.mutation({
      query: () => ({
        url: `/users/update-notification-count`,
        method: "PUT",
        // body: data,
      }),
    }),

   
  }),
});

export const {
 useUpdateNotificationCountMutation
} = notificationApiSlice;