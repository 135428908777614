import { apiSlice } from "../apiSlice";

export const userApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    signup: builder.mutation({
      query: (data) => ({
        url: "/auth/register",
        method: "POST",
        body: { ...data },
      }),
    }),
    login: builder.mutation({
      query: (data) => ({
        url: "/auth/login",
        method: "POST",
        body: { ...data },
      }),
    }),
    confirmMail: builder.mutation({
      query: (data) => ({
        url: "/auth/verify-otp",
        method: "POST",
        body: { ...data },
      }),
    }),
    recoveryPassword: builder.mutation({
      query: (data) => ({
        url: "/auth/forgot-password",
        method: "POST",
        body: { ...data },
      }),
    }),
    recoveryOtp: builder.mutation({
      query: (data) => ({
        url: "/auth/forgot-password-send-otp",
        method: "POST",
        body: { ...data },
      }),
    }),
    getInvitaionCount: builder.query({
      query: () => ({
        url: "users/get-notification-count",
        method: "GET",
      }),
    }),
    getUserDetails: builder.query({
      query: (id) => ({
        url: id ? `users/get-user-details/${id}` : `users/get-user-details`,
        method: "GET",
        // providesTags: ['User'],
        // refetchOnMountOrArgChange :true
      }),
      providesTags: (result, error, args) => {
        return [
          { type: "User", id: "list" },
        ]
      }
    }),
    editUserDetails: builder.mutation({
      query: (data) => ({
        url: "users/update-user-details",
        method: "PUT",
        body: { ...data },
      }),
      invalidatesTags: (result, error, args) => {
        return [
          { type: "User", id: "list" },
        ]
      }
      // invalidatesTags: ['User']
    }),
    fileUpload: builder.mutation({
        query: (data) => ({
          url: "/aws/upload_file",
          method: "POST",
          body: data ,
        }),
      }),
      changePassword: builder.mutation({
        query: (data) => ({
          url: "/users/change-password",
          method: "PUT",
          body: data ,
        }),
      }),
      getAllUsers: builder.query({
        query: () => ({
          url: `users/get-all-users`,
          method: "GET",
        }),
      }),
      getAllRelations: builder.query({
        query: () => ({
          url: `users/user-relation`,
          method: "GET",
        }),
      }),
  }),
});

export const {
  useSignupMutation,
  useLoginMutation,
  useConfirmMailMutation,
  useRecoveryPasswordMutation,
  useRecoveryOtpMutation,
  useGetUserDetailsQuery,
  useEditUserDetailsMutation,
  useFileUploadMutation,
  useChangePasswordMutation,
  useGetAllUsersQuery,
  useGetInvitaionCountQuery,
  useGetAllRelationsQuery
} = userApiSlice;
