import React, { useState, useEffect, useRef } from 'react';
import { Alert, Container, Form, Col, Row,Spinner } from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";
import Card from '../../../components/Card';
import ResourceCard from './ResourceCard';
import Loading from '../Loading';
import config from "../../../store/config.json"
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import {debounce, throttle} from 'lodash'




const Resources = () => {
    const [resourceData, setResourceData] = useState([])
    const [myResources, setMyResources] = useState([])
    const [searchTerm, setSearchTerm] = useState("")
    const [loading, setLoading] = useState(true);
    const [loadingMore, setLoadingMore] = useState(false); // Loading more data on scroll
  const [itemsToDisplay, setItemsToDisplay] = useState(6);
  const itemsPerScroll = 3;



    const getResources = () => {
        fetch("https://pasecondarytransition.com/resourcejson")
            .then(response => {
                return response.json()
            })
            .then(data => {
                setResourceData(data.data)
                setLoading(false);
            })
    }

    useEffect(() => {
        getResources();
        setItemsToDisplay(6)

    }, [])


    const getSavedResource = () => {
        fetch(
            `${config.WEBPORTAL_URL}/api/bookmark/get-bookmark`,
            {
                method: "GET",
                headers: {
                    authorization: `Bearer ${localStorage.getItem("token")}`,
                    "content-type": "application/json",
                },
            }
        )
            .then((response) => {
                if (response.ok) {
                    response.json().then((data) => {
                        setMyResources(data)
                    });
                } else {
                }
            })
            .catch((error) => { });
    };

    useEffect(() => {

        getSavedResource();
    }, [])
const handleScroll = () =>{
const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
  setLoadingMore(true);
  setTimeout(() => {
    setItemsToDisplay(prevItems => prevItems + itemsPerScroll);
    setLoadingMore(false);
  }, 1000); // Simulating loading delay, replace with your actual data fetching logic
}
};

useEffect(() => {
window.addEventListener('scroll', handleScroll);
return () => {
  window.removeEventListener('scroll', handleScroll);
};
}, [loadingMore]);

    

    
    return (
        <>
            <Container>
                <Row>
                    <Col lg={12}>
                        <Card>
                            <Card.Body className="d-flex justify-content-between grp-card-head allResourceBox" style={{ position: "relative" }}>
                                <div className="allResource" style={{ position: "relative" }}>
                                    <h4 className="grp-text">All Resources</h4>
                                </div>

                                <div className="table_search resourceSearch">
                                    <Form action="#" className="searchbox">
                                        <div className="search-link">
                                            <span className="material-symbols-outlined">search </span>
                                        </div>
                                        <Form.Control
                                            type="text"
                                            className="text search-input bg-soft-primary connection-input"
                                            placeholder="Search..."
                                            onChange={(event) => setSearchTerm(event.target.value)}
                                        />
                                    </Form>
                                </div>
                                <button type="button" className="btn btn-primary grp-btn">
                                    <Link to="/dashboards/app/resources/my-resources" style={{ color: "white" }} className="">
                                        My Favourites
                                    </Link>
                                </button>
                            </Card.Body>
                        </Card>
                    </Col>
                </Row>
            </Container>

            <div className="container" style={{ color: "white" }}>
                <img
                    src="https://www.pasecondarytransition.com/assets21/webpimages/resources-ban.webp"
                    style={{ width: "100%", height: "auto", borderRadius: "5px" }}
                />
            </div>

            <div id="content-page" className="content-page resourcesTxt" >
                <Container>
                    {loading ? <Loading /> : <>
                        <div className="d-grid gap-lg-2 gap-xl-4 gap-xxl-4 d-grid-template-19rem-19rem mb-3 justify-content-center">
                            {resourceData?.filter((item,index) => {
                                if (searchTerm === "") {
                                    return item;
                                } else if (item?.title?.toLowerCase()?.includes(searchTerm?.toLowerCase())) {
                                    return item;
                                }
                            }).slice(0,itemsToDisplay).map((item) => 
                                <ResourceCard item={item} myResources={myResources} />)
                            }
                           
                        </div>
                        {loadingMore && itemsToDisplay< resourceData.length &&  <Col lg={12} className="text-center mt-3 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </Col>
}
                    </>
                    }
                </Container>
                
            </div>
            <ToastContainer />
        </>
    )
}

export default Resources;