import React from 'react'
import {Switch,Route} from 'react-router-dom'
import ConfirmMail from '../views/auth/ConfirmMail'
import LockScreen from '../views/auth/LockScreen'
import Recoverpw from '../views/auth/Recoverypw'
import SignIn from '../views/auth/SignIn'
import SignUp from '../views/auth/SignUp'
import Error404 from '../views/errors/Error404'
import Error500 from '../views/errors/Error500'
import Maintenance from '../views/extrapages/Maintenance'
import ComingSoon from '../views/extrapages/ComingSoon'
import Forgotpw from '../views/auth/Forgotpw'
import TermConditions from '../views/auth/TermConditions'

const SimpleRouter = () => {
    return (
        <>
            <Switch>

                <Route  path="/auth/confirm-mail"               component={ConfirmMail}/>
                <Route  path="/auth/lock-screen"                component={LockScreen}/>
                <Route  path="/auth/recoverpw"                  component={Recoverpw}/>
                <Route  path="/auth/sign-in"                    component={SignIn}/>
                <Route  path="/auth/sign-up"                    component={SignUp}/>  
                <Route  path="/auth/forgotpw"                    component={Forgotpw} />
                <Route  path="/auth/term-conditions"                    component={TermConditions} />
                <Route  path="/errors/error404"                 component={Error404}/>  
                <Route  path="/errors/error500"                 component={Error500}/>

                <Route  path="/extra-pages/pages-maintenance"   component={Maintenance}/>
                <Route  path="/extra-pages/pages-comingsoon"    component={ComingSoon}/>
                
            </Switch>       
        </>
    )
}

export default SimpleRouter
