import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import {
  Row,
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import TextEditor from "./TextEditor";
import RenderFile from "./RenderFile";
import { useParams } from "react-router-dom";
import Loading from "../views/app/Loading";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import {
  useUpdatePostMutation,
  useFeedUploadMutation,
  useGetUrlMetaMutation,
} from "../store/post/postApiSlice";

export default function PostModal({ show, setShow, postDetails, getPost, page, getAllMedia }) {
  const [textEditorData, setTextEditorData] = useState(null);
  const [urlDetails, setUrlDetails] = useState({});
  const [loading, setLoading] = useState(false);
  const [file, setFile] = useState(null);
  const [updatePost] = useUpdatePostMutation();
  const [feedUpload] = useFeedUploadMutation();
  const [getUrlMeta] = useGetUrlMetaMutation();
  const myParams = useParams();
  const userInfo = useSelector((state) => state.user.userDetails);

  const user = postDetails?.created_by;
  useEffect(() => {
    if (postDetails?.text_editor_data) {
      setTextEditorData({ entityMap: {}, ...postDetails?.text_editor_data });
    }
  }, [postDetails]);

  const fetchUrlDetails = async (url) => {
    const result = await getUrlMeta({
      url: url,
    });
    if (result?.data?.status === 1 && result?.data?.data) {  
      setUrlDetails(result?.data?.data);
    }
  };

  const handleClose = () => {
    // setTextEditorData(null);
    // setFile(null)
    setShow(false);
  };

  const handleSubmit = async () => {
    setLoading(true);
    let multerData = new FormData();

    multerData.append("file", file);
    let multerPayload = {
      multerData: multerData,
      type: myParams?.group_id ? "group" : "main",
    };
    if (myParams?.group_id) {
      multerPayload.group_id = myParams?.group_id;
    }

    let url_meta_data = {}
    if (urlDetails) {
      url_meta_data = { ...urlDetails }
    }

    let payload;
    if (file) {
      const feedRes = await feedUpload(multerPayload);
      if (feedRes?.data?.status === 1  ) {
        payload = {
          id: postDetails._id,
          text_editor_data: textEditorData,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: file?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          type: "error",
        });
        setLoading(false);

        return;
      }
    } else {
      payload = {
        id: postDetails._id,
        text_editor_data: textEditorData,
      };
    }
    let studentId =
      userInfo?.user_type === "family" &&
        userInfo?.relation
          ?.map((userdetails) => userdetails._id)
          ?.includes(user?.ref)
        ? user?.ref
        : "";
    if (studentId) {
      payload.student_id = studentId;
    }
    const result = await updatePost({...payload, url_meta_data: url_meta_data});
    if (result?.data?.status === 1) {
       getPost.refetch();
       if(getAllMedia){
        getAllMedia()
       }
      setFile(null);
      setTextEditorData(null);
      setFile(null);
      setShow(false);
      getPost.refetch(page)
      setLoading(false);
      toast("Post updated successfully!!", {
        position: "top-right",
        autoClose: 3000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
    }
  };

  const uploadMediaHandle = (fileData) => {
    setFile(fileData);
  };
  return (
    <div>
      <Modal
        size="lg"
        className=" fade"
        id="post-modal"
        onHide={handleClose}
        show={show}
      >
        <Modal.Header className="d-flex justify-content-end">
          {/* <Modal.Title id="post-modalLabel">Edit Post</Modal.Title> */}
          <Link to="#" className="lh-1" onClick={handleClose}>
            <span className="material-symbols-outlined">close</span>
          </Link>
        </Modal.Header>
        <Modal.Body>
          <div className="d-flex align-items-center">
            <div className="user-img" >
              <img
                src={
                  user?.profile_picture
                    ? user?.profile_picture
                    : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                }
                alt="user1"
                className="avatar-50 rounded-circle img-fluid"
              />
            </div>
            <form
              className="post-text ms-3 w-100 "
              data-bs-toggle="modal"
              data-bs-target="#post-modal"
            >
              <TextEditor
                textEditorData={textEditorData}
                onChange={setTextEditorData}
                urlDetails={urlDetails}
                fetchUrlDetails={fetchUrlDetails}
              />
              {/* <input
            type="text"
            className="form-control rounded"
            onChange={handleTextChange}
            placeholder="Write something here..."
            style={{ border: "none" }}
          /> */}
            </form>
          </div>
          <hr />
          {loading ? (
            <Loading />
          ) : (
            <>
              <ul className="d-flex flex-column align-items-center justify-content-center list-inline m-0 p-0">
                <li className="col-md-6 mb-3">
                  <div
                    className="bg-soft-primary rounded p-2 pointer "
                    style={{ textAlign: "center" }}
                  >
                    <label className="shareOption">
                      <span style={{ cursor: "pointer", fontWeight: "600", color: "#000" }}>
                      Choose Photo, Video or Document
                      </span>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="file"
                        //  accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          uploadMediaHandle(e.target.files[0]);
                        }}
                      />
                    </label>
                  </div>
                </li>
                {file ? (
                  <RenderFile file={file} filetype={"filetype"} />
                ) : (
                  <RenderFile
                    fileUrl={
                      postDetails?.files ? postDetails?.files[0]?.url : null
                    }
                    filetype={
                      postDetails?.files
                        ? postDetails?.files[0]?.file_type
                        : null
                    }
                  />
                )}
              </ul>
              <hr />
              <button
                type="submit"
                className="btn btn-primary d-block w-100 mt-3"
                onClick={handleSubmit}
              >
                Post
              </button>
            </>
          )}

        </Modal.Body>
      </Modal>
    </div>
  );
}
