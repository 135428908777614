
import { configureStore,combineReducers } from '@reduxjs/toolkit';
import settingReducer from './setting/reducers';
import userReducer from './user/reducers';
import userListReducer from './userList/reducers'
import groupDetailReducer from './groups/groupDetailSlice';
import {apiSlice} from './apiSlice'
import storage from 'redux-persist/lib/storage';
import { persistReducer, persistStore } from 'redux-persist';
import connectionReducer from '../store/connection/reducer';
import thunk from 'redux-thunk';

const persistConfig = {
  key: 'root',
  storage,
}
const combinedReducer = combineReducers({ 
  [apiSlice.reducerPath]:apiSlice.reducer,
    setting: settingReducer,
    user:userReducer,
    userList: userListReducer,
    groupDetail: groupDetailReducer,
    connection: connectionReducer,
})

const rootReducer = (state, action) => {
  if (action.type === 'user/logoutUser') {
    
    storage.removeItem('persist:root')
    
    state = {};
  }
  return combinedReducer(state, action);
};
const persistedReducer = persistReducer(persistConfig, rootReducer)

export const store = configureStore({
  reducer:persistedReducer,
  middleware:getDefaultMiddleware=>getDefaultMiddleware({immutableCheck: false}).concat(apiSlice.middleware),
  devTools:true
});



export const persistor = persistStore(store)
