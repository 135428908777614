import React, { useState, useEffect, useRef } from "react";
import { useGetUserDetailsQuery, useGetAllRelationsQuery } from "../../store/user/userApiSlice";
import { useGetAllUsersQuery } from "../../store/user/userApiSlice";
import { useDeleteCommentMutation, useDeletePostMutation, useEditCommentMutation, useLikeCommentMutation } from "../../store/post/postApiSlice";
import { useGetConnectionQuery } from "../../store/connection/connectionApiSlice";
import { useSelector, useDispatch } from "react-redux";
import { setUser } from "../../store/user/action";
import Swal from "sweetalert2";
import {
  Row,
  Col,
  Container,
  Dropdown,
  Nav,
  Tab,
  Tabs,
  OverlayTrigger,
  Tooltip,
  Button,
  Modal,
} from "react-bootstrap";
import Card from "../../components/Card";
import CustomToggle from "../../components/Dropdowns";
import ShareOffcanvas from "../../components/ShareOffcanvas";
import TextEditor from "../../components/TextEditor";
import RenderFile from "../../components/RenderFile";
import FeedBlock from "../../components/FeedBlock";
import { useParams } from 'react-router-dom';
import pdfIcon from "../../assets/images/pdfIcon.png";
import docIcon from "../../assets/images/docImg.png";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';


import {
  useCreatePostMutation,
  useGetPostsForUserQuery,
  useFeedUploadMutation,
  useLikePostMutation,
  useCreateCommentMutation,
  useGetUrlMetaMutation,
  useCommentUploadMutation
} from "../../store/post/postApiSlice";
import config from "../../store/config.json"
import Loading from "./Loading";

import { Link } from "react-router-dom";
import FsLightbox from "fslightbox-react";

// images
import defaultBg from "../../assets/images/bg/bg.jpg";
import img1 from "../../assets/images/page-img/profile-bg1.jpg";
import bgImg from "../../assets/images/page-img/bg-Grey-img.jpg";
import img2 from "../../assets/images/user/11.png";
import img3 from "../../assets/images/icon/08.png";
import img4 from "../../assets/images/icon/09.png";
import img5 from "../../assets/images/icon/10.png";
import img6 from "../../assets/images/icon/11.png";
import img7 from "../../assets/images/icon/12.png";
import img8 from "../../assets/images/icon/13.png";
import img9 from "../../assets/images/page-img/07.jpg";
import img10 from "../../assets/images/page-img/06.jpg";
import user1 from "../../assets/images/user/1.jpg";
import user05 from "../../assets/images/user/05.jpg";
import user01 from "../../assets/images/user/01.jpg";
import user02 from "../../assets/images/user/02.jpg";
import user03 from "../../assets/images/user/03.jpg";
import user06 from "../../assets/images/user/06.jpg";
import user07 from "../../assets/images/user/07.jpg";
import user08 from "../../assets/images/user/08.jpg";
import user09 from "../../assets/images/user/09.jpg";
import user10 from "../../assets/images/user/10.jpg";
import user13 from "../../assets/images/user/13.jpg";
import user14 from "../../assets/images/user/14.jpg";
import user15 from "../../assets/images/user/15.jpg";
import user16 from "../../assets/images/user/16.jpg";
import user17 from "../../assets/images/user/17.jpg";
import user18 from "../../assets/images/user/18.jpg";
import user19 from "../../assets/images/user/19.jpg";
import p1 from "../../assets/images/page-img/p1.jpg";
import p3 from "../../assets/images/page-img/p3.jpg";
import icon1 from "../../assets/images/icon/01.png";
import icon2 from "../../assets/images/icon/02.png";
import icon3 from "../../assets/images/icon/03.png";
import icon4 from "../../assets/images/icon/04.png";
import icon5 from "../../assets/images/icon/05.png";
import icon6 from "../../assets/images/icon/06.png";
import icon7 from "../../assets/images/icon/07.png";
import g1 from "../../assets/images/page-img/g1.jpg";
import g2 from "../../assets/images/page-img/g2.jpg";
import g3 from "../../assets/images/page-img/g3.jpg";
import g4 from "../../assets/images/page-img/g4.jpg";
import g5 from "../../assets/images/page-img/g5.jpg";
import g6 from "../../assets/images/page-img/g6.jpg";
import g7 from "../../assets/images/page-img/g7.jpg";
import g8 from "../../assets/images/page-img/g8.jpg";
import g9 from "../../assets/images/page-img/g9.jpg";
import loader from "../../assets/images/page-img/page-load-loader.gif";
import small07 from "../../assets/images/small/07.png";
import small08 from "../../assets/images/small/08.png";
import small09 from "../../assets/images/small/09.png";
import small1 from "../../assets/images/small/07.png";
import small2 from "../../assets/images/small/08.png";
import small3 from "../../assets/images/small/09.png";
import small4 from "../../assets/images/small/10.png";
import small5 from "../../assets/images/small/11.png";
import small6 from "../../assets/images/small/12.png";
import small7 from "../../assets/images/small/13.png";
import small8 from "../../assets/images/small/14.png";
import user9 from "../../assets/images/user/1.jpg";
import img51 from "../../assets/images/page-img/51.jpg";
import img52 from "../../assets/images/page-img/52.jpg";
import img53 from "../../assets/images/page-img/53.jpg";
import img54 from "../../assets/images/page-img/54.jpg";
import img55 from "../../assets/images/page-img/55.jpg";
import img56 from "../../assets/images/page-img/56.jpg";
import img57 from "../../assets/images/page-img/57.jpg";
import img58 from "../../assets/images/page-img/58.jpg";
import img59 from "../../assets/images/page-img/59.jpg";
import img60 from "../../assets/images/page-img/60.jpg";
import img61 from "../../assets/images/page-img/61.jpg";
import img62 from "../../assets/images/page-img/62.jpg";
import img64 from "../../assets/images/page-img/64.jpg";
import img65 from "../../assets/images/page-img/65.jpg";
import img63 from "../../assets/images/page-img/63.jpg";
import backgroundImg from "../../assets/images/bg/bg.jpg";
import Video from "./Media/Video";
import Image from "./Media/Image";
import Document from "./Media/Document";
import { connectionSlice } from "../../store/connection/reducer";

const UserProfile = () => {
  const [allPosts, setAllPosts] = useState([]);
  const [mediaShow, setMediaShow] = useState(false)
  const [mediaSrc, setMediaSrc] = useState()
  const [allRelations, setAllRelations] = useState([]);
  const [show, setShow] = useState(false);
  const [counter, setCounter] = useState(1)
  const [details, setDetails] = useState({});
  const [textEditorData, setTextEditorData] = useState(null);
  const [urlDetails, setUrlDetails] = useState({});
  const [file, setFile] = useState(null);
  const [allMedia, setAllMedia] = useState([])
  const [emptyMedia, setEmptyMedia] = useState(false);
  const [loading, setLoading] = useState(false);
  const [createPost] = useCreatePostMutation();
  const [commentPost] = useCreateCommentMutation();
  const [feedUpload] = useFeedUploadMutation();
  const [likePost] = useLikePostMutation();
  const getPost = useGetPostsForUserQuery(counter);
  const getRelations = useGetAllRelationsQuery()
  const [getUrlMeta] = useGetUrlMetaMutation();
  const [deletePost] = useDeletePostMutation();
  const [delComment] = useDeleteCommentMutation();
  const [updateComment] = useEditCommentMutation();
  const [commentUpload] = useCommentUploadMutation()

  let dispatch = useDispatch();
  const myParams = useParams()

  const handleClose = () => {
    setTextEditorData(null);
    setUrlDetails({})
    setFile(null)
    setShow(false);
  };

  const handleShow = () => setShow(true);

  const uploadMediaHandle = (fileData) => {
    setFile(fileData);
  };

  const fetchUrlDetails = async (url) => {
    const result = await getUrlMeta({
      url: url,
    });
    if (result?.data?.status === 1 && result?.data?.data) {
      setUrlDetails(result?.data?.data)
    }
  };

  

  const getAllMedia = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/files/get-file-in-profile/bdfh`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setAllMedia(data)
            if (data?.message === "Files Not Found") {
              setEmptyMedia(true)
            }
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getAllMedia()
  }, [])

  const handleSubmit = async () => {
    setLoading(true)
    let multerData = new FormData();
    multerData.append("file", file);

    let payload;
    let multerPayload = {
      multerData: multerData,
      type: myParams?.group_id ? "group" : "main",
    };
    if (myParams?.group_id) {
      multerPayload.group_id = myParams?.group_id;
    }
    let url_meta_data = {}
    if (urlDetails) {
      url_meta_data = { ...urlDetails }
    }

    if (file) {
      const feedRes = await feedUpload(multerPayload);
      let feedUploadData = feedRes?.data?.status === 1 ? feedRes?.data?.data : console.log("ERROR in POST upload")
      payload = {
        // plain_text: text,
        text_editor_data: textEditorData,
        file_id: feedRes?.data?.data?._id,
        file_url: feedRes?.data?.data?.file_url,
        file_type: file?.type,
        file_details: feedUploadData
      };
    } else {
      payload = {
        // plain_text: text,
        text_editor_data: textEditorData,
      };
    }
    const result = await createPost({ ...payload, url_meta_data: url_meta_data });
    if (result?.data?.status === 1) {
      setLoading(false)
      getPost.refetch();
      getAllMedia();
      setFile(null);
      handleClose();
      toast(result.data.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
    } else {
      // console.log(result?.message,"mee2");

      setLoading(false)
      getPost.refetch();
      getAllMedia();
      setFile(null);
      handleClose();
      toast(result?.error?.data?.message || "Post failed", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "error",
      });
    }
  };

  const handleLike = async (id) => {
    const result = await likePost({
      post_id: id,
    });
  };

  const handleComment = async (id, comment,commentFile) => {
    let payload = {
      post_id: id,
      comment_text: comment,
      parent_comment: "",
    }
    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }

    const result = await commentPost(payload);
    if (result?.data?.status === 1) {
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
      getPost.refetch();
    }
  };

  const userInfo = useSelector((state) => state.user.userDetails);

  const [imageController, setImageController] = useState({
    toggler: false,
    slide: 1,
  });

  const { data: detailData } = useGetUserDetailsQuery(null);
  const { data: connectionData } = useGetConnectionQuery(null);
  const { postData } = useGetPostsForUserQuery(null);

  //invitation
  const [allConnections, setAllConnections] = useState([]);
  const [likeComment] = useLikeCommentMutation();

  useEffect(() => {

    setAllPosts(getPost?.data?.data);

  }, [getPost?.data?.data, counter]);


  const handleInfiniteScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 > document.documentElement.scrollHeight) {
      setCounter((prev) => prev + 1)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("scroll", handleInfiniteScroll)
    return () => window.removeEventListener("scroll", handleInfiniteScroll)
  }, [])


  useEffect(() => {
    if (getRelations?.data?.status === 1 && Array.isArray(getRelations?.data?.data) && getRelations?.data?.data.length > 0) {
      setAllRelations(getRelations?.data?.data);
    }
  }, [getRelations?.data]);

  useEffect(() => {
    if (connectionData) {
      setAllConnections(connectionData.data);
    }
  }, [connectionData]);

  useEffect(() => {
    if (detailData) {
      setDetails(detailData.data[0]);
      dispatch(setUser({ ...userInfo, ...detailData.data[0] }));
    }
  }, [detailData]);

  const handleReply = async (comment_id, post_id, text, commentFile) => {
    let payload = {
      parent_comment: comment_id,
      post_id: post_id,
      comment_text: text,
    }

    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }

    const result = await commentPost(payload);
    if (result?.data?.status === 1) {
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
      getPost.refetch();
    }
  };

  const handleCommentLike = async (post_id, comment_id) => {
    const result = await likeComment({
      post_id: post_id,
      comment_id: comment_id,
    });
  };

  function imageOnSlide(number) {
    setImageController({
      toggler: !imageController.toggler,
      slide: number,
    });
  };

  // const handleDelete = async (id) => {
  //   console.warn('id',id)
  //   let result = await deletePost(id);
  //   if (result.data.status === 1) {
  //     getPost.refetch();
  //     toast(result.data.message, {
  //       position: "top-right",
  //       autoClose: 1500,
  //       hideProgressBar: false,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       theme: "colored",
  //       type: "success",
  //     });
  //   }
  // };

  const handleDisable = async (id, postDetail) => {
    Swal.fire({
      title: "Are you sure you want to disable this Post?",
      // text: "You will not be able to recover this Post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disable it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let studentId =
          userInfo?.user_type === "family" &&
            userInfo?.relation
              ?.map((userdetails) => userdetails._id)
              ?.includes(postDetail?.created_by?.ref)
            ? postDetail?.created_by?.ref
            : "";
            let isAdmin=userInfo?.user_type === "admin"
        let result = await deletePost({ id, studentId, isAdmin });
        if (result.data.status === 1) {
          getPost.refetch();
          getAllMedia();
          toast("Post disable successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
        } else {
          getAllMedia();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Post is safe!", "error");
      }
    });

  };

  const handleDelete = async (id, postDetail) => {
    Swal.fire({
      title: "Are you sure you want to delete this Post?",
      text: "You will not be able to recover this Post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let studentId =
          userInfo?.user_type === "family" &&
            userInfo?.relation
              ?.map((userdetails) => userdetails._id)
              ?.includes(postDetail?.created_by?.ref)
            ? postDetail?.created_by?.ref
            : "";
            let isAdmin=userInfo?.user_type === "admin"
        let result = await deletePost({ id, studentId, isAdmin });
        if (result.data.status === 1) {
          getPost.refetch();
          getAllMedia();
          toast("Post deleted successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
        } else {
          getAllMedia();
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Post is safe!", "error");
      }
    });

  };

  const deleteComment = async (comment_id, post_id) => {
    Swal.fire({
      title: "Are you sure you want to delete this Comment?",
      text: "You will not be able to recover this Comment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await delComment({
          comment_id: comment_id,
          post_id: post_id,
        });
        if (result.data.status === 1) {
          getPost.refetch();
          toast(result?.data?.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success",
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Comment is safe!", "error");
      }
    })

  };
  const editComment = async (comment_id, post_id, comment,commentFile) => {
    let payload = {
      post_id: post_id,
      comment_text: comment,
      parent_comment: "",
      comment_id:comment_id
    }

    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }

    const result = await updateComment(payload);
    if (result?.data?.status === 1) {
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
      getPost.refetch();
    }
  };
  var userType = "family"
  if (userInfo?.user_type === "family") {
    userType = "family/caregiver"
  } else if (userInfo?.user_type === "agency") {
    userType = "agency/community partner"
  } else {
    userType = userInfo?.user_type
  }

  const handleImage = (src) => {
    setMediaSrc(src)
    setMediaShow(true)
  }

  // const videoRef = useRef();

  // const handleMouseOver = ()=>{
  //   videoRef.current.play()
  //   videoRef.current.muted = true
  //   setState(true)
  // }

  // const handleMouseOut = ()=>{
  //   videoRef.current.pause()
  //   videoRef.current.muted = false
  //   setState(false)
  // }

  return (
    <>
      <FsLightbox
        toggler={imageController.toggler}
        sources={[
          g1,
          g2,
          g3,
          g4,
          g5,
          g6,
          g7,
          g8,
          g9,
          img51,
          img52,
          img53,
          img54,
          img55,
          img56,
          img57,
          img58,
          img59,
          img60,
          img61,
          img62,
          img63,
          img64,
          img65,
          img51,
          img52,
          img53,
          img54,
          img55,
          img56,
          img57,
          img58,
          img51,
          img52,
          img53,
          img54,
          img55,
          img56,
          img57,
          img58,
          img59,
          img60,
        ]}
        slide={imageController.slide}
      />
      <Container>
        <Row>
          <Col sm={12}>
            <Card>
              <Card.Body className=" profile-page p-0">
                <div className="profile-header">
                  <div className="position-relative">
                    <img
                      style={{ height: "auto", width: "100%" }}
                      loading="lazy"
                      src={details?.backgournd_picture || defaultBg}
                      alt="profile-bg"
                      className="rounded img-fluid"
                    />
                    <ul className="header-nav list-inline d-flex flex-wrap justify-end p-0 m-0">
                    </ul>
                  </div>
                  <div className="user-detail text-center mb-3">
                    <div className="profile-img">
                      <img style={{ width: "130px", height: "130px" }}
                        loading="lazy"
                        src={
                          details?.profile_picture ||
                          "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                        }
                        alt="profile-img1"
                        className="avatar-130 img-fluid"
                      />
                    </div>
                    <div className="profile-detail">
                      <h3>
                        {userInfo?.first_name + " " + userInfo?.last_name}
                      </h3>
                    </div>
                  </div>
                  <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                    <div className="social-info">
                      <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                        {/* <li className="text-center ps-3">
                          <h6>Posts</h6>
                          <p className="mb-0">{allPosts.length}</p>
                        </li> */}
                        <li className="text-center ps-0 ps-lg-3 connectText">
                          <Link to="/dashboards/app/connection-list"><h6>Connections</h6></Link>
                          <p className="mb-0 numbText">{details?.connection_count}</p>
                        </li>
                        <li className="text-center ps-3 ps-0 ps-lg-3 connectText">
                          <Link to="/dashboards/app/invitation-list"><h6>Invitations</h6></Link>
                          <p className="mb-0 numbText">{details?.invitation_count < 0 ? 0 : details?.invitation_count}</p>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Tab.Container id="left-tabs-example" defaultActiveKey="first">
            <Card className="p-0">
              <Card.Body className="p-0">
                <div className="user-tabing">
                  <Nav
                    as="ul"
                    variant="pills"
                    className="d-flex align-items-center justify-content-between profile-feed-items p-0 m-0"
                  >
                    <Nav.Item as="li" className=" col-4 col-sm-3 p-0 ">
                      <Nav.Link
                        href="#pills-timeline-tab"
                        eventKey="first"
                        role="button"
                        // style={{ backgroundColor: "#1F639C" }}
                        className=" text-center timeline-btn  p-3"
                      >
                        My Posts
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="col-4 col-sm-3 p-0">
                      <Nav.Link
                        href="#pills-about-tab"
                        eventKey="second"
                        role="button"
                        className="text-center p-3"
                      >
                        About
                      </Nav.Link>
                    </Nav.Item>
                    <Nav.Item as="li" className="col-4 col-sm-3 p-0">
                      <Nav.Link
                        href="#pills-media-tab"
                        eventKey="third"
                        role="button"
                        className="text-center p-3"
                      >
                        My Uploads
                      </Nav.Link>
                    </Nav.Item>
                  </Nav>
                </div>
              </Card.Body>
            </Card>
            <Col sm={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first">
                  <Card.Body className=" p-0">
                    <Row>
                      <Col lg={4}>
                        {allConnections.length > 0 && <Card>
                          <div className="card-header d-flex justify-content-between">
                            <div className="header-title">
                              <h4 className="card-title">
                                Connections ({details?.connection_count})
                              </h4>
                            </div>
                            {/* <div className="card-header-toolbar d-flex align-items-center">
                                             <p className="m-0"><Link to="javacsript:void();">Add New </Link></p>
                                          </div> */}
                          </div>
                          <Card.Body>
                            <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                              {allConnections?.map((e) => {
                                let user =
                                  e.requester.ref === userInfo?._id
                                    ? e.requested
                                    : e?.requester;
                                return (
                                  <li className="d-flex justify-content-center flex-column" style={{ textAlign: "center" }}>
                                    <Link to={`/dashboard/app/profile-view/${user?.ref}`}>
                                      <img
                                        loading="lazy"
                                        src={user.profile_picture || "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                                        alt="gallary"
                                        className="img-fluid avatar-50 rounded-circle"
                                      />
                                    </Link>
                                    <h6 className="mt-2 text-center nameText" style={{ height: "50%" }}>
                                      <Link to={`/dashboard/app/profile-view/${user?.ref}`}>

                                        {user.username}{" "}
                                      </Link>
                                    </h6>
                                  </li>
                                )
                              })}


                            </ul>
                          </Card.Body>
                        </Card>}
                        {allRelations.length > 0 && <Card>
                          <div className="card-header d-flex justify-content-between">
                            <div className="header-title">
                              <h4 className="card-title">
                                {userInfo?.user_type === "student" ? `Parents` : `Students (${allRelations.length})`}
                              </h4>
                            </div>
                            {/* <div className="card-header-toolbar d-flex align-items-center">
                                             <p className="m-0"><Link to="javacsript:void();">Add New </Link></p>
                                          </div> */}
                          </div>
                          <Card.Body>
                            <ul className="profile-img-gallary p-0 m-0 list-unstyled">
                              {allRelations?.map((user) => {
                                return (
                                  <li className="d-flex justify-content-center flex-column" style={{ textAlign: "center" }}>
                                    {/* <div> */}
                                    <Link to={`/dashboard/app/profile-view/${user?._id}`}>
                                      <img
                                        loading="lazy"
                                        src={user?.profile_picture || "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                                        alt="gallary"
                                        className="img-fluid avatar-50 rounded-circle"
                                      />
                                    </Link>
                                    <h6 className="mt-2 text-center" style={{ height: "50%" }}>
                                      <Link to={`/dashboard/app/profile-view/${user?._id}`}>

                                        {user?.first_name}{" "}{user?.last_name}
                                      </Link>
                                    </h6>
                                    {/* </div>
                                    <div>
                                    <Link Link to={`/dashboard/app/profile-view/${user?._id}`}>View Profile</Link>
                                    <Link Link to={`/dashboard/app/activity-view/${user?._id}`}>View Activity</Link>
                                    </div> */}
                                  </li>
                                )
                              })}


                            </ul>
                          </Card.Body>
                        </Card>}
                      </Col>
                      <Col lg={8}>
                        <Card id="post-modal-data">
                          {/* <div className="card-header d-flex justify-content-between">
                            <div className="header-title">
                              <h4 className="card-title">Create Post</h4>
                            </div>
                          </div> */}
                          <Card.Body>
                            <div className="d-flex align-items-center">
                              <div className="user-img">
                                <img
                                  loading="lazy"
                                  src={
                                    details?.profile_picture ||
                                    "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                                  }
                                  alt="userimg"
                                  className="avatar-50 rounded-circle"
                                />
                              </div>
                              <form
                                className="post-text ms-3 w-100 "
                                onClick={handleShow}
                              >
                                <input
                                  type="text"
                                  className="form-control rounded"
                                  placeholder="Write something here..."
                                  style={{ border: "none" }}
                                />
                              </form>
                            </div>
                            <hr />
                          </Card.Body>
                          <Modal show={show} onHide={handleClose} size="lg">
                            <Modal.Header className="d-flex justify-content-end">
                              {/* <h5 className="modal-title" id="post-modalLabel">
                                Create Post
                              </h5> */}
                              <Link to="#" className="lh-1" onClick={handleClose}>
                                <span className="material-symbols-outlined">close</span>
                              </Link>
                            </Modal.Header>
                            <Modal.Body>
                              <div className="d-flex align-items-center">
                                <div className="user-img" style={{ height: "60px", width: "60px" }}>
                                  <img
                                    loading="lazy"
                                    src={
                                      details?.profile_picture ||
                                      "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                                    }
                                    alt="userimg"
                                    className="avatar-50 rounded-circle "
                                  />
                                </div>
                                <form
                                  className="post-text ms-3 w-100"
                                  action=""
                                >
                                  <TextEditor
                                    textEditorData={textEditorData}
                                    onChange={setTextEditorData}
                                    urlDetails={urlDetails}
                                    fetchUrlDetails={fetchUrlDetails}
                                  />
                                </form>
                              </div>
                              <hr />
                              {loading ? <Loading /> : <>
                                <ul className="d-flex flex-column align-items-center list-inline m-0 p-0">
                                  <li className="col-md-6 mb-3">
                                    <div className="bg-soft-primary rounded p-2 pointer" style={{ textAlign: "center" }}>
                                      <label className="shareOption">
                                        <span style={{ cursor: "pointer", fontWeight: "600", color: "#000" }}>Choose Photo, Video or Document</span>

                                        <input
                                          style={{ display: "none" }}
                                          type="file"
                                          id="file"
                                          //  accept=".png,.jpeg,.jpg"
                                          onChange={(e) => {
                                            uploadMediaHandle(e.target.files[0]);
                                          }}
                                        />
                                      </label>
                                    </div>
                                  </li>
                                  <RenderFile file={file} filetype={"filetype"} />
                                </ul>
                                <hr />
                                <Button
                                  variant="primary"
                                  className="d-block w-100 mt-3"
                                  onClick={handleSubmit}
                                >
                                  Post
                                </Button>
                              </>}
                            </Modal.Body>
                          </Modal>
                        </Card>
                        <Card>
                          {allPosts?.map((val) => (
                            
                            <FeedBlock
                              postDetails={val}
                              handleLike={handleLike}
                              handleReply={handleReply}
                              handleCommentLike={handleCommentLike}
                              handleComment={handleComment}
                              getPost={getPost}
                              handleDelete={handleDelete}
                              deleteComment={deleteComment}
                              editComment={editComment}
                              getAllMedia={getAllMedia}
                              handleDisable={handleDisable}
                            />
                          ))}
                        </Card>
                      </Col>
                    </Row>
                  </Card.Body>
                </Tab.Pane>
                <Tab.Pane eventKey="second">
                  <Tab.Container
                    id="left-tabs-example"
                    defaultActiveKey="about1"
                  >
                    <Row className="d-flex justify-content-between personalInfo">
                      <Col md={12} className="px-0">
                        <Card>
                          <Card.Body>
                            <Tab.Content>
                              <Tab.Pane eventKey="about1">
                                <h4>Personal Info</h4>
                                <hr />
                                <Row className="">
                                  <div className="col-12 col-lg-6 mb-2 ">
                                    <h6 style={{  fontWeight: "bold" }} className="">First Name : <span className="mb-0 profileData">{userInfo?.first_name}</span> </h6>
                                  </div>
                                  
                                  <div className="col-12 col-lg-6 mb-2 ">
                                    <h6 style={{  fontWeight: "bold" }}>Last Name : <span className="mb-0 profileData">{userInfo?.last_name}</span> </h6>
                                  </div>
                                  
                                </Row>
                                {details?.first_name && (
                                  <Row className="">
                                    <div className="col-12 col-lg-6 mb-2 ">
                                      <h6 style={{  fontWeight: "bold" }}>Username : <span className="mb-0 profileData">{userInfo?.email}</span></h6>
                                    </div>
                                    
                                   <div className="col-12 col-lg-6 mb-2 ">
                                      <h6 style={{ fontWeight: "bold" }}>Email : <span className="mb-0 profileData">{userInfo?.email}</span></h6>
                                    </div>
                                    
                                  </Row>
                                )}
                                {userInfo?.user_type && (
                                  <Row className="">
                                    <div className="col-12 col-lg-6 mb-2 ">
                                      <h6 style={{ fontWeight: "bold" }}>Location/Region : <span className="mb-0 profileData">{userInfo?.location}</span></h6>
                                    </div>
                                    
                                   <div className="col-12 col-lg-6 mb-2 ">
                                      <h6 style={{  fontWeight: "bold" }}>User Role : <span className="mb-0 profileData">{userType}</span></h6>
                                    </div>
                                    
                                  </Row>
                                )}
                                {userInfo?.user_type === "educator" || userInfo?.user_type === "agency" ? <Row className="mb-2">
                                 <div className="col-12 col-lg-6 mb-2 ">
                                    <h6 style={{ fontWeight: "bold" }}>Work : <span className="mb-0 profileData">{userInfo?.work}</span></h6>
                                  </div>
                                  
                                </Row> : ""}

                              </Tab.Pane>
                              <Tab.Pane eventKey="about2">
                                <h4 className="mt-2">Hobbies and Interests</h4>
                                <hr />
                                <h6 className="mb-1">Hobbies:</h6>
                                <p>
                                  Hi, I’m Bni, I’m 26 and I work as a Web
                                  Designer for the iqonicdesign.I like to ride
                                  the bike to work, swimming, and working out. I
                                  also like reading design magazines, go to
                                  museums, and binge watching a good tv show
                                  while it’s raining outside.
                                </p>
                                <h6 className="mt-2 mb-1">
                                  Favourite TV Shows:
                                </h6>
                                <p>
                                  Breaking Good, RedDevil, People of Interest,
                                  The Running Dead, Found, American Guy.
                                </p>
                                <h6 className="mt-2 mb-1">Favourite Movies:</h6>
                                <p>
                                  Idiocratic, The Scarred Wizard and the Fire
                                  Crown, Crime Squad, Ferrum Man.
                                </p>
                                <h6 className="mt-2 mb-1">Favourite Games:</h6>
                                <p>
                                  The First of Us, Assassin’s Squad, Dark
                                  Assylum, NMAK16, Last Cause 4, Grand Snatch
                                  Auto.
                                </p>
                                <h6 className="mt-2 mb-1">
                                  Favourite Music Bands / Artists:
                                </h6>
                                <p>
                                  Iron Maid, DC/AC, Megablow, The Ill, Kung
                                  Fighters, System of a Revenge.
                                </p>
                                <h6 className="mt-2 mb-1">Favourite Books:</h6>
                                <p>
                                  The Crime of the Century, Egiptian Mythology
                                  101, The Scarred Wizard, Lord of the Wings,
                                  Amongst Gods, The Oracle, A Tale of Air and
                                  Water.
                                </p>
                                <h6 className="mt-2 mb-1">
                                  Favourite Writers:
                                </h6>
                                <p>
                                  Martin T. Georgeston, Jhonathan R. Token,
                                  Ivana Rowle, Alexandria Platt, Marcus Roth.
                                </p>
                              </Tab.Pane>
                              <Tab.Pane eventKey="about3">
                                <h4 className="mb-3">Relationship</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <span className="material-symbols-outlined md-18">
                                        add
                                      </span>
                                    </div>
                                    <div className="media-support-info ms-3">
                                      <h6>Add Your Relationship Status</h6>
                                    </div>
                                  </li>
                                </ul>
                                <h4 className="mt-3 mb-3">Family Members</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <span className="material-symbols-outlined md-18">
                                        add
                                      </span>
                                    </div>
                                    <div className="media-support-info ms-3">
                                      <h6>Add Family Members</h6>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user01}
                                        alt="story1"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between">
                                        <div className="ms-3">
                                          <h6>Paul Molive</h6>
                                          <p className="mb-0">Brothe</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex justify-content-between mb-4  align-items-center">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user02}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className=" ms-3">
                                          <h6>Anna Mull</h6>
                                          <p className="mb-0">Sister</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user03}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between">
                                        <div className="ms-3">
                                          <h6>Paige Turner</h6>
                                          <p className="mb-0">Cousin</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link to="#">
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Tab.Pane>
                              <Tab.Pane eventKey="about4">
                                <h4 className="mb-3">Work</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex justify-content-between mb-4  align-items-center">
                                    <div className="user-img img-fluid">
                                      <span className="material-symbols-outlined md-18">
                                        add
                                      </span>
                                    </div>
                                    <div className="ms-3">
                                      <h6>Add Work Place</h6>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user01}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex justify-content-between">
                                        <div className="ms-3">
                                          <h6>Themeforest</h6>
                                          <p className="mb-0">Web Designer</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link
                                            to="#"
                                            className="d-flex align-items-center"
                                          >
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user02}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className="ms-3">
                                          <h6>iqonicdesign</h6>
                                          <p className="mb-0">Web Developer</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link
                                            to="#"
                                            className="d-flex align-items-center"
                                          >
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user03}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className="ms-3">
                                          <h6>W3school</h6>
                                          <p className="mb-0">Designer</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link
                                            to="#"
                                            className="d-flex align-items-center"
                                          >
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <h4 className="mb-3">Professional Skills</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <span className="material-symbols-outlined md-18">
                                        add
                                      </span>
                                    </div>
                                    <div className="ms-3">
                                      <h6>Add Professional Skills</h6>
                                    </div>
                                  </li>
                                </ul>
                                <h4 className="mt-3 mb-3">College</h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <span className="material-symbols-outlined md-18">
                                        add
                                      </span>
                                    </div>
                                    <div className="ms-3">
                                      <h6>Add College</h6>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user01}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className="ms-3">
                                          <h6>Lorem ipsum</h6>
                                          <p className="mb-0">USA</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link
                                            to="#"
                                            className="d-flex align-items-center"
                                          >
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                              </Tab.Pane>
                              <Tab.Pane eventKey="about5">
                                <h4 className="mb-3">
                                  Current City and Hometown
                                </h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user01}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className="ms-3">
                                          <h6>Georgia</h6>
                                          <p className="mb-0">Georgia State</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link
                                            to="#"
                                            className="d-flex align-items-center"
                                          >
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                  <li className="d-flex mb-4 align-items-center justify-content-between">
                                    <div className="user-img img-fluid">
                                      <img
                                        loading="lazy"
                                        src={user02}
                                        alt="story-img"
                                        className="rounded-circle avatar-40"
                                      />
                                    </div>
                                    <div className="w-100">
                                      <div className="d-flex flex-wrap justify-content-between">
                                        <div className="ms-3">
                                          <h6>Atlanta</h6>
                                          <p className="mb-0">Atlanta City</p>
                                        </div>
                                        <div className="edit-relation">
                                          <Link
                                            to="#"
                                            className="d-flex align-items-center"
                                          >
                                            <span className="material-symbols-outlined me-2 md-18">
                                              edit
                                            </span>
                                            Edit
                                          </Link>
                                        </div>
                                      </div>
                                    </div>
                                  </li>
                                </ul>
                                <h4 className="mt-3 mb-3">
                                  Other Places Lived
                                </h4>
                                <ul className="suggestions-lists m-0 p-0">
                                  <li className="d-flex mb-4 align-items-center">
                                    <div className="user-img img-fluid">
                                      <span className="material-symbols-outlined md-18">
                                        add
                                      </span>
                                    </div>
                                    <div className="ms-3">
                                      <h6>Add Place</h6>
                                    </div>
                                  </li>
                                </ul>
                              </Tab.Pane>
                            </Tab.Content>
                          </Card.Body>
                        </Card>
                      </Col>
                    </Row>
                  </Tab.Container>
                </Tab.Pane>
                <Tab.Pane eventKey="third">
                  <Card>
                    <Card.Body className=" p-0">
                      <Tabs defaultActiveKey="image" id="noanim-tab-example" className="d-flex justify-content-center">
                        <Tab eventKey="image" title="Image" className="">
                          <Container>
                            <Row>
                              {emptyMedia ? <p className="text-center">Not any image yet.</p> : <>
                                {allMedia?.data?.filter((item) => {
                                  if (item?.file_type === "image") {
                                    return item;
                                  }
                                })?.map((item) => {
                                  return (
                                    <>
                                      <Col lg={3} onClick={() => handleImage(item?.file_url)} style={{ cursor: "pointer" }}>
                                        <Image data={item?.file_url} />
                                      </Col>
                                    </>
                                  )
                                })}
                              </>}
                            </Row>
                          </Container>
                        </Tab>
                        <Tab eventKey="videos" title="Videos">
                          <Container>
                            <Row>
                              {emptyMedia ? <p className="text-center">Not any video yet.</p> : <>
                                {allMedia?.data?.filter((item) => {
                                  if (item?.file_type === "video") {
                                    return item;
                                  }
                                })?.map((item) => {
                                  return (
                                    <Col lg={4}>
                                      <Video data={item?.file_url} />
                                    </Col>
                                  )
                                })}
                              </>}
                            </Row>
                          </Container>
                        </Tab>
                        <Tab eventKey="documents" title="Documents" >
                          <Container>
                            <Row>
                              {emptyMedia ? <p className="text-center">Not any documents yet.</p> : <>
                                {allMedia?.data?.filter((item) => {
                                  if (item?.file_type === "application") {
                                    return item;
                                  }
                                })?.map((item) => {
                                  return (
                                    <Col lg={2} >
                                      <Document
                                        item={item}
                                        pdfIcon={pdfIcon}
                                        docIcon={docIcon}
                                      />
                                    </Col>
                                  )
                                })}
                              </>}
                            </Row>
                          </Container>
                        </Tab>
                      </Tabs>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
              <Modal show={mediaShow} onHide={mediaShow} size="xl">
                <Modal.Header className="d-flex justify-content-end">
                  <Link to="#" className="lh-1" onClick={() => setMediaShow(false)}>
                    <span className="material-symbols-outlined">close</span>
                  </Link>
                </Modal.Header>
                <Modal.Body>
                  <div>
                    <img src={mediaSrc} style={{ width: "100%" }} />
                  </div>
                  <hr />
                  <button
                    type="submit"
                    className="btn btn-primary d-block w-100 mt-3"
                    onClick={() => setMediaShow(false)}
                  >
                    Close
                  </button>
                </Modal.Body>
              </Modal>
            </Col>
          </Tab.Container>
          <ToastContainer />
        </Row>
      </Container>
    </>
  );
};

export default UserProfile;
