
import { apiSlice } from "../apiSlice";

export const userListByAdminApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getUserListByAdmin: builder.query({
      query: (searchTerm) => ({
        url: `users/get-all-users`,
        method: "GET",
      }),
    }),
    UpdateuserListByAdmin: builder.mutation({
      query: (body) => ({
        url: `users/update-user-details-by-admin`,
        method: "PATCH",
        body,
        msg: true
      }),
    }),
  })
})



export const {
  useGetUserListByAdminQuery,
  useUpdateuserListByAdminMutation
} = userListByAdminApiSlice