import { createSlice,createAsyncThunk } from "@reduxjs/toolkit";
const initialState = {};
export const connectionSlice = createSlice({
  name: "connection",
  initialState,
  reducers: {
    setConnections: (state,action) =>{
      state.connection = action.payload
    }
  },
});

export default connectionSlice.reducer;
