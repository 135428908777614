import React, { useState } from "react";
import {
  Row,
  Col,
  Container,
  Form,
  Button,
  Image,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import { setUser } from "../../store/user/action";
import { useSelector, useDispatch } from "react-redux";
import "./index.scss";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

//img
import logo from "../../assets/images/main_logo.png";
import login1 from "../../assets/images/slider1.jpg";
import login2 from "../../assets/images/slider2.jpg";
import login3 from "../../assets/images/slider3.jpg";
import { validation } from "./FormValidation";
import { useRecoveryPasswordMutation } from "../../store/user/userApiSlice";

// install Swiper modules

SwiperCore.use([Navigation, Autoplay]);

const Recoverypw = () => {
  const [formErrors, setFormErrors] = useState({});
  const [visiblePassword, setVisiblePassword] = useState(false);
  const [visibleConfirmPassword, setVisibleConfirmPassword] = useState(false);

  const [userInput, setUserInput] = useState({
    email: "",
    new_password: "",
    confirm_password: "",
    otp: "",
  });
  const [isValid, setIsValid] = useState({
    call: false,
    success: false,
    msg: "bbbb",
  });
  const user = useSelector((state) => state.user);
  let history = useHistory();
  const dispatch = useDispatch();
  const [recoveryPassword] = useRecoveryPasswordMutation();

  const handlUserInput = (e) => {
    const { name, value } = e.target;
    setUserInput({ ...userInput, [name]: value });
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setFormErrors(validation(userInput));
    //console.log("submit");

    dispatch(setUser("data"));
    if (Object.keys(validation(userInput)).length === 0) {
      const res = await recoveryPassword(userInput);

      //console.log("res", res);
      const data = res?.data;
      if (res?.data?.status === 1) {
        setIsValid({
          ...isValid,
          call: true,
          success: true,
          msg: data?.message,
        });
        history.push("/auth/sign-in");
        validation("clear error");
      } else {
        if (res?.error?.status === 500) {
          setIsValid({
            ...isValid,
            call: true,
            success: false,
            msg: res?.error?.data.message.data || res?.error?.data.message,
          });
        } else {
          res.json().then((data) => {
            setIsValid({
              ...isValid,
              call: true,
              success: false,
              msg: res?.data?.message,
            });
            // console.log(error);;
          });
        }
      }

      validation("clear error");
    }
  };
  //console.log("confirmMail", isValid);
  const handleVisible = (value) => {
    if (value === "password") {
      setVisiblePassword(!visiblePassword);
    }
    if (value === "confirm_password") {
      setVisibleConfirmPassword(!visibleConfirmPassword);
    }
  };

  const style ={
    '@media only screen and (max-width: 600px)':{
      background:"black"
    }
    // position:"fixed"
  }

  return (
    <>
      <section className="sign-in-page">
        <div>
          <Row className="no-gutters align-items-center" style={{ width: "100%", height:"100vh", marginLeft: "0.1px" }}>
             <Col md="8" className="p-5 d-none d-lg-block">
                <h2 className="text-white fontBold">
                  PA Secondary Transition - Public Square
                </h2>
                <h3 className="text-white mt-2 mb-3">
                 Login and create an account to personalize your experience
                </h3>
                <h4 className="text-white mt-2 mb-3">
                  Connect, Collect, Contribute
                </h4>
                <ul className="text-white listItems">
                
                <li>Connect with people & events within your community and across the state.</li>
                <li>Collect and save your favorite transition resources & assessments.</li>
                <li>Contribute by sharing resources, articles and stories.</li>
                </ul>
            </Col>
            <Col md="4" className="bg-white p-5" style={{  height:"100vh", display:"flex", flexDirection:"column",justifyContent: "center"}}>
              
                {isValid.call &&
                  (isValid.success ? (
                    <Alert variant="success">{isValid.msg}</Alert>
                  ) : (
                    <Alert variant="danger">{isValid.msg}</Alert>
                  ))}
                <div style={{ textAlign: "center" }}>
                <Image src={logo} className="img-fluid mb-3" alt="logo" />
                </div>
                <h3 className="mb-0 text text-center" style={{ fontWeight: "600" }}>Recover password</h3>
                <p className="text-center formsSubHeadings text-dark">Enter your email and verification number to set new password.</p>
                <Form className="mt-4" noValidate onSubmit={handleSubmit}>
                  <Form.Group className="form-group">
                    <Form.Label className="textBlue">Email</Form.Label>
                    <Form.Control
                      type="email"
                      className="mb-0"
                      id="email"
                      name="email"
                      placeholder="Enter Email"
                      onChange={handlUserInput}
                      required
                    />
                    <p className="errortext">{formErrors.email}</p>
                  </Form.Group>
                  <Form.Group className="form-group">
                    <Form.Label className="textBlue">Verification Number</Form.Label>
                    <Form.Control
                      type="number"
                      className="mb-0"
                      id="otp"
                      name="otp"
                      placeholder="4 Digit verification number received on your email"
                      onChange={handlUserInput}
                      required
                    />
                    <p className="errortext">{formErrors.otp}</p>
                  </Form.Group>
                  <Form.Group className="form-group" style={{ position: "relative" }}>
                    <Form.Label className="textBlue">New Password</Form.Label>
                    <Form.Control
                      type={visiblePassword ? "text" : "password"}
                      className="mb-0"
                      id="password"
                      name="new_password"
                      placeholder="New Password"
                      onChange={handlUserInput}
                      required
                    />
                    <div
                      style={{ position: "absolute", right: "4%", bottom: "8%", cursor: "pointer" }}
                    >
                      {(visiblePassword === false) ? <i onClick={() => { handleVisible("password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("password") }} class="ri-eye-off-line"></i>}
                    </div>
                  </Form.Group>
                  <p className="errortext">{formErrors.new_password}</p>
                  <Form.Group className="form-group" style={{ position: "relative" }}>
                    <Form.Label className="textBlue">Confirm Password</Form.Label>
                    <Form.Control
                      type={visibleConfirmPassword ? "text" : "password"}
                      className="mb-0"
                      id="confirm-password"
                      name="confirm_password"
                      placeholder="Re-enter New Password"
                      onChange={handlUserInput}
                      required
                    />
                    <div
                      style={{ position: "absolute", right: "4%", bottom: "8%", cursor: "pointer" }}
                    >
                      {(visibleConfirmPassword === false) ? <i onClick={() => { handleVisible("confirm_password") }} class="ri-eye-line"></i> : <i onClick={() => { handleVisible("confirm_password") }} class="ri-eye-off-line"></i>}
                    </div>
                  </Form.Group>
                  <p className="errortext">{formErrors.confirm_password}</p>
                  <div className="d-inline-block w-100">
                    <Button variant="primary"
                      type="submit"
                      className="float-end">
                      Submit
                    </Button>
                  </div>
                </Form>
              
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Recoverypw;
