import React, { useState, useEffect } from "react";

import { Editor } from "react-draft-wysiwyg";
import {
  EditorState,
  convertFromRaw,
  convertToRaw,
  ContentState,
  convertFromHTML,
} from "draft-js";
import { stateToHTML } from "draft-js-export-html";
import RenderMetaURL from "./RenderMetaURL"
import "react-draft-wysiwyg/dist/react-draft-wysiwyg.css";

function TextEditor({ textEditorData, onChange, urlDetails, fetchUrlDetails }) {
  const [editorState, setEditorState] = useState(() => {
    EditorState.createEmpty();
  });
  useEffect(() => {
    if (textEditorData) {
      setEditorState(
        EditorState.createWithContent(
          ContentState.createFromBlockArray(
            convertFromHTML(stateToHTML(convertFromRaw(textEditorData)))
          )
        )
      );
    } else {
      setEditorState(EditorState.createEmpty());
    }
  }, []);

  function ValidURL(str) {
    var regex =
      /(?:https?):\/\/(\w+:?\w*)?(\S+)(:\d+)?(\/|\/([\w#!:.?+=&%!\-\/]))?/;
    if (!regex.test(str)) {
      return false;
    } else {
      return true;
    }
  }



  const updateTextDescription = async (state) => {
    await setEditorState(state);
    const da = convertToRaw(state.getCurrentContent());

    onChange(da);

    if (da?.entityMap[0]?.type === "LINK") {
      fetchUrlDetails(da?.entityMap[0]?.data.url);
      return;
    }
    let splitter = da?.blocks[0]?.text.split(" ");
    splitter.forEach((str, index) => {
      let check = ValidURL(str);
      if (check) {
        fetchUrlDetails(str);
      }
    });
  };

  return (
    <div>
      <Editor
        editorState={editorState}
        wrapperClassName="demo-wrapper"
        editorClassName="demo-editor"
        onEditorStateChange={updateTextDescription}
        toolbar={{
          options: [
            "inline",

          ],
          inline: {
            inDropdown: false,
            className: undefined,
            component: undefined,
            dropdownClassName: undefined,
            options: [
              "bold",
              "italic",
              "underline",
              "strikethrough",

            ],

          },

        }}

        hashtag={{}}
      />
      {Object.keys(urlDetails).length > 0 && <RenderMetaURL title={urlDetails?.meta?.title} description={urlDetails?.meta?.description ? urlDetails?.meta?.description : urlDetails?.og?.description} image={urlDetails?.og?.image} url={urlDetails?.meta?.url} />
      }
    </div>
  );
}


export default TextEditor;
