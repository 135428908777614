import { apiSlice } from "../apiSlice";

export const groupApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({

    getGroups: builder.query({
      query: (counter) => ({
        url: `groups/get-group-details/0/${counter}`,
        method: 'GET',
      }),
      providesTags: (result, error, args) => {
        return [
          ...result?.data?.map(({ _id }) => ({
            type: "Group",
            id: _id,
          })),
          { type: "Group", id: "list" },
        ]
      }
    }),
    createGroup: builder.mutation({
      query: (body) => ({
        url: 'groups/create-group',
        method: 'POST',
        body,
        msg: true
      }),
      invalidatesTags: (result, error, args) => {
        return [{
          type: "Group",
          id: "list",
        }]
      }
    }),
    getGroupsByUserId: builder.query({
      query: (data) => ({
        url: `groups/get-group-details-by-userid/${data}`,
        method: 'GET',
      })
    }),
    addMember: builder.mutation({
      query: (data) => ({
        url: `groups/add-group-member`,
        method: "PATCH",
        body: { ...data },
        msg:true
      }),
    }),
    removeMember: builder.mutation({
      query: (data) => ({
        url: `groups/remove-group-member`,
        method: "DELETE",
        body: { ...data },
        msg:true
      }),
    }),
    editGroupDetail: builder.mutation({
      query: (data) => ({
        url: `groups/update-group-details`,
        method: "PATCH",
        body: { ...data },
        msg: true
      }),
      invalidatesTags: (result, error, args) => {
        return [{
          type: "Group",
          id: args?._id,
        }]
      }
    }),
    deleteGroup: builder.mutation({
      query: (data) => ({
        url: `groups/remove-group`,
        method: "DELETE",
        body: { ...data }
      }),
      invalidatesTags: (result, error, args) => {
        return [{
          type: "Group",
          id: args?._id,
        }]
      }
    }),
    disableGroup: builder.mutation({
      query: (data) => ({
        url: `groups/enable-disable-group-by-admin/${data?.group_id}/${data?.status}`,
        method: "PUT",
        // body: { ...data }
      }),
      invalidatesTags: (result, error, args) => {
        return [{
          type: "Group",
          id: args?._id,
        }]
      }
    }),
  }),
});

export const {
  useGetGroupsQuery,
  useAddMemberMutation,
  useRemoveMemberMutation,
  useEditGroupDetailMutation,
  useDeleteGroupMutation,
  useGetGroupsByUserIdQuery,
  useCreateGroupMutation,
  useDisableGroupMutation
} = groupApiSlice;
