import React, { useEffect, useState } from "react";
import {
  Container,
} from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import { groupDetail as groupDetailActions } from "../../store/groups/groupDetailSlice";
import { useDeleteGroupMutation } from "../../store/group/groupApiSlice";
import { useDispatch, useSelector } from "react-redux";
import {
  FacebookShareButton,
  useCreateGroupPostMutation,
  useGetPostsQuery,
  useFeedUploadMutation,
  useGetUrlMetaMutation,
  useGetPostsForGroupQuery,
  useLikeCommentMutation,
  useLikePostMutation,
  useCreateCommentMutation,
  useGetPostsForIdQuery,
  useDeletePostMutation,
  useDeleteCommentMutation,
  useCommentUploadMutation,
  useEditCommentMutation
} from "../../store/post/postApiSlice";
import FeedBlock from "../../components/FeedBlock";
import fetchApi from "../../store/apiUtil";
import Loading from "./Loading";
import config from "../../store/config.json"
import { ToastContainer, toast } from 'react-toastify';

//image

const PostDetail = () => {
  const [postDetail, setPostDetail] = useState({});
  const [show, setShow] = useState(false);
  const [loading, setLoading] = useState(true);
  const handleClose = () => {
    setTextEditorData(null);
    setUrlDetails({});
    setFile(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const { groupDetail } = useSelector((state) => state.groupDetail);
  const dispatch = useDispatch();
  const { id } = useParams();
  const [createGroupPost] = useCreateGroupPostMutation();
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;
  const [deleteGroup] = useDeleteGroupMutation();
  const [feedUpload] = useFeedUploadMutation();
  const [commentUpload] = useCommentUploadMutation()
  const [updateComment] = useEditCommentMutation()
  const getPost = useGetPostsForIdQuery(id);

  // const getPost = useGetPostsForGroupQuery(id);
  // const getIdPost = useGetPostsForGroupQuery(id);
  const [file, setFile] = useState(null);
  const [textEditorData, setTextEditorData] = useState(null);
  const [urlDetails, setUrlDetails] = useState({});

  const [likePost] = useLikePostMutation();
  const [likeComment] = useLikeCommentMutation();
  const [commentPost] = useCreateCommentMutation();
  const [deletePost] = useDeletePostMutation();
  const [delComment] = useDeleteCommentMutation();

  const [getUrlMeta] = useGetUrlMetaMutation();
  const detailGroup = groupDetail[0];
  // console.log(getIdPost,"getIdPost")

  const getPostInfo = async () => {
    let res = await fetchApi("posts/get-post-details", "GET", id);
    if (res && res?.status === 1 && res?.data?.length > 0) {
      const comments = await fetchApi("comments/get-all-comments", "GET", id);
      if (comments && comments?.status === 1) {
        let updatedComments = comments?.data.map((value) => {
          let username = value?.commented_by?.username
          let profile_picture = value?.commented_by?.profile_picture
          let id = value?._id
          return {
            ...value,
            profile_picture: profile_picture,
            created_by: username,
            is_liked: value?.is_liked === 'true' ? true : false,
            user_id: value?.commented_by?.ref,
            ref: id,
          };
        });
        let response = { ...res?.data[0], comments: updatedComments };
        setPostDetail(response);
        setLoading(false)
      }
    }
    // console.log("fetchApi",res)
  };

  let getPostClone = {
    refetch: () => {
      getPostInfo()
    }
  }

  const fetchUrlDetails = async (url) => {
    const result = await getUrlMeta({
      url: url,
    });
    if (result?.data?.status === 1 && result?.data?.data) {
      setUrlDetails(result?.data?.data);
    }
  };

  const getGroupDetail = () => {
    fetch(
      `https://portal-api.pasecondarytransition.com/api/posts/get-post-details/${id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            dispatch(groupDetailActions(data?.data));
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  // getGroupDetail()

  useEffect(() => {
    getPostInfo();
  }, []);

  // console.log(getIdPost?.data?.data,"getIdPostchcekw")
  // useEffect(() => {
  //   if (
  //     getPost?.data?.status === 1 &&
  //     Array.isArray(getPost?.data?.data) &&
  //     getPost?.data?.data.length > 0
  //   ) {
  //     setPostDetail(getPost?.data?.data[0]);
  //   }
  // }, [getPost?.data]);

  const history = useHistory();
  const goBack = () => {
    history.goBack();
  };

  const uploadMediaHandle = (fileData) => {
    setFile(fileData);
  };

  const handleLike = async (id) => {
    const result = await likePost({
      post_id: id,
    });
  };

  const handleCommentLike = async (post_id, comment_id) => {
    const result = await likeComment({
      post_id: post_id,
      comment_id: comment_id,
    });
  };

  const editComment = async (comment_id, post_id, comment,commentFile) => {
    let payload = {
      post_id: post_id,
      comment_text: comment,
      parent_comment: "",
      comment_id:comment_id
    }

    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }


    const result = await updateComment(payload);
    if (result?.data?.status === 1) {
      getPostInfo()
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
    }
  };

  const handleComment = async (id, comment, commentFile) => {
    let payload = {
      post_id: id,
      comment_text: comment,
      parent_comment: "",
    }
    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }


    const result = await commentPost(payload);
    if (result?.data?.status === 1) {
      getPostInfo()
    }
  };

  const handleReply = async (comment_id, post_id, text,commentFile) => {
    let payload = {
      parent_comment: comment_id,
      post_id: post_id,
      comment_text: text,
    }

    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }

    const result = await commentPost(payload);
    if (result?.data?.status === 1) {
      getPostInfo()
    }
  };

  const handleSubmit = async () => {
    let multerData = new FormData();
    multerData.append("file", file);

    let payload;
    let multerPayload = {
      multerData: multerData,
      type: "main",
    };
    let url_meta_data = {};
    if (urlDetails) {
      url_meta_data = { ...urlDetails };
    }

    if (file) {
      const feedRes = await feedUpload(multerPayload);
      payload = {
        text_editor_data: textEditorData,
        file_id: feedRes?.data?.data?.data?._id,
        file_url: feedRes?.data?.data?.data?.file_url,
        group_id: detailGroup?._id,
      };
      if (feedRes?.data?.status === 1) {
      } else {
        console.log("something error in group post");
        return;
      }
    } else {
      payload = {
        text_editor_data: textEditorData,
        group_id: detailGroup?._id,
      };
    }
    const result = await createGroupPost({
      ...payload,
      url_meta_data: url_meta_data,
    });
    if (result?.data?.status === 1) {
      getPostInfo()
      setFile(null);
      handleClose();
    }
  };

  const handleDelete = async (id) => {
    let studentId =
      userInfo?.user_type === "family" &&
        userInfo?.relation
          ?.map((userdetails) => userdetails._id)
          ?.includes(postDetail?.created_by?.ref)
        ? postDetail?.created_by?.ref
        : "";
    let isAdmin = userInfo?.user_type === "admin"
    let result = await deletePost({ id, studentId, isAdmin });
    if (result.data.status === 1) {
      toast("Post deleted successfully", {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success"
      });
      setPostDetail(null);
      history.push("/")
    }
  };

  const deleteComment = async (comment_id, post_id) => {
    let result = await delComment({
      comment_id: comment_id,
      post_id: post_id
    });
    if (result.data.status === 1) {
      getPostInfo()
    }
  };

  return (
    <>
      {loading && <Loading />}
      {!loading && <Container>
        {postDetail && (
          <FeedBlock
            postDetails={postDetail}
            handleLike={handleLike}
            handleComment={handleComment}
            handleCommentLike={handleCommentLike}
            handleReply={handleReply}
            handleDelete={handleDelete}
            deleteComment={deleteComment}
            getPost={getPostClone}
            isViewFullPost={true}
            editComment={editComment}
          />
        )}
      </Container>}
    </>
  );
};

export default PostDetail;