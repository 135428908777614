import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    userList: []
}

export const userListSlice = createSlice({
    name: 'userListByAdmin',
    initialState,
    reducers: {
        userListByAdmin: (state, action) =>{
            state.userList = action.payload
        }
    }
})


export default userListSlice.reducer

