import React, { useState } from "react";
import {
  Row,
  Col,
  Button,
  Form,
  Container,
  Image,
  Alert,
} from "react-bootstrap";
import { Link } from "react-router-dom";
import { useHistory } from "react-router-dom";
import "./index.scss";

//swiper
import { Swiper, SwiperSlide } from "swiper/react";
import SwiperCore, { Navigation, Autoplay } from "swiper";

// Import Swiper styles
import "swiper/swiper-bundle.min.css";
import "swiper/components/navigation/navigation.scss";

// img
import logo from "../../assets/images/main_logo.png";
import { validation } from "./FormValidation";
import { useRecoveryOtpMutation } from "../../store/user/userApiSlice";

// install Swiper modules
SwiperCore.use([Navigation, Autoplay]);

const Forgotpw = () => {
  const [userInput, setUserInput] = useState({ email: "" });
  const [formErrors, setFormErrors] = useState({});
  const [isValid, setIsValid] = useState({
    call: false,
    success: false,
    msg: "bbbb",
  });
  let history = useHistory();
  const [recoveryOtp] = useRecoveryOtpMutation();

  const handleEmail = (e) => {
    setUserInput({ email: e.target.value });
  };

  const recoverpw = async (event) => {
    event.preventDefault();
    event.stopPropagation();
    setFormErrors(validation(userInput));
    //console.log("submit");

    if (Object.keys(validation(userInput)).length === 0) {
      const res = await recoveryOtp(userInput);

      //console.log("res", res);
      const data = res?.data;
      if (res?.data?.status === 1) {
        setIsValid({
          ...isValid,
          call: true,
          success: true,
          msg: data?.message,
        });
        history.push("/auth/recoverpw");
        validation("clear error");
      } else {
        if (res?.error?.status === 400) {
          setIsValid({
            ...isValid,
            call: true,
            success: false,
            msg: res?.error?.data.data[0].msg,
          });
        } else {
          res.json().then((data) => {
            setIsValid({
              ...isValid,
              call: true,
              success: false,
              msg: res?.data?.message,
            });
            // console.log(error);;
          });
        }
      }

      validation("clear error");
    }
  };
 // console.log(userInput);
  return (
    <>
     <section className="sign-in-page">
        <div>
          <Row className="no-gutters align-items-center" style={{ width: "100%", height:"100vh", marginLeft: "0.1px" }}>
             <Col md="8" className="p-5 d-none d-lg-block">
                <h2 className="text-white fontBold">
                  PA Secondary Transition - Public Square
                </h2>
                <h3 className="text-white mt-2 mb-3">
                 Login and create an account to personalize your experience
                </h3>
                <h4 className="text-white mt-2 mb-3">
                  Connect, Collect, Contribute
                </h4>
                <ul className="text-white listItems">
                
                <li>Connect with people & events within your community and across the state.</li>
                <li>Collect and save your favorite transition resources & assessments.</li>
                <li>Contribute by sharing resources, articles and stories.</li>
                </ul>
            </Col>
            <Col md="4" className="bg-white p-5" style={{  height:"100vh", display:"flex", flexDirection:"column",justifyContent: "center"}}>
              
                {isValid.call &&
                  (isValid.success ? (
                    <Alert variant="success">{isValid.msg}</Alert>
                  ) : (
                    <Alert variant="danger">{isValid.msg}</Alert>
                  ))}
                <div style={{ textAlign: "center" }}>
                <Image src={logo} className="img-fluid mb-3" alt="logo" />
                </div>
                <h3 className="mb-0 text text-center" style={{ fontWeight: "600" }}>
                  Reset Password
                </h3>
                <p className="text-center formsSubHeadings text-dark">
                  Enter your email and we'll send you a 4 digit verification code to reset
                  your password.
                </p>
                <Form className="mt-4">
                  <Form.Group>
                    <Form.Label className="textBlue">Email / Username</Form.Label>
                    <Form.Control
                      type="email"
                      className="mb-0"
                      id="exampleInputEmail1"
                      placeholder="Enter Email / Username"
                      onChange={handleEmail}
                    />
                    <p className="errortext">{formErrors.email}</p>
                  </Form.Group>
                  <div className="d-inline-block w-100">
                    <Button
                      variant="primary"
                      type="submit"
                      className="float-end"
                      onClick={recoverpw}
                    >
                      Get Verification Code
                    </Button>
                  </div>
                </Form>
              
            </Col>
          </Row>
        </div>
      </section>
    </>
  );
};

export default Forgotpw;
