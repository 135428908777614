import {apiSlice} from "../apiSlice"

export const connectionApiSlice=apiSlice.injectEndpoints({
    endpoints:builder=>({
        getConnection:builder.query({
            query:()=>({
                url:'connections/get-connections',
                method:'GET',
            }),
          }),
    

        getInvitationByUserId: builder.query({
            query: () => ({
              url: `connections/get-invitation-byId`,
            }),
            // transformResponse: (response, meta, arg) => {
            //   console.log('response',response)
            //   return response
            // },
            providesTags: (result, error, args) => {
              return [
                // ...result?.data?.map(({ }) => ({
                //   type: "Group",
                //   id: _id,
                // })),
                { type: "invitationByUserId", id: "list" },
              ]
            }
          }),
       
        getInvitations:builder.query({
            query:()=>({
                url:'connections/get-invitation-count',
                method:'GET',
            })
        }),
        updateStatus:builder.mutation({
            query: (data) => ({
                url: `connections/update-status/${data.id}`,
                method: "PUT",
                body: { status:data.status },
              }),
              invalidatesTags: (result, error, args) => {
                return [
                  { type: "User", id: "list" },
                ]
              }
        }),
        createConnection:builder.mutation({
            query: (data) => ({
                url: `connections`,
                method: "POST",
                body: data,
                msg: true
              }),
        })
    })
})

export const {
    useGetConnectionQuery,
    useUpdateStatusMutation,
    useGetInvitationsQuery,
    useCreateConnectionMutation,
    useGetInvitationByUserIdQuery
}=connectionApiSlice