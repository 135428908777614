import React, { useEffect } from "react";
import { Switch, Route, Redirect, useHistory,useLocation } from "react-router-dom";
import Default from "../layouts/Default";
import WithoutLeftSidebar from "../layouts/WithoutLeftsidebar";
import WithoutRightSidebar from "../layouts/WithoutRightsidebar";
import Layout1 from "../layouts/Layout1";
import Simple from "../layouts/Simple";
var state={}
const IndexRouters = () => {
  const history = useHistory(); 
  const location = useLocation();
  const token = localStorage.getItem("token");
  useEffect(() => {
    if (!token) {
      if(history?.location?.pathname==="/auth/sign-up"){
        history.push("/auth/sign-up");

      }else{
        if(location.pathname.includes('/app/post-detail')){
          localStorage.setItem('urlRedirect',location.pathname)
        }
        history.push("/auth/sign-in");
      }
    } 
    // else {
    //   // history.push("/dashboard");
    //   history.goBack ()
    // }
  }, []);

  return (
    <>
      {!token ? (
        <Switch>
          <Route path="/errors" component={Simple}></Route>
          <Route path="/extra-pages" component={Simple}></Route>
          <Route path="/auth" component={Simple}></Route>
          <Redirect to="/" />
        </Switch>
      ) : (
        <Switch>
          <Route exact path="/">
            {<Redirect to="/dashboard" />}
          </Route>
          <Route path="/dashboard" component={Default}></Route>
          <Route
            path="/without-leftsidebar"
            component={WithoutLeftSidebar}
          ></Route>
          <Route
            path="/without-rightsidebar"
            component={WithoutRightSidebar}
          ></Route>
          <Route path="/dashboards" component={Layout1}></Route>
          <Redirect to="/" />
        </Switch>
      )}
    </>
  );
};

export default IndexRouters;
