import React, { useRef, useState } from 'react'
import { Card, Col } from 'react-bootstrap';

const Video = ({ data }) => {
    const videoRef = useRef();

    const handleMouseOver = () => {
        videoRef.current.play()
        videoRef.current.muted = true
    }

    const handleMouseOut = () => {
        videoRef.current.pause()
        videoRef.current.muted = false
    }
    return (
        
        <Card>
          <Card.Body>
        <div>
            <div style={{ height: "20vh" }}
                onMouseOver={handleMouseOver}
                onMouseOut={handleMouseOut}>
                <video ref={videoRef}
                    width="100%"
                    height="100%"
                    controls
                    loop>
                    <source src={data}
                        type="video/mp4" />
                    Your browser does not support the video tag.
                </video>
            </div>
        </div>
           </Card.Body>
           </Card>
        
    )
}

export default Video