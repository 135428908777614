import React, { useState, useEffect, useRef } from "react";
import { useGetInvitaionCountQuery, useGetUserDetailsQuery } from "../../../store/user/userApiSlice";
import {
  useGetInvitationsQuery,
  useUpdateStatusMutation,
} from "../../../store/connection/connectionApiSlice";
import { setUser } from "../../../store/user/action";
import user05 from "../../../assets/images/user/05.jpg";
import { getDate } from "../../../utilities/time";

import { useDispatch, useSelector } from "react-redux";
import {
  Navbar,
  Dropdown,
  Nav,
  Form,
  Card,
  Container,
  Image,
  Offcanvas,
  CloseButton,
} from "react-bootstrap";
import { Link, useHistory } from "react-router-dom";

//image
import logo from "../../../assets/images/main_logo.png";
import user1 from "../../../assets/images/user/1.jpg";
import user2 from "../../../assets/images/user/02.jpg";
import user3 from "../../../assets/images/user/03.jpg";
import user4 from "../../../assets/images/user/04.jpg";
import user5 from "../../../assets/images/user/05.jpg";
//Componets
import CustomToggle from "../../Dropdowns";
import { logoutUser } from "../../../store/user/action";
import fetchApi from "../../../store/apiUtil";
import { useGetSearchQuery } from "../../../store/globalSearch/globalSearchApiSlice";
import { useUpdateNotificationCountMutation } from "../../../store/notification/notificationApiSlice";
import InvitationCard from "./InvitationCard";

const Header = () => {
  const [showbtn, setShowbtn] = useState(false);
  const [searchRes, setSearchRes] = useState();
  const [enterWord, setEnterWord] = useState("");
  const [searchText, setSearchText] = useState([]);
  const [showNtf, setShowNtf] = useState(false)
  const [open, setOpen] = useState(true);
  const [showDropdown, setShowDropdown] = useState(false);
  const [isHovering, setIsHovering] = useState(false);
  const [countInvite, setCountInvite] = useState({});
  const [displayInvitations, setDisplayInvitations] = useState([]);
  const [displayNotifications, setDisplayNotifications] = useState([]);
  const [notificationCount,setNotificationCount]=useState(0)
  const [detail,setDetails] = useState({})
  const [invitationCount, setInvitationCount] = useState(0);
  const minisidebar = () => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  };
  const { data } = useGetInvitaionCountQuery();
  const invitationData = useGetInvitationsQuery();
  const { data: detailData } = useGetUserDetailsQuery(null);
  const [updateNotificationCount] = useUpdateNotificationCountMutation();
  const searchData = useGetSearchQuery(enterWord,{
    skip : enterWord?.length < 3
  });

  useEffect(() => {
    if (detailData) {
      setDetails(detailData.data[0]);
      dispatch(setUser({ ...userInfo, ...detailData.data[0] }));
    }
  }, [detailData]);

  const getInvitationsInfo = async () => {
    setShowNtf(true)
    const res = await fetchApi("connections/get-invitation-count", "GET");
    if (res && res?.status === 1 && res?.data?.data?.length > 0) {
      setDisplayInvitations(res?.data?.data);
    }
  };
  const getNotificationInfo = async () => {
    const res = await fetchApi("users/get-notifications/1", "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      const unReadNotifications=res?.data?.filter((notification) => !notification?.is_read)
      setDisplayNotifications(  unReadNotifications);
      setNotificationCount(unReadNotifications?.length)
    }
  };

  const getNotificationCount = async () => {
    const res = await fetchApi("users/get-notifications/1", "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      setDisplayNotifications(
        res?.data?.filter((notification) => !notification?.is_read)
      );
    }

  };

  // const MINUTE_MS = 5000;

  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     console.log('Logs every minute');
  //   }, MINUTE_MS);

  //   return () => clearInterval(interval); // This represents the unmount function, in which you need to clear your interval to prevent memory leaks.
  // }, [])

  useEffect(() => {
    getNotificationInfo();
    getInvitationsInfo();
    setInvitationCount(userInfo?.invitation_count);
  }, []);

  useEffect(() => {
    if (searchData && searchData?.data?.status === 1) {
      setSearchRes(searchData?.data?.data);
    }
  }, [searchData]);

  useEffect(() => {
    if (data && data.status === 1) {
      setCountInvite(data.data);
    }
  }, [data]);

  const [show1, setShow1] = useState(false);

  const localStorageUser = localStorage.getItem("userDetail");
  // const user =localStorageUser ? JSON.parse(localStorageUser) :{};
  const userInfo = useSelector((state) => state.user.userDetails);

  const history = useHistory();
  const dispatch = useDispatch();
  useEffect(() => {
    document.getElementsByTagName("ASIDE")[0].classList.toggle("sidebar-mini");
  }, []);

  
  const handleMouseEnter = () => {
    setIsHovering(true);
  };

  const handleMouseLeave = () => {
    setIsHovering(false);
  };

  const setDropdown = () => {
    setShowDropdown(!showDropdown);
  };

  const inputRef = useRef();
  const listRef = useRef();

  const handleSearch = (e) => {
    setShowbtn(true);
    const searchValue = e.target.value;
    if(searchValue.length === 0){
      setSearchRes()
    }
    setEnterWord(searchValue);
  };

  const handleNotificationClick = (notificationDetails) => {
    if (notificationDetails?.redirect_link) {
      setNotificationCount(0)
      history.push(notificationDetails?.redirect_link);
    } else {
      console.log("link not found");
    }
  };
  // useEffect(() => {
  //      inputRef.current.addEventListener('click',(event)=>{
  //      event.stopPropagation();
  //      listRef.current.style.display = "block";
  //       setSearchRes();
  //    });
  //   document.addEventListener('click', (event) => {
  //     event.stopPropagation();
  //     listRef.current.style.display = "none";
  //     // setSearchRes();
  //     // setShowbtn(false)
  //     setEnterWord("");
  //   })

  // }, [])
  useEffect(() => {
    const handleClickOutside = (e) => {
      if (!listRef?.current?.contains(e.target)) {
        setShowbtn(false);
        setEnterWord("");
      }
    };
    document.addEventListener("mousedown", handleClickOutside);
  }, [listRef]);

  const seeAllResult = () => {
    setEnterWord("");
  };

  const handleDropdown = () => {
    setShowbtn(false);
    setEnterWord("");
  };

  return (
    <>
      <div className="iq-top-navbar">
        <Navbar expand="lg" variant="light" className="nav iq-navbar p-lg-0">
          <Container fluid className="navbar-inner">
            <div className="d-flex align-items-center gap-3">
              <Link
                to="/"
                className="d-flex align-items-center gap-2 iq-header-logo d-none d-md-flex"
              >
                <Image className="" src={logo} alt="" loading="lazy" />
              </Link>
              <Link
                to="#"
                className="sidebar-toggle"
                data-toggle="sidebar"
                data-active="true"
                onClick={minisidebar}
              >
                <div className="icon material-symbols-outlined iq-burger-menu">
                  menu
                </div>
              </Link>
            </div>
            <div className="d-block d-md-none banner">
              <Link
                to="/"
                className="d-flex align-items-center gap-2 iq-header-logo"
              >
                <Image
                  className="banner-img mt-2"
                  src={logo}
                  alt=""
                  loading="lazy"
                />
              </Link>
            </div>
            <div className="table_search">
              <Form
                action="#"
                className="searchbox"
                style={{ position: "relative" }}
              >
                {
                  searchText.length === 0 ? (
                    <Link className="search-link" to="">
                      <span className="material-symbols-outlined">search</span>
                    </Link>
                  ) : (
                    <i className="ri-close-line crossbar" onClick=""></i>
                  )
                  // <Link to="" className="lh-1" onClick={onclose} style={{position:"absolute",left:"87%",top:"8px"}}>
                  //       <span className="material-symbols-outlined">close</span>
                  //     </Link>
                }
                <Form.Control
                  type="text"
                  value={enterWord}
                  className="text search-input bg-soft-primary connection-input"
                  placeholder="Search ..."
                  onChange={handleSearch}
                  ref={inputRef}
                />
              </Form>
              {showbtn && (
                <div className="search-dropdown" ref={listRef}>
                  {searchRes?.users?.slice(0, 3).map((item) => {
                    return (
                      <Link
                        to={`/dashboard/app/profile-view/${item?._id}`}
                        onClick={handleDropdown}
                      >
                        {" "}
                        <div className="dropdown d-flex justify-content-between">
                          <div>
                            <img
                              src={
                                item?.profile_picture ||
                                "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                              }
                              alt=""
                              width="25"
                              height="25"
                              className="rounded-circle"
                            />
                            <span style={{ paddingLeft: "10px" }}>
                              {item?.first_name}
                            </span>
                          </div>
                          <div>
                            <span>In People</span>
                          </div>
                        </div>{" "}
                      </Link>
                    );
                  })}
                  {searchRes?.groups?.slice(0, 3).map((item) => {
                    return (
                      <Link
                        to={`/dashboards/app/group-detail/${item._id}`}
                        onClick={handleDropdown}
                      >
                        {" "}
                        <div className="dropdown d-flex justify-content-between">
                          <div>
                            <img
                              src={
                                item?.group_image ||
                                "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                              }
                              alt=""
                              width="25"
                              height="25"
                              className="rounded-circle"
                            />
                            <span style={{ paddingLeft: "10px" }}>
                              {item?.title}
                            </span>
                          </div>
                          <div>
                            <span>In Group</span>
                          </div>
                        </div>{" "}
                      </Link>
                    );
                  })}

                  {(searchRes?.users.length || searchRes?.groups.length) > 0 ? (
                    <div onClick={() => setShowbtn(false)}>
                      <Link
                        to={`/dashboard/app/search-result-page/${enterWord}`}
                        onClick={seeAllResult}
                      >
                        <button>See all result</button>
                      </Link>
                    </div>
                  ) : (
                    ""
                  )}
                </div>
              )}
            </div>

            <Link
              className="d-lg-none d-flex"
              to="#"
              aria-expanded={show1}
              data-bs-toggle="collapse"
              data-bs-target="#navbarSupportedContent"
              onClick={() => setShow1(!show1)}
            >
              {/* <span className="material-symbols-outlined">person</span> */}
              <Image
                        src={
                          detail?.profile_picture
                            ? detail?.profile_picture
                            : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                        }
                        className="img-fluid rounded-circle"
                        alt="user"
                        loading="lazy"
                        style={{width:"30px"}}
                      />
            </Link>
            <div
              className={`offcanvas offcanvas-end iq-profile-menu-responsive ${show1 === true ? "show" : ""
                } `}
              tabIndex="-1"
              id="offcanvasBottom"
              style={{ visibility: `${show1 === true ? "visible" : "hidden"}` }}
            >
              <Offcanvas.Header>
                <h5 id="offcanvasRightLabel"> Hello{" "}
                                {userInfo?.first_name +
                                  " " +
                                  userInfo?.last_name}</h5>
                <CloseButton onClick={() => setShow1(false)} />
                {/* <Button type="button" className="btn-close text-reset"></Button> */}
              </Offcanvas.Header>
              <Offcanvas.Body className="pt-0">
                <ul className="navbar-nav  ms-auto navbar-list mobFooter">
                  <Nav.Item as="li">
                    <Link
                      to="/"
                      className="d-flex align-items-center"
                      style={{ color: "#45505f" }}
                      onClick={() => setShow1(false)} 
                    >
                      <i className="material-symbols-outlined">home</i>
                      <span className="mobile-text d-lg-none ms-3">Home</span>
                    </Link>
                  </Nav.Item>
                  <Dropdown as="li" className="nav-item d-none d-lg-block">
                    <Dropdown.Toggle
                      href="/"
                      as={CustomToggle}
                      variant="d-flex align-items-center"                   
                    >
                      <span
                        className="material-symbols-outlined"
                        style={{ color: "#45505f" }}
                        onClick={() => getInvitationsInfo()}
                      >
                        group
                      </span>
                      {displayInvitations?.length > 0 && (
                        <span class="badge rounded-pill badge-notification bg-success">
                          {displayInvitations?.length}
                        </span>
                      )}
                    </Dropdown.Toggle>
                     <Dropdown.Menu className={showNtf ? "sub-drop sub-drop-large" : "d-none"}>
                      <Card className="shadow-none m-0">
                        <Card.Header className="d-flex justify-content-between bg-primary">
                          <div className="header-title">
                            <h5 className="mb-0 text-white">Friend Request</h5>
                          </div>
                        </Card.Header>
                        <Card.Body className="p-0">
                          {displayInvitations?.slice(0,5).map((val) => {
                            let user = val.requester.ref === userInfo?._id ? val.requested : val?.requester
                            return (
                              <>
                              <InvitationCard
                                user={user}
                                displayInvitations={displayInvitations}
                                invitationCount={invitationCount}
                                setInvitationCount={setInvitationCount}
                                setDisplayInvitations={setDisplayInvitations}
                                dispatch={dispatch}
                                setUser={setUser}
                                val={val}
                                invitationData={invitationData}
                                userInfo={userInfo}
                                setShowNtf={setShowNtf}
                              />
                              </>
                            );
                          })}

                          <div className="text-center">
                            <Link
                              to="/dashboards/app/invitation-list"
                              className=" btn text-primary"
                            >
                              View More Request
                            </Link>
                          </div>
                        </Card.Body>
                      </Card>
                    </Dropdown.Menu> 
                  </Dropdown>
                  <Nav.Item as="li" className="d-lg-none">
                    <Link
                      to="/dashboard/app/friend-request"
                      className="d-flex align-items-center"
                      onClick={() => setShow1(false)} 
                    >
                      <span className="material-symbols-outlined">group</span>
                      <span className="mobile-text  ms-3">Friend Request</span>
                    </Link>
                  </Nav.Item>
                  <Dropdown as="li" className="nav-item d-none d-lg-block"
                  onBlur={()=>{
                    updateNotificationCount()
                    setNotificationCount(0)
                  }}
                  >
                    <Dropdown.Toggle
                      href="#"
                      as={CustomToggle}
                      variant="search-toggle d-flex align-items-center"
                    >
                      <i
                        className="material-symbols-outlined"
                        style={{ color: "#45505f" }}
                        onClick={() => {
                          getNotificationInfo();
                          setShowDropdown(true);
                          updateNotificationCount()
                                              }}
                      >
                        notifications
                      </i>
                      {notificationCount > 0 && (
                        <span class="badge rounded-pill badge-notification bg-success">
                          {notificationCount}
                        </span>
                      )}
                    </Dropdown.Toggle>
                    {showDropdown ? (
                      <Dropdown.Menu className="sub-drop">
                        <Card className="shadow-none m-0">
                          {!displayNotifications?.length ? <Card.Header className="d-flex justify-content-between bg-primary">
                            <div className="header-title bg-primary">
                              <h5 className="mb-0 text-white">
                                All Notifications
                              </h5>
                            </div>
                            {/* <small className="badge  bg-light text-dark">
                              {displayNotifications?.length}
                            </small> */}
                          </Card.Header> : ""}
                          <Card.Body className="p-0">
                            {displayNotifications?.map(
                              (notificationDetails, index) => {
                                if (index > 4) {
                                  return null;
                                }
                                let timeObj = getDate(
                                  notificationDetails?.sent_at
                                );

                                return (
                                  <div
                                    className="iq-sub-card"
                                    style={{
                                      backgroundColor:
                                        notificationDetails?.is_read
                                          ? "transparent"
                                          : "lightgrey",
                                    }}
                                    onClick={() =>
                                      handleNotificationClick(
                                        notificationDetails
                                      )
                                    }
                                  >
                                    <div className="d-flex align-items-center">
                                      <div className="">
                                        <Image
                                          className="avatar-40 rounded"
                                          src={notificationDetails?.icon}
                                          alt=""
                                          loading="lazy"
                                        />
                                      </div>
                                      <div className="ms-3 w-100">
                                        <h6 className="mb-0 ">
                                          {notificationDetails?.title}
                                        </h6>
                                        <div className="d-flex justify-content-between align-items-center">
                                          {/* <p className="mb-0">95 MB</p> */}
                                          <small className="float-right font-size-12">
                                            {timeObj.hours > 0
                                              ? timeObj.hours > 23
                                                ? `on ${timeObj?.timeStart.toDateString()}`
                                                : `${Math.floor(
                                                  timeObj.hours
                                                )} hour ago`
                                              : `${Math.floor(
                                                timeObj.minutes
                                              )} min ago`}
                                          </small>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                );
                              }
                            )}
                          </Card.Body>
                          <Card.Header className={!displayNotifications?.length ? "d-flex justify-content-between notification" : "d-flex justify-content-between notification bg-primary"}
                          style={ !displayNotifications?.length ? {paddingLeft:"37%",border:"1px solid #CACACA"} : null}>
                            <Link
                              to="/dashboard/app/notification"
                              className="view-all-btn"
                              onClick={()=>
                                {setDropdown()
                                setNotificationCount(0)}
                            }

                            >
                              <button
                                className=" btn header-title notfication-btn"
                                style={{ color: isHovering ? "white" : "" }}
                                onMouseEnter={handleMouseEnter}
                                onMouseLeave={handleMouseLeave}
                              >
                                View All
                              </button>
                            </Link>
                            {/* <small className="badge  bg-light text-dark">
                              {displayNotifications?.length}
                            </small> */}
                          </Card.Header>
                        </Card>
                      </Dropdown.Menu>
                    ) : null}
                  </Dropdown>
                  <Nav.Item as="li" className="d-lg-none">
                    <Link
                      to="/dashboard/app/notification"
                      className="d-flex align-items-center"
                      onClick={() => setShow1(false)} 
                    >
                      <i className="material-symbols-outlined">notifications</i>
                      <span className="mobile-text  ms-3">Notifications</span>
                    </Link>
                  </Nav.Item>
                  {/* <Dropdown as="li" className="nav-item d-none d-lg-block">
                    <Dropdown.Toggle
                      href="#"
                      as={CustomToggle}
                      variant="d-flex align-items-center"
                    >
                      <i className="material-symbols-outlined">
                        <Link
                          to="/dashboard/app/chat"
                          style={{ color: "#45505f" }}
                        >
                          mail
                        </Link>
                      </i>
                      <span className="mobile-text d-lg-none ms-3">
                        Message
                      </span>
                    </Dropdown.Toggle>
                  </Dropdown>
                  <Nav.Item as="li" className="d-lg-none">
                    <Link
                      to="/dashboard/app/chat"
                      className="dropdown-toggle d-flex align-items-center"
                    >
                      <i className="material-symbols-outlined">mail</i>
                      <span className="mobile-text  ms-3">Message</span>
                    </Link>
                  </Nav.Item> */}
                  <Dropdown as="li" className="nav-item d-none d-lg-block">
                    <Dropdown.Toggle
                      href="#"
                      as={CustomToggle}
                      variant="d-flex align-items-center"
                    >
                      <Image
                        src={
                          detail?.profile_picture
                            ? detail?.profile_picture
                            : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                        }
                        className="img-fluid rounded-circle me-3 profilePicHeader"
                        alt="user"
                        loading="lazy"
                        onClick={() => setShowDropdown(true)}
                      />
                      <div className="caption">
                       <h6
                          className="mb-0 line-height fontBold"
                          onClick={() => setShowDropdown(true)} style={{color:"#1f639c"}}
                        >
                           <span style={{color:'#000'}}>Hi,</span> {userInfo?.first_name + " " + userInfo?.last_name}
                        </h6>
                      </div>
                    </Dropdown.Toggle>
                    {showDropdown ? (
                      <Dropdown.Menu className="sub-drop caption-menu">
                        <Card className="shadow-none m-0">
                          <Card.Header>
                            <div className="header-title">
                              <h5 className="mb-0 ">
                                Hello{" "}
                                {userInfo?.first_name +
                                  " " +
                                  userInfo?.last_name}
                              </h5>
                            </div>
                          </Card.Header>
                          <Card.Body className="p-0 ">
                            <Link
                              to="/dashboard/app/profile"
                              className="mb-0 h6"
                              onClick={setDropdown}
                            >
                              <div className="d-flex align-items-center iq-sub-card border-0">
                                <span className="material-symbols-outlined">
                                  person
                                </span>
                                <div className="ms-3">My Profile</div>
                              </div>
                            </Link>
                            <Link
                              to="/dashboard/app/user-profile-edit"
                              className="mb-0 h6"
                              onClick={setDropdown}
                            >
                              <div className="d-flex align-items-center iq-sub-card border-0">
                                <span className="material-symbols-outlined">
                                  edit_note
                                </span>
                                <div className="ms-3">Edit Profile</div>
                              </div>
                            </Link>
                            {/* <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <span className="material-symbols-outlined">
                                                            manage_accounts
                                                        </span>
                                                        <div className="ms-3">
                                                            <Link to="/dashboard/app/user-account-setting" className="mb-0 h6">
                                                                Account settings
                                                            </Link>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <span className="material-symbols-outlined">
                                                            lock
                                                        </span>
                                                        <div className="ms-3">
                                                            <Link to="/dashboard/app/user-privacy-setting" className="mb-0 h6">
                                                                Privacy Settings
                                                            </Link>
                                                        </div>
                                                    </div> */}
                            <div
                              className="d-flex align-items-center iq-sub-card"
                              onClick={async () => {
                                await dispatch(logoutUser());
                                localStorage.clear();
                                history.push("/auth/sign-in");
                              }}
                            >
                              <span className="material-symbols-outlined">
                                login
                              </span>
                              <div className="ms-3 mb-0 h6">Sign out</div>
                            </div>
                            {/* <div className=" iq-sub-card">
                                                        <h5>Chat Settings</h5>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-success md-14">
                                                            circle
                                                        </i>
                                                        <div className="ms-3">
                                                            Online
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-warning md-14">
                                                            circle
                                                        </i>
                                                        <div className="ms-3">
                                                            Away
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-danger md-14">
                                                            circle
                                                        </i>
                                                        <div className="ms-3">
                                                            Disconnected
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center iq-sub-card border-0">
                                                        <i className="material-symbols-outlined text-gray md-14">
                                                            circle
                                                        </i>
                                                        <div className="ms-3">
                                                            Invisible
                                                        </div>
                                                    </div> */}
                          </Card.Body>
                        </Card>
                      </Dropdown.Menu>
                    ) : null}
                  </Dropdown>
                  <Nav.Item as="li" className="d-lg-none">
                    <Link
                      to="/dashboard/app/profile"
                      className="dropdown-toggle d-flex align-items-center"
                      onClick={() => setShow1(false)} 
                    >
                      <span className="material-symbols-outlined">person</span>
                      <span className="mobile-text  ms-3">Profile</span>
                    </Link>
                  </Nav.Item>
                  <Nav.Item as="li" className="d-lg-none">
                    <Link
                      to="/dashboard/app/user-profile-edit"
                      className="dropdown-toggle d-flex align-items-center"
                      onClick={() => setShow1(false)} 
                    >
                      <span className="material-symbols-outlined">person</span>
                      <span className="mobile-text  ms-3">Edit Profile</span>
                    </Link>
                  </Nav.Item>
                </ul>
              </Offcanvas.Body>
            </div>
          </Container>
        </Navbar>
      </div>
    </>
  );
};

export default Header;
