import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
  Alert,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";
// import { ToastContainer, toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css';

//image
import img1 from "../../../assets/images/user/11.png";
import { useCreateGroupMutation } from "../../../store/group/groupApiSlice";
import { useFileUploadMutation } from "../../../store/user/userApiSlice";


const CreateGroup = () => {
  // const { i } = useParams();
  // const userList = useSelector((state) => state.userList);
  const [showA1, setShowA1] = useState(false);
  const [createGroup, { isLoading }] = useCreateGroupMutation()
  const [groupPic, setGroupPic] = useState({ icon: "", url: "" });
  const [BgPic, setBgPic] = useState({ icon: "", url: "" });
  const [userData, setUserData] = useState({
    is_public: true,
    title: "",
    group_name: "",
    group_image: "",
    background_image: "",
  });

  const inputRef = useRef(null);
  const bg_ImageRef = useRef(null);

  const history = useHistory();
  const [fileUpload] = useFileUploadMutation();

  const groupCreate = async() => {
    const result = await createGroup(userData)
    if (result?.data?.status === 1) {
      setTimeout(() => {
        history.push("/dashboards/app/groups")
      }, 1500);
      setShowA1(true)
    }
    // fetch(
    //   `https://portal-api.pasecondarytransition.com/api/groups/create-group`,
    //   {
    //     method: "POST",
    //     body: JSON.stringify(userData),
    //     headers: {
    //       authorization: `Bearer ${localStorage.getItem("token")}`,
    //       "content-type": "application/json",
    //     },
    //   }
    // )
    //   .then((response) => {
    //     if (response.ok) {
    //       response.json().then((data) => {
    //         // toast('Group Created Successfully', {
    //         //   position: "top-right",
    //         //   autoClose: 3000,
    //         //   hideProgressBar: false,
    //         //   closeOnClick: true,
    //         //   pauseOnHover: true,
    //         //   draggable: true,
    //         //   theme: "colored",
    //         //   type: "success"
    //         // });
    //         setTimeout(() => {
    //           history.push("/dashboards/app/groups")
    //         }, 1500);
    //         setShowA1(true)

    //       });
    //     } else {
    //     }
    //   })
    //   .catch((error) => { });
  };

  const goBack = () => {
    history.goBack();
  }

  const handleChange = (e) => {
    if (e.target.id === "group_title") {
      setUserData({ ...userData, title: e.target.value });
    }
    if (e.target.id === "group_description") {
      setUserData({ ...userData, group_description: e.target.value });
    }
    if (e.target.id === "group_type") {
      if (e.target.value === "public") {
        setUserData({ ...userData, is_public: true });
      }
      if (e.target.value === "private") {
        setUserData({ ...userData, is_public: false });
      }
    }
  };

  const handleUpload = async (event) => {
    if (event.target.id === "grp-image") {
      let url = "";
      let multerData = new FormData();

      multerData.append("file", event.target.files[0]);
      let result = await fileUpload(multerData);
      if (result?.data?.status === 1) {
        url = result?.data?.data?.data;
        setUserData({ ...userData, group_image: url });
      }

      setGroupPic({
        icon: event.target.files[0],
        url: url,
      });
      setUserData({ ...userData, group_image: url });
    }

    if (event.target.id === "bg-image") {
      let url = "";
      let multerData = new FormData();

      multerData.append("file", event.target.files[0]);
      let result = await fileUpload(multerData);
      if (result?.data?.status === 1) {
        url = result?.data?.data?.data;
        setUserData({ ...userData, background_image: url });
      }

      setBgPic({
        icon: event.target.files[0],
        url: url,
      });
      setUserData({ ...userData, background_image: url });
    }
  };

  return (
    <>
      <Container>
        <div className="container" style={{ height: "200px", color: "white" }}>
          <img
            src="https://www.pasecondarytransition.com/assets21/webpimages/Practices-ban.webp"
            style={{ width: "100%", height: "100%", borderRadius: "5px" }}
          />
        </div>
        <Tab.Container defaultActiveKey="first">
          <Row className="mt-3">
            <Col lg={12}>
              <Tab.Content>
                <Tab.Pane eventKey="first" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Create a New Group</h4>
                      </div>
                      <Button onClick={goBack}
                        className="btn btn-primary me-2"

                      >
                        Back
                      </Button>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Row className="align-items-center">
                          <Form.Group className="form-group col-sm-6 ">
                            <div>Group Picture :</div>
                            <div className="profile-img-edit">
                              <img style={{ width: "150px", height: "150px" }}
                                className="profile-pic"
                                src={groupPic?.url ? groupPic?.url : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                                alt="Group-Pic"
                              />
                              <div className="p-image">
                                <i
                                  className="ri-pencil-line upload-button text-white"
                                  onClick={() => inputRef.current?.click()}
                                ></i>
                                <input
                                  id="grp-image"
                                  className="file-upload"
                                  type="file"
                                  ref={inputRef}
                                  accept="image/*"
                                  onChange={(event) => handleUpload(event)}
                                />
                              </div>
                            </div>
                          </Form.Group>

                          <Form.Group className="form-group col-sm-6">
                            <div>Cover Picture :</div>
                            <div className="profile-bg-img-edit">
                              <img
                                className="bg-grp-image"
                                src={BgPic?.url ? BgPic?.url : img1}
                                alt="profile-pic"
                              />
                              <div className="p-image">
                                <i
                                  className="ri-pencil-line upload-button text-white"
                                  onClick={() => bg_ImageRef.current?.click()}
                                ></i>
                                <input
                                  id="bg-image"
                                  className="file-upload"
                                  type="file"
                                  ref={bg_ImageRef}
                                  accept="image/*"
                                  onChange={(event) => handleUpload(event)}
                                />
                              </div>
                            </div>
                          </Form.Group>
                        </Row>
                        <Row className="align-items-center">
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label
                              htmlFor="first_name"
                              className="form-label"
                            >
                              Group Title:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="group_title"
                              onChange={handleChange}
                            />
                          </Form.Group>

                          <Form.Group className="form-group col-sm-6">
                            <Form.Label className="form-label">
                              Group Type:
                            </Form.Label>
                            <Form.Select
                              className="form-select"
                              aria-label="Default select example 2"
                              id="group_type"
                              onChange={handleChange}
                            >
                              <option value="public">Public</option>
                              <option value="private">Private</option>
                            </Form.Select>
                          </Form.Group>

                          <Form.Group className="form-group col-sm-12">
                            <Form.Label
                              htmlFor="last_name"
                              className="form-label"
                            >
                              Group Description:
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              className="form-control"
                              id="group_description"
                              onChange={handleChange}
                            />
                          </Form.Group>
                        </Row>
                        {/* <Alert
                          variant="alert alert-success"
                          show={showA1}
                          onClose={() => setShowA1(false)}
                          dismissible
                        >
                          <span>
                            <i className="fas fa-thumbs-up"></i>
                          </span>
                          <span> Group Created successfully!</span>
                        </Alert> */}
                        <Button
                          className="btn btn-primary me-2"
                          onClick={groupCreate}
                        >
                          Create
                        </Button>
                        <Button
                          type="reset"
                          style={{ backgroundColor: "rgb(68,175,157)" }}
                          className="btn btn-secondry me-1"
                          onClick={goBack}
                        >
                          Cancel
                        </Button>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
            </Col>
          </Row>
        </Tab.Container>
        {/* <ToastContainer /> */}
      </Container>
    </>
  );
};

export default CreateGroup;
