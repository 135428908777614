import React, { useState, useEffect } from "react";
import { OverlayTrigger, Tooltip, Button } from "react-bootstrap";
import LikePeopleList from "../app/likePeopleList";
import config from "../../store/config.json"

const RenderLike = ({ handleLike, data, like_count }) => {
  const [isLike, setIsLike] = useState(false);
  const [toolTipShow, setToolTipShow] = useState(false);
  const [likeCount, setLikeCount] = useState(0);
  const [likePeoples, setLikePeoples] = useState([]);

  const getLikeDetails = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/like/get-post-like/${data?._id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setLikePeoples(data?.data)
            // setGroupdetailData(data.data[0])
            // dispatch(groupDetailActions(data.data));
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (toolTipShow) {

      getLikeDetails()
    } else {
      setLikePeoples([])

    }
  }, [toolTipShow])

  useEffect(() => {
    if (data) {
      setLikeCount(data?.like_count);
    }
  }, [data?.like_count]);

  useEffect(() => {
    if (data) {
      setIsLike(data?.is_liked);
    }
  }, [data?.is_liked]);

  return (
    <OverlayTrigger
      // delay={{ hide: 450, show: 300 }}
      overlay={(props) => {
        setToolTipShow(props?.show)
        if (likePeoples?.length===0){
          return <span></span>
        }
        return <Tooltip {...props}>
          <LikePeopleList likePeoples={likePeoples}/>
        </Tooltip>
      }}
      placement="bottom"
    >
      <div className="total-like-block ms-2 me-3">
        <span
          onClick={() => {
            handleLike(data._id);
            if (isLike) {
              setLikeCount(likeCount - 1);
              setIsLike(false);
            } else {
              setLikeCount(likeCount + 1);
              setIsLike(true);
            }
          }}
        >
          <i className={isLike ? "ri-thumb-up-fill" : "ri-thumb-up-line"}></i>
        </span>{" "}
        {`${likeCount} Likes`}

      </div>
    </OverlayTrigger>
  );
};

export default RenderLike;
