import { useState, useEffect } from 'react';
import {debounce, throttle} from 'lodash'
import { Row, Col, Container, Form, Card, Button, Spinner } from 'react-bootstrap';
import { Link, useParams, useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';

const MemberList = () => {
  const [displayMembers, setDisplayMembers] = useState('');
  const { groupDetail } = useSelector((state) => state.groupDetail);
  const detailGroup = groupDetail[0]?.members_subset;
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;
  const history = useHistory();
  const [visibleMembers, setVisibleMembers] = useState(10);
  const [loading, setLoading] = useState(false);

  const onHandleBack = async (e) => {
    history.push(`/dashboards/app/group-detail/${groupDetail[0]?._id}`);
  };
  const handleScroll = () => {
    const windowHeight = 'innerHeight' in window ? window.innerHeight : document.documentElement.offsetHeight;
    const body = document.body;
    console.log("ram",windowHeight)
    const html = document.documentElement;
    const docHeight = Math.max(body.scrollHeight, body.offsetHeight, html.clientHeight, html.scrollHeight, html.offsetHeight);
    const windowBottom = windowHeight + window.pageYOffset;
    console.log("xyz",visibleMembers,detailGroup,windowBottom,docHeight)
  
    if (windowBottom >= docHeight - 10 && !loading && visibleMembers < detailGroup.length) {
      
      setLoading(true);
  
      // Simulate loading delay (replace with your actual data fetching logic)
      setTimeout(() => {
        setVisibleMembers((prevVisibleMembers) => Math.min(prevVisibleMembers + 2, detailGroup.length));
        setLoading(false);
      }, 1000);
    }
  };

  const debounceHandleScroll = throttle(handleScroll, 200)
  
  useEffect(() => {
    console.log("abc")
    window.addEventListener('scroll', debounceHandleScroll);
    return () => window.removeEventListener('scroll', debounceHandleScroll);
  }, [debounceHandleScroll]); // eslint-disable-line react-hooks/exhaustive-deps

  return (
    <>
      <Container>
        <Row>
        <Col>
          <div onClick={onHandleBack}>
          <Button variant="primary" className="mb-2 me-2">
                     Back
          </Button>
          </div>
          </Col>
          <Col lg={12}>
          
            <Card>
             <Card.Body
                className="d-flex justify-content-between grp-card-head allResourceBox"
                style={{ position: "relative" }}
              >
               
              <div className="allResource" style={{ position: "relative" }}>
                  <h4 className="grp-text">Members of Group</h4>
                </div>
                
              <div className="table_search resourceSearch">
             
                  <Form action="#" className="searchbox">
                    <div className="search-link">
                      <span className="material-symbols-outlined">search </span>
                    </div>
                    <Form.Control
                      type="text"
                      className="text search-input bg-soft-primary connection-input"
                      placeholder="Search..."
                      onChange={(e) => setDisplayMembers(e.target.value)}
                    />
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      
      <Container style={{ borderRadius: "5px" }}>
        
        <Row className="d-flex">
          {detailGroup?.filter((item) => {
            if (displayMembers === "") {
              return item;
            } else if (item.username.toLowerCase().includes(displayMembers.toLocaleLowerCase())) {
              return item
            }
          })?.slice(0,visibleMembers).map((item) => {
            return (
              <Col lg={6} md={6}>
                <Card style={{ boxShadow: "6px 6px 5px #e5e5e5" }}>
                  <Card.Body>
                    <div className="d-flex align-items-center justify-content-between">
                      <Link to={
                        item.ref === userId ? `/dashboard/app/profile` :
                          `/dashboard/app/profile-view/${item.ref}`}>
                        <div className="">
                          <img
                            src={item?.image || "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                            alt="story-img"
                            className="rounded-circle avatar-40"
                          />
                        </div>
                      </Link>
                      <div className="flex-grow-1 ms-3">
                      <Link to={
                        item.ref === userId ? `/dashboard/app/profile` :
                          `/dashboard/app/profile-view/${item.ref}`}>
                         
                          <h6 style={{fontWeight:"600"}}>{item?.username}</h6>
                       
                      </Link> </div>
                      <Link to={
                        item.ref === userId ? `/dashboard/app/profile` :
                          `/dashboard/app/profile-view/${item.ref}`}>
                        <div className=" btn btn-primary rounded confirm-btn">
                          View
                        </div>
                      </Link>
                    </div>
                  </Card.Body>
                </Card>
              </Col>
            );
          })}
           {loading &&  detailGroup.length && (
            <Col lg={12} className="text-center mt-3">
              <Spinner animation="border" variant="primary" />
            </Col>
          )}
        </Row>
      </Container>
    </>
  )
}

export default MemberList