import React, { useEffect, useState } from "react";
import { useParams, useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import { Row, Col, Container, Card } from "react-bootstrap";

import img1 from "../../assets/images/page-img/profile-bg1.jpg";
import { useGetUserListByAdminQuery } from "../../store/userList/userListByAdminApiSlice";
import { useGetUserDetailsQuery } from "../../store/user/userApiSlice";
import { userListByAdmin } from "../../store/userList/action";
import defaultBg from "../../assets/images/bg/bg.jpg";
import { useCreateConnectionMutation, useGetConnectionQuery, useGetInvitationByUserIdQuery, useGetInvitationsQuery, } from "../../store/connection/connectionApiSlice";
import { useDeletePostMutation } from "../../store/post/postApiSlice";
import Loading from "./Loading";
import config from "../../store/config.json"
import FeedBlock from "../../components/FeedBlock";
import Swal from "sweetalert2";
import { toast } from 'react-toastify';

const ProfileViewByAdmin = () => {
  const [createConnection] = useCreateConnectionMutation();
  const connectionData = useGetConnectionQuery();
  // const {data: invitationByUserId} = useGetInvitationByUserIdQuery()
  // console.warn('invitation',invitationByUserId)
  const [id, setId] = useState();
  const invitationData = useGetInvitationsQuery()
  const [userList, setUserList] = useState([]);
  const [connectionMember, setConnectionMember] = useState(false);
  const [invitationMember, setInvitationMember] = useState(false)
  const [connection, setConnection] = useState([])
  const [invitation, setInvitation] = useState([]);
  const [deletedPosts, setDeletedPosts] = useState([]);
  const [showBtn, setShowBtn] = useState(true)
  const [requested, setRequested] = useState(false)
  // const [userList, setUserList] = useState([]);
  const [userData, setUserData] = useState({});
  const [loading, setLoading] = useState(true);
  const [invitedUser, setInvitedUser] = useState()
  const history = useHistory();
  const { i } = useParams();
  const dispatch = useDispatch();
  // const { data, refetch } = useGetUserListByAdminQuery("");
  const { data: userDetailData } = useGetUserDetailsQuery(i);
  const [deletePost] = useDeletePostMutation();

  // const userData = userList?.find((element) => element._id === i);
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;

  const getInvitationByUser = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/connections/get-invitation-byId`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setInvitedUser(data?.data?.data)
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  const getDeletedPost = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/posts/get-deleted-post-history/${i}/0/1`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            console.log("datauser",data?.data)
            setDeletedPosts(data?.data)
          });
        } else {
        }
      })
      .catch((error) => { });
  };
  useEffect(() => {
    getInvitationByUser()
    getDeletedPost()
  }, [])

  useEffect(() => {
    if (userDetailData && userDetailData?.status === 1 && userDetailData?.data?.length > 0) {
      setUserData(userDetailData?.data[0]);
      setLoading(false);
      // dispatch(userListByAdmin(data?.data));
    }
  }, [userDetailData]);

  useEffect(() => {
    if (connectionData && connectionData?.data?.status === 1) {
      setConnection(connectionData?.data?.data)
    }
    if (invitationData && invitationData?.data?.status === 1) {
      setInvitation(invitationData?.data?.data?.data)
    }
  }, [connectionData, invitationData]);

  useEffect(() => {
    connection?.map((val) => {
      let user =
        val?.requester?.ref === userInfo?._id
          ? val?.requested
          : val?.requester;
      if (user?.ref === userData?._id) {
        setConnectionMember(true)
      }

    })

    invitation?.map((val) => {
      let user =
        val?.requester?.ref === userId
          ? val?.requested
          : val?.requester;
      if (user?.ref === userData?._id) {
        setInvitationMember(true)
      }
    })

    invitedUser?.map((val) => {
      if (val?.requested?.ref === userData?._id) {
        setRequested(true)
      }
    })

  })

  const handleSentInvite = async () => {
    setShowBtn(false)
    const res = await createConnection({
      user_id: i,
    });
    if (res.data.status === 1) {
      getInvitationByUser()
      setShowBtn(true)
    }
  };
  // console.log(userData?._id)
  var userType = "family"
  if (userData?.user_type === "family") {
    userType = "family/caregiver"
  } else if (userData?.user_type === "agency") {
    userType = "agency/community partner"
  } else {
    userType = userData?.user_type
  }

  const handleDisable = async (id, postDetail) => {
    let actionType= postDetail?.is_deleted ? "enable" :"disable"
    let status= postDetail?.is_deleted
    Swal.fire({
      title: `Are you sure you want to ${actionType} this Post?`,
      // text: "You will not be able to recover this Post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: `Yes, ${actionType} it!`,
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let studentId =
          userInfo?.user_type === "family" &&
            userInfo?.relation
              ?.map((userdetails) => userdetails?._id)
              ?.includes(postDetail?.created_by?.ref)
            ? postDetail?.created_by?.ref
            : "";
        let isAdmin = userInfo?.user_type === "admin"

        let result = await deletePost({ id, studentId, isAdmin, status });
        if (result.data.status === 1) {
          getDeletedPost();
          toast(`Post ${actionType} successfully`, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Post is safe!", "error");
      }
    });

  };
console.log("dele",deletedPosts)
  return (
    <>
      {loading && <Loading />}
      {!loading && <Container>
        <div style={{minHeight:'100vh'}}>
          <Col sm={12}>
            <Card>
              <Card.Body className=" profile-page p-0">
                <div className="profile-header">
                  <div className="position-relative">
                    <img
                      loading="lazy"
                      style={{ height: "auto", width: "100%" }}
                      src={userData?.backgournd_picture || defaultBg}
                      alt="profile-bg"
                      className="rounded img-fluid"
                    />
                  </div>
                  <div className="user-detail text-center mb-3">
                    <div className="profile-img">
                      <img
                        style={{ height: "200px", width: "200px" }}
                        loading="lazy"
                        src={
                          userData?.profile_picture ||
                          "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                        }
                        alt="profile-img1"
                        className="avatar-130 img-fluid"
                      />
                    </div>
                    <div className="profile-detail">
                      <h3>
                        {userData?.first_name + " " + userData?.last_name}
                      </h3>
                    </div>
                  </div>
                  <div className="profile-info p-3 d-flex align-items-center justify-content-between position-relative">
                    <div className="social-info">
                      {/* <ul className="social-data-block d-flex align-items-center justify-content-between list-inline p-0 m-0">
                        <li className="text-center ps-3">
                          <h6>Connections</h6>
                          <p className="mb-0">{userData?.connection_count}</p>
                        </li>
                        <li className="text-center ps-3">
                          <h6>Invitations</h6>
                          <p className="mb-0">{userData?.invitation_count}</p>
                        </li>
                      </ul> */}
                    </div>
                    {requested ? <div>
                      <button type="submit"
                        disabled
                        className="btn btn-secondary d-block w-100 mt-1 sendInvite"
                        style={{ padding: "2px 8px" }}>
                        Requested
                      </button>
                    </div> :
                      <>
                        {invitationMember ? <div>
                          <button type="submit"
                            disabled
                            className="btn btn-secondary d-block w-100 mt-1 sendInvite"
                            style={{ padding: "2px 8px" }}>
                            Invited You
                          </button>
                        </div> :
                          <div>
                            {!connectionMember ? <div> {userId !== userData?._id ? <div> {showBtn ? <button
                              type="submit"
                              className="btn btn-primary d-block w-100 mt-1 sendInvite"
                              style={{ padding: "2px 8px" }}
                              onClick={handleSentInvite}
                            >
                              Connect
                            </button> : ""}
                            </div> : ""}
                            </div> :

                              <h6 className="text-center">
                                In Your Connections
                              </h6>

                            }
                          </div>}
                      </>}
                  </div>
                </div>
              </Card.Body>
            </Card>
          </Col>
          <Col sm={12}>
          {/* <Row className="personalInfo"> */}
            <Col md={12} className="px-0 personalInfo"   >
              <Card style={{position:'sticky', top:76}}>
                <Card.Body >
                  <h4>About User</h4>
                  <hr />
                  <Row className="">
                    

                  </Row>

                  <div className="d-flex justify-content-between" style={{gap:'6px'}}>
                  {userData?.first_name && (
                    <Row className="" style={{flex:1}}>
                      

                      <div className="col-12 mb-2 ">
                        <h6 style={{ fontWeight: "bold",fontSize:'18px' , display:'flex',gap:'6px',alignItems:'center'}}><div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'2px'}}><i class="ri-mail-line"></i></div> <span className="mb-0 profileData" style={{display:"inline-block"}}>{userData?.email}</span></h6>
                      </div>

                    </Row>
                  )}
                  {userData?.user_type && (
                    <Row className="" style={{flex:1}}>
                      <div className="col-12  mb-2">
                        <h6 style={{ fontWeight: "bold", fontSize:'18px', display:'flex',gap:'6px',alignItems:'center' }} ><div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'2px'}}><i class="ri-user-3-fill"></i></div> <span className="mb-0 profileData">{userType}</span></h6>
                      </div>

                    

                    </Row>

                  )}
                  {userData?.location && userData?.user_type && (
                    <Row className="" style={{flex:1}}>
                      

                      <div className="col-12  mb-2">
                        <h6 style={{ fontWeight: "bold", fontSize:'18px', display:'flex',gap:'6px',alignItems:'center' }}><div style={{display:'flex',justifyContent:'center',alignItems:'center',gap:'2px'}}><i class="ri-map-pin-line"></i></div> <span className="mb-0 profileData">{userData?.location}</span></h6>
                      </div>

                    </Row>

                  )}
                  </div>

                  {/* {userData?.user_type === "educator" || userData?.user_type === "agency" ? <Row className="mb-2">
                    <div className="col-12 col-lg-6  mb-2">
                      <h6 style={{ fontWeight: "bold" }}>Work : <span className="mb-0 profileData">{userData?.work}</span></h6>
                    </div>

                  </Row> : ""} */}
                </Card.Body>
              </Card>
            </Col>
            <Col md={12} >
              <Card className="px-0">
                {deletedPosts?.map((val) => (
                  <FeedBlock
                    postDetails={val}
                    // handleLike={handleLike}
                    // handleReply={handleReply}
                    // handleCommentLike={handleCommentLike}
                    // handleComment={handleComment}
                    // getPost={getPost}
                    // handleDelete={handleDelete}
                    // deleteComment={deleteComment}
                    // editComment={editComment}
                    // getAllMedia={getAllMedia}
                    handleDisable={handleDisable}
                  />
                ))}
              </Card>
            </Col>
          {/* </Row> */}
          </Col>
        </div>
      </Container>}

    </>
  );
};

export default ProfileViewByAdmin;
