


    export function validation(values) {
        //console.log("values",values,values.email?.length)
       let errors = {}
        if(values.first_name?.length === 0){
            errors.first_name = "First Name is Required"
        }
        if(values.last_name?.length === 0){
            errors.last_name = "Last Name is Required"
        }
        if(values.email?.length === 0){
            errors.email = "Email / Username is required"
        }
        if(values.password?.length === 0){
            errors.password = "Password is required"
        }
        if(values.confirm_password?.length === 0){
            errors.confirm_password = "Confirm Password is Required"
        }
        if(values.user_type?.length === 0){
            errors.user_type = "Please Select User Type"
        }
        if(values.new_password?.length === 0){
            errors.new_password = "New Password Required"
        }
        if(values.otp?.length === 0){
            errors.otp = "4 Digit verification number Required"
        }
        if(values === 'clear error') {
            errors = {}

        }


        return errors

    }


    export function signupvalidation(values) {
        //console.log("values",values,values.email?.length)
       let errors = {}
        if(values.first_name?.length === 0){
            errors.first_name = "First Name is Required"
        }
        if(values.last_name?.length === 0){
            errors.last_name = "Last Name is Required"
        }
        if(values.email?.length === 0){
            errors.email = "Email is Required"
        }
        if(values.password?.length === 0){
            errors.password = "Password is Required"
        }
        if(values.confirm_password?.length === 0){
            errors.confirm_password = "Confirm Password is Required"
        }
        if(values.user_type?.length === 0){
            errors.user_type = "Please Select User Type"
        }
        if(values.confirm_password?.length !== 0 && values.confirm_password !== values.password){
            errors.confirm_password = "Confirm Password is not same as Password"
        }
      
        if(values === 'clear error') {
            errors = {}

        }


        return errors

    }