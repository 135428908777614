import React from "react";
import { OverlayTrigger, Tooltip } from "react-bootstrap";
import user05 from "../../assets/images/user/05.jpg";
import user01 from "../../assets/images/user/01.jpg";
import user02 from "../../assets/images/user/02.jpg";
import user03 from "../../assets/images/user/03.jpg";
import { Link } from "react-router-dom";
import { useState, useEffect } from "react";
import { getDate } from "../../utilities/time";
import { useSelector } from "react-redux";
import { useDeleteCommentMutation } from "../../store/post/postApiSlice";
import RenderFile from "../../components/RenderFile";
import LikePeopleList from "./likePeopleList";
import config from "../../store/config.json"

function RenderComment({
  key,
  objData,
  handleReply,
  post_id,
  handleCommentLike,
  isViewFullPost,
  deleteComment,
  editComment
}) {
  let timeObj = getDate(objData.createdAt);
  let [show, setShow] = useState(false);
  let [iconShow, setIconShow] = useState(false);
  let [editShow, setEditShow] = useState(false);
  let [isLike, setIsLike] = useState(() =>
    objData?.is_liked ? objData?.is_liked : false
  );
  let [likeCount, setLikeCount] = useState(() =>
    objData?.like_count ? objData?.like_count : 0
  );
  const [text, setText] = useState({
    comment_text: "",
  });
  const [submitted, setSubmitted] = useState(false);
  const [commentText, setCommentText] = useState({});

  const userInfo = useSelector((state) => state.user.userDetails);
  const [toolTipShow, setToolTipShow] = useState(false);
  const [likePeoples, setLikePeoples] = useState([]);

  const getLikeDetails = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/like/get-comment-like/${post_id}/${objData?._id}`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setLikePeoples(data?.data)
            // setGroupdetailData(data.data[0])
            // dispatch(groupDetailActions(data.data));
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    if (toolTipShow) {

      getLikeDetails()
    } else {
      setLikePeoples([])

    }
  }, [toolTipShow])


  useEffect(() => {
    setCommentText(objData)
  }, [objData])

  const handleSubmit = (e) => {
    e.preventDefault();
    handleReply(objData.ref, post_id, text.comment_text,text.file,);
    setSubmitted(true);
    setShow(false);
    setText({
      comment_text: "",
    });
  };

  const handleChange = (e) => {
    setSubmitted(false);
    setText({ ...text, [e.target.name]: e.target.value });
    if (e.target.value.length >= 1) {
      setIconShow(true);
    } else {
      setIconShow(false);
    }
  };

  const uploadFileMediaHandle = (fileData) => {
    setSubmitted(false);

    setText({ ...text, file: fileData })
    if (fileData) {
      setIconShow(true);
    } else {
      setIconShow(false);
    }
  };

  function handleCount() {
    if (isLike) {
      setLikeCount(likeCount - 1);
      setIsLike(false);
    } else {
      setLikeCount(likeCount + 1);
      setIsLike(true);
    }
  };

  function handleInput() {
    setShow(!show);
  };

  const handleEditShow = () => {
    setEditShow(!editShow);
  };

  const handleDeleteComment = () => {
    deleteComment(objData.ref, post_id);
  };

  const uploadMediaHandle = (fileData) => {
    setCommentText({ ...commentText, file: fileData })

  };

  const handleCommentChange = (e) => {
    setCommentText({ ...commentText, [e.target.name]: e.target.value })
  };
  // console.log("singh", commentText);

  const handleEditComment = (e) => {
    e.preventDefault();
    editComment(commentText.ref, post_id, commentText.comment_text, commentText.file);
    setEditShow(!editShow);
  };

  return (
    <div key={key}>
      <ul className="post-comments p-0 m-0">
        <li className="mb-2">
          <div className="d-flex flex-wrap">
            <Link to={userInfo?._id === objData?.user_id ? `/dashboard/app/profile` : `/dashboard/app/profile-view/${objData.user_id}`}>
              <div className="user-img">
                <img
                  loading="lazy"
                  src={objData?.profile_picture}
                  alt="userimg"
                  className="avatar-35 rounded-circle img-fluid"
                />
              </div>
            </Link>
            <div className="comment-data-block  ms-3">
              <Link to={userInfo?._id === objData?.user_id ? `/dashboard/app/profile` : `/dashboard/app/profile-view/${objData.user_id}`}><h6>{objData?.created_by}</h6></Link>{" "}
              <span>
                {" "}
                {timeObj.hours > 0
                  ? timeObj.hours > 23
                    ? `on ${timeObj?.timeStart.toDateString()}`
                    : `${Math.floor(timeObj.hours)} hour ago`
                  : `${Math.floor(timeObj.minutes)} min ago`}{" "}
              </span>
              <p className="mb-0">{objData.comment_text}</p>
              {/* {objData?.files && objData?.files?.map((file) => {
              return (
                <div className=" d-grid grid-rows-2 grid-flow-col gap-3">
                  <div className="row-span-2 row-span-md-1">
                    <RenderFile file={file?.url} filetype={file?.file_type} />
                  </div>
                </div>
              );
            })} */}
              {objData?.files && objData?.files?.file_url && <RenderFile file={objData?.files?.file_url} filetype={objData?.files?.file_type} />}
              {objData?.file_url && <RenderFile file={objData?.file_url} filetype={objData?.file_type} />}
              <div className="d-flex gap-2 flex-wrap align-items-center comment-activity">

                {isViewFullPost ?
                  <span>
                    {!editShow ?
                      <span>
                        {!show ?
                          <OverlayTrigger
                            // rootCloseDisabled={false}
                            // delay={{ hide: 450, show: 300 }}
                            overlay={(props) => {
                              setToolTipShow(props?.show)
                              if (likePeoples?.length === 0) {
                                return <span></span>
                              }
                              return <Tooltip {...props}>
                                <LikePeopleList likePeoples={likePeoples} />
                              </Tooltip>
                            }}
                            placement="bottom"
                          >
                            <div className="total-like-block">


                              <span
                                onClick={() => {
                                  handleCommentLike(post_id, objData._id);
                                  if (isLike) {
                                    setLikeCount(likeCount - 1);
                                    setIsLike(false);
                                  } else {
                                    setLikeCount(likeCount + 1);
                                    setIsLike(true);
                                  }
                                }}
                              >

                                <i className={isLike ? "fas fa-thumbs-down" : "fas fa-thumbs-up"} style={{ cursor: "pointer" }}></i>
                              </span>{" "}
                              <span style={{ color: "#1F639C" }}>{likeCount} Likes</span>
                            </div>
                          </OverlayTrigger>
                          : "  "}
                      </span> : " "}
                  </span>
                  : " "}

                {!editShow ?
                  <span style={{ cursor: "pointer", color: "#1f639c" }} onClick={handleInput}>
                    Reply
                  </span> : " "}

                {!show ?
                  <div>
                    {objData.user_id === userInfo?._id ? (
                      <span style={{ cursor: "pointer", color: "#1f639c" }} onClick={handleEditShow}>
                        Edit
                      </span>
                    ) : " "}

                    {objData.user_id === userInfo?._id && !editShow ? (
                      <span style={{ cursor: "pointer", color: "#1f639c", marginLeft: "0.625rem" }} onClick={handleDeleteComment}>
                        Delete
                      </span>
                    ) : ""}
                  </div> : " "}
              </div>

              {show ? (
                <div style={{ position: "relative", paddingRight: "1.5rem" }}>
                  <form className="d-flex comment-text" onSubmit={handleSubmit}>
                    <input
                      name="comment_text"
                      type="text"
                      className="form-control rounded"
                      placeholder="Enter Your Reply"
                      value={text.comment_text}
                      onChange={(e) => handleChange(e)}
                    />

                    <label style={{ position: "absolute", right: "1%" }}>
                      <i class="fa fa-paperclip"></i>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="file"
                        //  accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          // console.log(e.target.files[0]);
                          uploadFileMediaHandle(e.target.files[0]);
                        }}
                      />
                       {iconShow &&
                        <button
                          className="border-0  btn btn-link"
                          type="submit"
                          onClick={handleSubmit}
                        >
                          <i
                            className="fa fa-paper-plane"
                            style={{ color: "#45505f" }}
                          ></i>
                        </button>}
                    
                    </label>


                  </form>
                  {text?.file &&
                    <div className="mt-2">

                      <RenderFile file={text?.file} filetype={"filetype"} />
                    </div>
                  }
                </div>
              ) : " "}

              {editShow ? (
                <div style={{ position: "relative", paddingRight: "1.5rem" }}>
                  <form className="d-flex comment-text" onSubmit={handleEditComment}>
                    <input
                      name="comment_text"
                      type="text"
                      className="form-control rounded"
                      placeholder="Edit Your Comment"
                      value={commentText.comment_text}
                      onChange={handleCommentChange}
                    />

                    <label style={{ position: "absolute", right: "1%" }}>
                      <i class="fa fa-paperclip"></i>

                      <input
                        style={{ display: "none" }}
                        type="file"
                        id="file"
                        //  accept=".png,.jpeg,.jpg"
                        onChange={(e) => {
                          // console.log(e.target.files[0]);
                          uploadMediaHandle(e.target.files[0]);
                        }}
                      />
                      <button
                        className="border-0  btn btn-link"
                        type="submit"
                      // style={{ position: "absolute", right: "1%" }}
                      >
                        <i
                          className="fa fa-paper-plane"
                          style={{ color: "#45505f" }}
                        ></i>
                      </button>
                    </label>

                  </form>
                  {commentText?.file &&
                    <div className="mt-2">

                      <RenderFile file={commentText?.file} filetype={"filetype"} />
                    </div>
                  }
                </div>
              ) : " "}
            </div>
          </div>
        </li>
      </ul>
    </div>
  );
}

export default RenderComment;