import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Container, Spinner } from "react-bootstrap";
import Card from "../../components/Card";
import { useHistory } from "react-router-dom";
import './index.scss';

const Events = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const [allRecentEvent, setAllRecentEvent] = useState([]);
  const history = useHistory();
  const [loadingMore, setLoadingMore] = useState(false);
  const [itemsToDisplay, setItemsToDisplay] = useState(6);
  const itemsPerScroll = 1;
  const months = [
    "January", "February", "March", "April", "May", "June", "July",
    "August", "September", "October", "November", "December"
  ];

  const upcomingEventDiv= useRef(null)
  const recentEventDiv= useRef(null)

  useEffect(()=>{
    const leftDiv = upcomingEventDiv.current;
    const rightDiv = recentEventDiv.current;

    const handleLeftScroll = ()=>{
        rightDiv.style.position = "sticky";
        rightDiv.style.top = `${leftDiv.scrollTop}px`
    }
    const handleRightScroll = ()=>{
        leftDiv.style.position = "sticky";
        leftDiv.style.top = `${rightDiv.scrollTop}px`
    }

    leftDiv.addEventListener('scroll',handleLeftScroll);
    rightDiv.addEventListener('scroll',handleRightScroll);

    return  ()=>{
        leftDiv.removeEventListener('scroll',handleLeftScroll)
        rightDiv.removeEventListener('scroll',handleRightScroll)
    }

  }, []);

  const formatDate = (dateString) => {
    const dateParts = dateString.split("-");
    const month = months[parseInt(dateParts[1], 10) - 1];
    const day = parseInt(dateParts[0], 10);
    const year = parseInt(dateParts[2], 10);
    return `${month} ${day}, ${year}`;
  };

  const formatTime = (timeString) => {
    const [hour, minute] = timeString.split(":");
    const hourInt = parseInt(hour, 10);
    const minuteInt = parseInt(minute, 10);
    const ampm = hourInt >= 12 ? "pm" : "am";
    const formattedHour = hourInt % 12 === 0 ? 12 : hourInt % 12;
    return `${formattedHour.toString().padStart(2, "0")}:${minuteInt.toString().padStart(2, "0")} ${ampm}`;
  };

  const getInvitations = async () => {
    const res = await fetch("https://www.pasecondarytransition.com/upcomingeventjson");
    const events = await res.json();
    console.log("eventss",events)
    if (res.status === 200 && events?.data?.length > 0) {
      setAllNotifications(events.data);
    }
  };

  const getRecentdata = async () => {
    const res = await fetch("https://www.pasecondarytransition.com/recenteventjson");
    const events = await res.json();
    if (res.status === 200 && events?.data?.length > 0) {
      setAllRecentEvent(events.data);
    }
  };

  useEffect(() => {
    getInvitations();
    getRecentdata();
  }, []);

  const handleNotificationClick = (notificationDetails) => {
    const link = `https://www.pasecondarytransition.com/event-detail/${notificationDetails?.slug}`;
    if (notificationDetails?.slug) {
      history.push(link);
    } else {
      console.log("Link not found");
    }
  };

  const handleScroll = () => {
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
      setLoadingMore(true);
      setTimeout(() => {
        setItemsToDisplay((prevItems) => prevItems + itemsPerScroll);
        setLoadingMore(false);
      }, 1000);
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [loadingMore]);

  return (
    <div className="events-wrapper">
      <Container className= "eventsConatiner">
        <Row className="overflow-hidden events-row">
          <Col sm="12" md="6" className="events-col">
            <div ref={upcomingEventDiv} className="events-div">
              <Card className="card-container mt-3 event-card">
                <div>
                  <h5 className="grp-text ms-2 mt-2">UPCOMING EVENTS</h5>
                  <div className="highlight-bar"></div>
                  {/* <div class="ms-4" style="background-color: rgb(60, 174, 163); height: 3px; width: 50px;"></div> */}
                </div>
                <Card.Body>
                  <ol className="notification-list p-0">
                    {allNotifications.map((notificationDetails, index) => (
                      <li key={index} className="d-flex align-items-center justify-content-between mb-2">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className="ms-2">
                              <p className="mb-1">
                                {formatDate(notificationDetails?.sdate)}, {formatTime(notificationDetails?.stime)} - {formatTime(notificationDetails?.etime)}
                              </p>
                              <a
                                href={`https://www.pasecondarytransition.com/event-detail/${notificationDetails?.slug}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h6>{notificationDetails?.title}</h6>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                </Card.Body>
              </Card>
            </div>
          </Col>
          <Col sm="12" md="6" className="events-col">
            <div ref={recentEventDiv} className="events-div">
              <Card className="card-container mt-3 event-card">
                <h5 className="grp-text ms-2 mt-2">RECENT EVENTS</h5>
                <div className="highlight-bar"></div>
                <Card.Body>
                  <ol className="notification-list p-0">
                    {allRecentEvent.slice(0, itemsToDisplay).map((notificationDetails, index) => (
                      <li key={index} className="d-flex align-items-center justify-content-between mb-2">
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className="ms-2">
                              <p className="mb-1">
                                {formatDate(notificationDetails?.sdate)}, {formatTime(notificationDetails?.stime)} - {formatTime(notificationDetails?.etime)}
                              </p>
                              <a
                                href={`https://www.pasecondarytransition.com/event-detail/${notificationDetails?.slug}`}
                                target="_blank"
                                rel="noopener noreferrer"
                              >
                                <h6>{notificationDetails?.title}</h6>
                              </a>
                            </div>
                          </div>
                        </div>
                      </li>
                    ))}
                  </ol>
                </Card.Body>
              </Card>
            </div>
          </Col>
          {loadingMore && itemsToDisplay < allRecentEvent.length && (
            <Col lg={12} className="text-center mt-3 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </Col>
          )}
        </Row>
      </Container>
    </div>
  );
};

export default Events;
