import React, { useState, useEffect } from "react";
import { Row, Col, Container, Dropdown,Spinner } from "react-bootstrap";
import Card from "../../components/Card";
import { Link,useHistory } from "react-router-dom";
import fetchApi from "../../store/apiUtil";
import { getDate } from "../../utilities/time";

// img
import user1 from "../../assets/images/user/01.jpg";
import user2 from "../../assets/images/user/02.jpg";
import user3 from "../../assets/images/user/03.jpg";
import user4 from "../../assets/images/user/04.jpg";
import user5 from "../../assets/images/user/05.jpg";
import user6 from "../../assets/images/user/06.jpg";
import user7 from "../../assets/images/user/07.jpg";
import user8 from "../../assets/images/user/08.jpg";
import user9 from "../../assets/images/user/09.jpg";
import user10 from "../../assets/images/user/10.jpg";
import user11 from "../../assets/images/user/11.jpg";
import user12 from "../../assets/images/user/12.jpg";
import user13 from "../../assets/images/user/13.jpg";
import user14 from "../../assets/images/user/14.jpg";

const Notification = () => {
  const [allNotifications, setAllNotifications] = useState([]);
  const history = useHistory();
  const [loadingMore, setLoadingMore] = useState(false); // Loading more data on scroll
  const [itemsToDisplay, setItemsToDisplay] = useState(6);
  const itemsPerScroll = 1;

  const getInvitations = async () => {
    const res = await fetchApi("users/get-notifications/1", "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      setAllNotifications(res?.data);
    }
    // console.log("fetchApi",res)
  };

  useEffect(() => {
    getInvitations();
  }, []);

  const handleNotificationClick=(notificationDetails)=>{
     if(notificationDetails?.redirect_link){

        history.push(notificationDetails?.redirect_link)
     }else{
      console.log("link not found")
     }

  }
  const handleScroll = () =>{
    const { scrollTop, clientHeight, scrollHeight } = document.documentElement;
    if (scrollTop + clientHeight >= scrollHeight - 5 && !loadingMore) {
      setLoadingMore(true);
      setTimeout(() => {
        setItemsToDisplay(prevItems => prevItems + itemsPerScroll);
        setLoadingMore(false);
      }, 1000); // Simulating loading delay, replace with your actual data fetching logic
    }
    };
    
    useEffect(() => {
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
    }, [loadingMore]);
    
  return (
    <>
      <Container>
        <Row>
          <Col sm="12">
            <h4 className="grp-text">Notifications</h4>
          </Col>
          <Col sm="12">
            {allNotifications?.slice(0,itemsToDisplay).map((notificationDetails) => {
              let timeObj = getDate(notificationDetails?.sent_at);
              return (
                <Card style={{backgroundColor:false ? "transparent" :"lightgrey"}} >
                  <Card.Body >
                    <ul className="notification-list m-0 p-0" onClick={()=>handleNotificationClick(notificationDetails)}>
                      <li className="d-flex align-items-center justify-content-between" style={{cursor:"pointer"}}>
                        <div className="user-img img-fluid">
                          <img
                            src={notificationDetails?.icon}
                            alt="story-img"
                            className="rounded-circle avatar-40"
                          />
                        </div>
                        <div className="w-100">
                          <div className="d-flex justify-content-between">
                            <div className=" ms-3">
                              <h6>{notificationDetails?.title}</h6>
                              <p className="mb-0">
                                {timeObj.hours > 0
                                  ? timeObj.hours > 23
                                    ? `on ${timeObj?.timeStart.toDateString()}`
                                    : `${Math.floor(timeObj.hours)} hour ago`
                                  : `${Math.floor(timeObj.minutes)} min ago`}
                              </p>
                            </div>
                            <div className="d-flex align-items-center">
                              {/* <Link to="#" className="me-3 iq-notify bg-soft-primary rounded">
                                       <i className="material-symbols-outlined md-18">
                                          military_tech
                                       </i>
                                       </Link> */}
                              {/* <div className="card-header-toolbar d-flex align-items-center">
                                          <Dropdown>
                                             <Dropdown.Toggle as="span" className="material-symbols-outlined">
                                                more_horiz
                                             </Dropdown.Toggle>
                                             <Dropdown.Menu className="dropdown-menu-right">
                                                <Dropdown.Item to="#"><i className="ri-eye-fill me-2"></i>View</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-delete-bin-6-fill me-2"></i>Delete</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-pencil-fill me-2"></i>Edit</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-printer-fill me-2"></i>Print</Dropdown.Item>
                                                <Dropdown.Item to="#"><i className="ri-file-download-fill me-2"></i>Download</Dropdown.Item>
                                             </Dropdown.Menu>
                                          </Dropdown>
                                       </div> */}
                            </div>
                          </div>
                        </div>
                      </li>
                    </ul>
                  </Card.Body>
                </Card>
              );
            })}
          </Col>
          {loadingMore && itemsToDisplay< allNotifications.length && <Col lg={12} className="text-center mt-3 d-flex justify-content-center align-items-center">
              <Spinner animation="border" variant="primary" />
            </Col>
}
        </Row>
      </Container>
    </>
  );
};

export default Notification;
