import config from "./config.json"

const fetchApi = async (url, method, id) => {
  let apiResponseRaw = await fetch(
    id ? `${config.WEBPORTAL_URL}/api/${url}/${id}` : `${config.WEBPORTAL_URL}/api/${url}`,
    {
      method: method,
      headers: {
        authorization: `Bearer ${localStorage.getItem("token")}`,
        "content-type": "application/json",
      },
    }
    // )
    //   .then((response) => {
    //     if (response.ok) {
    //       response.json().then((data) => {
    //         console.log(data,"myres")
    //         apiResponse= data
    //         // setAllInvitations(data?.data?.data);
    //         // setDisplayInvitations(data?.data?.data);
    //       });
    //     } else {
    //     }
    //   })
    //   .catch((error) => {}
  );
  let apiResponse = await apiResponseRaw.json()

  if (apiResponseRaw?.status === 401) {

    localStorage.clear();
    window.location.href = window.location.origin;
    window.location.reload(false);
  }
  return apiResponse
};

export default fetchApi