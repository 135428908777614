import React, { useState, useEffect } from "react";
import { Row, Col, Container, Form, Card, Button } from "react-bootstrap";

import { Link, useHistory } from "react-router-dom";
import {
  useGetConnectionQuery,
  useUpdateStatusMutation,
} from "../../../store/connection/connectionApiSlice";
import { useSelector } from "react-redux";
import { useAddMemberMutation } from "../../../store/group/groupApiSlice";
import { useRemoveMemberMutation } from "../../../store/group/groupApiSlice";
import MemberCard from "./MemberCard";

const AddMemberGroup = () => {
  const [allConnections, setAllConnections] = useState([]);
  const [displayConnections, setDisplayConnections] = useState([]);
  const { data } = useGetConnectionQuery("");
  const [updateStatus] = useUpdateStatusMutation();
  const [addMember] = useAddMemberMutation();
  const [removeMember] = useRemoveMemberMutation()
  const { groupDetail } = useSelector((state) => state.groupDetail);
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;
  const history = useHistory();

  const onHandleSearchChange = async (e) => {
    let connectionSearchData = e.target.value;
    e.preventDefault();
    var tempArr = [];
    allConnections.map((item) => {
      let fullName = item.requested?.username;
      const lData = fullName.toLowerCase();
      if (lData.includes(connectionSearchData.toLowerCase())) {
        tempArr.push(item);
      }
    });

    setDisplayConnections(tempArr);
  };

  const onHandleBack = async (e) => {
          history.push(`/dashboards/app/group-detail/${groupDetail[0]?._id}`);

  };

  useEffect(() => {
    if (data && data?.status === 1 && data?.data?.length > 0) {
      setAllConnections(data?.data);
      setDisplayConnections(data?.data);
    }
  }, [data]);
  return (
    <>
      <Container>
        
        <Row>
          <Col>
          <div onClick={onHandleBack}>

          <Button variant="primary" className="mb-2 me-2">
                     Back
          </Button>
          </div>
          </Col>
      
          <Col lg={12}>
            <Card>
              <Card.Body
                className="d-flex justify-content-between grp-card-head allResourceBox"
                style={{ position: "relative" }}
              >
              <div className="allResource" style={{ position: "relative" }}>
                  <h4 className="grp-text">Add Connections to Group</h4>
                </div>
              
              <div className="table_search resourceSearch">
                  <Form action="#" className="searchbox">
                    <div className="search-link">
                      <span className="material-symbols-outlined">search </span>
                    </div>
                    <Form.Control
                      type="text"
                      className="text search-input bg-soft-primary connection-input"
                      placeholder="Search..."
                      onChange={(event) => onHandleSearchChange(event)}
                    />
                  </Form>
                </div>
              </Card.Body>
            </Card>
          </Col>
        </Row>
      </Container>
      <Container>
        <Row className="d-flex">
         
            {displayConnections?.filter((val) => {
              let user =
                val.requester.ref === userId
                  ? val.requested
                  : val?.requester;
              if (groupDetail[0]?.owner?.ref !== user.ref) {
                return val;
              }
            })?.map((val) => {
              let user =
                val.requester.ref === userId
                  ? val.requested
                  : val?.requester;
              return (
                <Col lg={6}>
                <MemberCard
                  user={user}
                  addMember={addMember}
                  removeMember={removeMember}
                  groupDetail={groupDetail}
                />
                </Col>
              )
            })}
        </Row>
      </Container>
    </>
  );
};

export default AddMemberGroup;
