import { apiSlice } from "../apiSlice";

export const postApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    getPosts: builder.query({
      query: (page) => ({
        url: `posts/get-all-post/0/${page}`,
        method: "GET",
      }),
    }),

    getPostsForUser: builder.query({
      query: (counter) => ({
        url: `posts/get-post-by-user_id/0/${counter}`,
        method: "GET",
      }),
      providesTags: (result, error, args) => {
        return [
          { type: "UserPost", id: "user" },
        ]
      }
    }),

    getPostsForGroup: builder.query({
      query: (data) => ({
        url: `/posts/get-group-post/${data?.group_id}/0/${data?.counter}`,
        method: "GET",
      }),
      providesTags: (result, error, args) => {
        return [
          { type: "GroupPost", id: "grp" },
        ]
      }
    }),

    getPostsForId: builder.query({
      query: (id) => ({
        url: `/posts/get-post-details/${id}`,
        method: "GET",
      }),
    }),

    createPost: builder.mutation({
      query: (data) => ({
        url: "/posts/create-post",
        method: "POST",
        body: data,
      }),
    }),

    getUrlMeta: builder.mutation({
      query: (data) => ({
        url: "/posts/url-meta-data",
        method: "POST",
        body: data,
      }),
    }),

    createGroupPost: builder.mutation({
      query: (data) => ({
        url: `/posts/create-post/${data?.group_id}`,
        method: "POST",
        body: data,
      }),
    }),

    deletePost: builder.mutation({
      query: ({ id, studentId, isAdmin, status }) => {
        return {
          url: studentId
            ? `/posts/delete-post/${id}/${studentId}`
            : isAdmin ? `/posts/delete-post-by-admin/${id}/${status === undefined ? "false" : status}` : `/posts/delete-post/${id}`,
          method: "DELETE",
        };
      },
    }),

    updatePost: builder.mutation({
      query: (data) => ({
        url: `/posts/update-post/${data.id}`,
        method: "PUT",
        body: data,
      }),
    }),

    feedUpload: builder.mutation({
      query: (data) => {
        return {
          url: data?.group_id
          ? `/aws/upload_feed/group/${data?.group_id}`
          : `/aws/upload_feed/${data?.type}`,
          method: "POST",
          body: data?.multerData,
        };
      },
    }),
    commentUpload: builder.mutation({
      query: (data) => {
        return {
          url: `/aws/upload-comment-file`,
          method: "POST",
          body: data,
        };
      },
    }),

    likePost: builder.mutation({
      query: (data) => ({
        url: "/like/like-post",
        method: "POST",
        body: data,
      }),
    }),

    likeComment: builder.mutation({
      query: (data) => ({
        url: "/like/like-comment",
        method: "POST",
        body: data,
      }),
    }),

    createComment: builder.mutation({
      query: (data) => ({
        url: `comments/add-comment`,
        method: "POST",
        body: data,
      }),
    }),

    deleteComment: builder.mutation({
      query: (data) => ({
        url: `comments/delete-comment`,
        method: "DELETE",
        body: data,
      }),
    }),

    editComment: builder.mutation({
      query: (data) => ({
        url: `comments/edit-comment`,
        method: "PATCH",
        body: data,
      }),
    }),
  }),
});

export const {
  useGetPostsQuery,
  useCreatePostMutation,
  useCreateGroupPostMutation,
  useDeletePostMutation,
  useFeedUploadMutation,
  useLikePostMutation,
  useCreateCommentMutation,
  useLikeCommentMutation,
  useUpdatePostMutation,
  useGetPostsForUserQuery,
  useGetUrlMetaMutation,
  useGetPostsForGroupQuery,
  useGetPostsForIdQuery,
  useEditCommentMutation,
  useDeleteCommentMutation,
  useCommentUploadMutation
} = postApiSlice;
