import React, { useState,useEffect } from "react";

import pdfIcon from "../assets/images/pdfIcon.png";
import docIcon from "../assets/images/docImg.png";

function RenderFile({ fileUrl, file, filename, filetype }) {
  const [fileUrlString, setFileUrlString] = useState(() => file instanceof File ? window.URL.createObjectURL(file) : file)
  const [imgUrlString, setImgUrlString] = useState("")
  useEffect(() => {
    if (file) {
      setImgUrlString(typeof file === "string" ? file : URL.createObjectURL(file))
      setFileUrlString(file instanceof File ? window.URL.createObjectURL(file) : file)
    }
  }, [file])
  
  if (file) {
    let typeArr = file.type ? file.type?.split("/") : filetype?.split("/");
    // let fileUrlString = file instanceof File ? window.URL.createObjectURL(file) : file
    let localFileUpdate = file instanceof File
    if (Array.isArray(typeArr) && typeArr[0] === "image") {
      return (
        <div key={imgUrlString}>
          {file && (
            <img
              src={imgUrlString}
              style={{ width: localFileUpdate ? "40%" : "100%", height: localFileUpdate ? "40%" : "auto" }}
            />
          )}
        </div>
      );
    } else if (Array.isArray(typeArr) && typeArr[0] === "video") {
      return (
        // <div >
        <div key={fileUrlString}>
          {/* <img
            src="https://jbpbucket-dev.s3.amazonaws.com/devjanbask/feed/videoIcon.jpg"
            style={{ width: "100px" }}
          /> */}
          <video width={localFileUpdate ? "40%" : "100%"} height={localFileUpdate ? "40%" : "100%"} controls>
            <source src={fileUrlString} type="video/mp4" />
            {/* <source src={file} type="video/ogg"/> */}

            Your browser does not support the video tag.
          </video>
          {/* <span>{` ${file.name || filename}`} </span> */}
        </div>
      );
    } else if (Array.isArray(typeArr) && typeArr[0] === "application") {
      return (
        <div key={typeArr[1] === "pdf" ? pdfIcon : docIcon}>
          <a href={file}>
            <img
              src={typeArr[1] === "pdf" ? pdfIcon : docIcon}
              style={{ width: "100px" }}
            />
          </a>

        </div>
      );
    } else { 
      return <div>Undefined file type</div>;
    }
  } else if (fileUrl) {
    let typeArr = filetype ? filetype?.split("/") : null;
    if (Array.isArray(typeArr) && typeArr[0] === "image") {
      return (
        <div>
          <img src={fileUrl} style={{ width: "100%", height: "auto" }} />
        </div>
      );
    } else if (Array.isArray(typeArr) && typeArr[0] === "video") {
      return (
        <div>
          {/* <img
            src="https://jbpbucket-dev.s3.amazonaws.com/devjanbask/feed/videoIcon.jpg"
            style={{ width: "100px" }}
          /> */}
          <video width="100%" height="100%" controls>
            <source src={fileUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          {/*<span>{` ${file.name || filename}`} </span>*/}
        </div>
      );
    } else if (Array.isArray(typeArr) && typeArr[0] === "application") {
      return (
        <div>
          <a href={fileUrl}>
            <img
              src={typeArr[1] === "pdf" ? pdfIcon : docIcon}
              style={{ width: "100px" }}
            />
          </a>

          {/*<span>{` ${file.name || filename}`} </span>*/}
        </div>
      );
    } else {
      return <div>Undefined file type</div>;
    }
  } else {
    return null;
  }
}

export default RenderFile;
