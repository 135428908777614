import React, { useState } from 'react';
import RenderFile from './RenderFile';

function CommentInput({ handleComment, data }) {

  let [iconShow, setIconShow] = useState(false);
  const [comment, setComment] = useState({
    comment_text: ""
  });
  const [file, setFile] = useState(null);
  const handleSubmit = (e) => {
    e.preventDefault();
    handleComment(data._id, comment.comment_text, comment.file);
    setFile(null)
    setComment({
      comment_text: "",
      file:null
    });
  }
  console.log("data",data)

  const handleChange = (e) => {
    setComment({ ...comment, [e.target.name]: e.target.value });
    if (e.target.value.length >= 1) {
      setIconShow(true);
    } else {
      setIconShow(false);
    }
  }

  const uploadMediaHandle = (fileData) => {
    setFile(fileData);
    setComment({ ...comment, file: fileData });
    if (fileData) {
      setIconShow(true);
    } else {
      setIconShow(false);
    }
  };

  return (
    <div style={{ position: "relative" }}>
      <form className="comment-text d-flex align-items-center mt-3"
        onSubmit={handleSubmit}
      >
        {/* <div> */}

        <input
          type="text"
          className="form-control rounded"
          placeholder="Enter Your Comment"
          name='comment_text'
          value={comment.comment_text}
          onChange={(e) => handleChange(e)}
        />
        <label style={{ position: "absolute", right: "1%" }}>
          {/* <i className="fa fa-paper-plane" style={{ color: "#45505f" }}></i> */}
          <i class="fa fa-paperclip"></i>

          <input
            style={{ display: "none" }}
            type="file"
            id="file"
            //  accept=".png,.jpeg,.jpg"
            onChange={(e) => {
              uploadMediaHandle(e.target.files[0]);
            }}
          />
          {iconShow &&
            <button
              className="border-0  btn btn-link"
              type="submit">
              <i className="fa fa-paper-plane" style={{ color: "#45505f" }}></i>
            </button>}
        </label>

        {/* </div> */}





      </form>
      {file &&
        <div className="mt-2">

          <RenderFile file={file} filetype={"filetype"} />
        </div>
      }


    </div>
  )
}

export default CommentInput;