import React from 'react'
import { Card } from 'react-bootstrap'

const Image = ({ data }) => {
    return (
        <div>
            <Card>
                <Card.Body style={{ padding: "0" }}>
                    <div style={{ height: "20vh", }}>
                        <img src={data} style={{ width: "100%", height: "100%" }}></img>
                    </div>
                </Card.Body>
            </Card>
        </div>
    )
}

export default Image