import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { setConnections } from "../store/connection/action";
import { useGetConnectionQuery } from "../store/connection/connectionApiSlice";
import {
  Row,
  Col,
  Container,
  Dropdown,
  OverlayTrigger,
  Tooltip,
  Modal,
} from "react-bootstrap";
import { Link, useParams, useHistory } from "react-router-dom";
import Card from "../components/Card";
import TextEditor from "../components/TextEditor";
import CustomToggle from "../components/Dropdowns";
import ShareOffcanvas from "../components/ShareOffcanvas";
import Loading from "./app/Loading"
import { ToastContainer, toast, } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import {
  useCreatePostMutation,
  useGetPostsQuery,
  useFeedUploadMutation,
  useLikePostMutation,
  useLikeCommentMutation,
  useGetUrlMetaMutation,
  useDeletePostMutation,
  useDeleteCommentMutation,
  useEditCommentMutation,
  useCommentUploadMutation
} from "../store/post/postApiSlice";
import { useGetAllUsersQuery, useGetUserDetailsQuery } from "../store/user/userApiSlice";
import { useCreateConnectionMutation } from "../store/connection/connectionApiSlice";
import { useCreateCommentMutation } from "../store/post/postApiSlice";
import PlusMinus from './+-icon.png'
import RenderFile from "../components/RenderFile";
import FeedBlock from "../components/FeedBlock";
import fetchApi from "../store/apiUtil";

import s2 from "../assets/images/page-img/s2.jpg";
import loader from "../assets/images/page-img/page-load-loader.gif";
import { setUser } from "../store/user/action";
import { useAddMemberMutation, useGetGroupsQuery } from "../store/group/groupApiSlice";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faCirclePlus } from '@fortawesome/free-solid-svg-icons';

const Index = () => {
  const [show, setShow] = useState(false);
  const [text, setText] = useState("");
  const [file, setFile] = useState(null);
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(false);
  const [allDisplayedUsers, setAllDisplayedUsers] = useState([]);
  const [allPosts, setAllPosts] = useState([]);
  const [detail, setDetails] = useState({})
  const [textEditorData, setTextEditorData] = useState(null);
  const [urlDetails, setUrlDetails] = useState({});
  const [members, setMembers] = useState(5);
  const [groupLimit, setGroupLimit] = useState(5);

  const history = useHistory();

  const handleClose = () => {
    setTextEditorData(null);
    setUrlDetails({});
    setFile(null);
    setShow(false);
  };
  const handleShow = () => setShow(true);
  const localStorageUser = localStorage.getItem("userDetail");
  const user = localStorageUser ? JSON.parse(localStorageUser) : {};
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;
  
  const [createPost] = useCreatePostMutation();
  const [createConnection] = useCreateConnectionMutation();
  const [feedUpload] = useFeedUploadMutation();
  const [commentUpload] = useCommentUploadMutation()
  const [likePost] = useLikePostMutation();
  const [likeComment] = useLikeCommentMutation();
  const [getUrlMeta] = useGetUrlMetaMutation();
  const getPost = useGetPostsQuery(page);
  const { data } = useGetConnectionQuery();
  const [deletePost] = useDeletePostMutation();
  const [delComment] = useDeleteCommentMutation();
  const [updateComment] = useEditCommentMutation();
  const [commentPost] = useCreateCommentMutation();
  const { data: detailData } = useGetUserDetailsQuery(null);
  const [counter, setCounter] = useState(2);
  const [group, setGroup] = useState([]);
  const { data: groupData, refetch } = useGetGroupsQuery(counter);
  const [addMember] = useAddMemberMutation();

  const myParams = useParams();
console.log('lengthw',groupData)
  const dispatch = useDispatch();
  useEffect(() => {
    if (groupData && groupData.status === 1) {
      // setAllGroup(data?.data)
      const notJoinedGroup = groupData?.data.filter(item=>
      !item.members_subset.some(item=> item.ref=== userId)
      )

      setGroup(notJoinedGroup);
    }
  }, [groupData,counter]);
  console.log('group',group)

  // const handleInfiniteScrolls = () => {
  //   if (
  //     window.innerHeight + document.documentElement.scrollTop + 1 >
  //     document.documentElement.scrollHeight
  //   ) {
  //     setCounter((prev) => prev + 1);
  //   }
  // };

  // useEffect(() => {
  //   window.scrollTo(0, 0);
  //   window.addEventListener("scroll", handleInfiniteScrolls);
  //   return () => window.removeEventListener("scroll", handleInfiniteScrolls);
  // }, []);

  useEffect(() => {
    if (detailData) {
      setDetails(detailData.data[0]);
      dispatch(setUser({ ...userInfo, ...detailData.data[0] }));
    }
  }, [detailData]);

  useEffect(() => {
    if (getPost?.data?.status === 1) {
      setAllPosts(getPost?.data?.data);
    }
  }, [getPost?.data]);

  useEffect(() => {
    if (data && data?.status === 1 && data?.data?.length > 0) {
      dispatch(setConnections(data?.data));
    }
  }, [data]);

  const getAllPost = async () => {
    const res = await fetchApi(`posts/get-all-post/0/${page}`, "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      // setAllConnections(res?.data);
      // setDisplayConnections(res?.data);
      setAllPosts(res?.data);
    }
  };

  
  const memberAdd = async (group_id) => {
    let result = await addMember({
      group_id,
      member_id: userId,
    });
    
    if (result?.data?.status === 1) {
      // history.push(`/dashboards/app/group-detail/${group_id}`);
      // if (groupData && groupData.status === 1) {
        // setAllGroup(data?.data)
        const notJoinedGroup = group.filter(item=>
        item._id !=group_id
        )
  
        setGroup(notJoinedGroup);
  };
  }
  useEffect(() => {
    getAllPost();
  }, [page]);

  const handleInfiniteScroll = () => {
    if (window.innerHeight + document.documentElement.scrollTop + 1 > document.documentElement.scrollHeight) {
      setPage((prev) => prev + 1)
      setCounter(prev=>prev+1)
    }
  }

  useEffect(() => {
    window.scrollTo(0, 0)
    window.addEventListener("scroll", handleInfiniteScroll)
    return () => window.removeEventListener("scroll", handleInfiniteScroll)
  }, [])

  const getAllPeopleYouMayKnow = async () => {
    const res = await fetchApi("users/get-users", "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      // setAllConnections(res?.data);
      // setDisplayConnections(res?.data);
      setAllDisplayedUsers(res?.data);
    }
    // console.log("fetchApi",res,res?.data)
  };

  const getAllGroups = async () => {
    const res = await fetchApi("users/get-users", "GET");
    if (res && res?.status === 1 && res?.data?.length > 0) {
      // setAllConnections(res?.data);
      // setDisplayConnections(res?.data);
      // setAllDisplayedUsers(res?.data);
    }
    // console.log("fetchApi",res,res?.data)
  };

  useEffect(() => {
    getAllPeopleYouMayKnow();
    getAllGroups()
  }, []);
  const handleTextChange = (event) => {
    setText(event.target.value);
  };

  const fetchUrlDetails = async (url) => {
    const result = await getUrlMeta({
      url: url,
    });
    if (result?.data?.status === 1 && result?.data?.data) {
      setUrlDetails(result?.data?.data);
    }
  };

  const uploadMediaHandle = (fileData) => {
    setFile(fileData);
  };

  const handleSentInvite = async (id) => {
    const res = await createConnection({
      user_id: id,
    });
    if (res?.data?.status === 1) {
      let newList = allDisplayedUsers?.filter((val) => val._id != id);
      setAllDisplayedUsers(newList);
    }
  };

  const handleComment = async (id, comment, commentFile) => {
    let payload = {
      post_id: id,
      comment_text: comment,
      parent_comment: "",
    }
    console.log("comms",id,comment)

    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }


    const result = await commentPost(payload);
    console.log('commsw',result)
    if (result?.data?.status === 1) {
      getPost.refetch();
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
    }
  };

  const handleReply = async (comment_id, post_id, text, commentFile) => {
    let payload = {
      parent_comment: comment_id,
      post_id: post_id,
      comment_text: text,
    }

    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }
    const result = await commentPost(payload);
    if (result?.data?.status === 1) {
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
      getPost.refetch();
    }
  };

  const handleLike = async (id) => {
    const result = await likePost({
      post_id: id,
    });
  };

  const handleCommentLike = async (post_id, comment_id) => {
    const result = await likeComment({
      post_id: post_id,
      comment_id: comment_id,
    });
  };

  const handleSubmit = async () => {
    setLoading(true);
    const data= Math.floor(Math.random()*900)+100+ String.fromCharCode(97+Math.floor(Math.random()*26))+String.fromCharCode(97+Math.floor(Math.random()*26))
    let multerData = new FormData();
    multerData.append("file", file);
    let textEditorData1;
if(!textEditorData){
 textEditorData1={
    entityMap: {},
    blocks:[
      { 
        key: data,
        text: " ",
        type:"unstyled",
        depth:0,
        inlineStyleRanges:[],
        entityRanges: [],
        data: {}
      }
    ]
  };
}
    let payload;
    let multerPayload = {
      multerData: multerData,
      type: myParams?.group_id ? "group" : "main",
    };
    if (myParams?.group_id) {
      multerPayload.group_id = myParams?.group_id;
    }
    let url_meta_data = {};
    if (urlDetails) {
      url_meta_data = { ...urlDetails };
    }
    if (file) {
      const feedRes = await feedUpload(multerPayload);
      if(feedRes?.data?.status === 1){
        let feedUploadData = feedRes?.data?.data 
        payload = {
          // plain_text: text,
          // text_editor_data: textEditorData,
          text_editor_data: textEditorData || textEditorData1,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: file?.type,
          file_details: feedUploadData
        };
      }else{
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error || "ERROR in File upload"
          , {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          theme: "colored",
          type: "error",
        });
        setLoading(false);

        return
      }
    
    } else {
      payload = {
        // plain_text: text,
        text_editor_data: textEditorData,
      };
    }
    
     const result = await createPost({
      ...payload,
      url_meta_data: url_meta_data,
    });
    if (result?.data?.status === 1) {
      getPost.refetch();
      // getAllPost()
      setFile(null);
      handleClose();
      setLoading(false);
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
    } else {
      // console.log(result?.message,"mee");
      getPost.refetch();
      setFile(null);
      handleClose();
      setLoading(false);
      toast(result?.error?.data?.message || "Post failed", {
        position: "top-right",
        autoClose: 2000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "error",
      });
    }
  };

  const handleDisable = async (id, postDetail) => {
    Swal.fire({
      title: "Are you sure you want to disable this Post?",
      // text: "You will not be able to recover this Post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, disable it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let studentId =
          userInfo?.user_type === "family" &&
            userInfo?.relation
              ?.map((userdetails) => userdetails._id)
              ?.includes(postDetail?.created_by?.ref)
            ? postDetail?.created_by?.ref
            : "";
        let isAdmin=userInfo?.user_type === "admin"

        let result = await deletePost({ id, studentId, isAdmin });
        if (result.data.status === 1) {
          getPost.refetch();
          toast("Post disable successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Post is safe!", "error");
      }
    });

  };

  const handleDelete = async (id, postDetail) => {
    Swal.fire({
      title: "Are you sure you want to delete this Post?",
      text: "You will not be able to recover this Post!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let studentId =
          userInfo?.user_type === "family" &&
            userInfo?.relation
              ?.map((userdetails) => userdetails._id)
              ?.includes(postDetail?.created_by?.ref)
            ? postDetail?.created_by?.ref
            : "";
        let isAdmin=userInfo?.user_type === "admin"

        let result = await deletePost({ id, studentId, isAdmin });
        if (result.data.status === 1) {
          getPost.refetch();
          toast("Post deleted successfully", {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success"
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Post is safe!", "error");
      }
    });

  };

  const deleteComment = async (comment_id, post_id) => {
    Swal.fire({
      title: "Are you sure you want to delete this Comment?",
      text: "You will not be able to recover this Comment!",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Yes, delete it!",
      cancelButtonText: "No, keep it",
    }).then(async (result) => {
      if (result.isConfirmed) {
        let result = await delComment({
          comment_id: comment_id,
          post_id: post_id,
        });
        if (result.data.status === 1) {
          getPost.refetch();
          toast(result?.data?.message, {
            position: "top-right",
            autoClose: 1500,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "success",
          });
        }
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire("Cancelled", "Your Comment is safe!", "error");
      }
    })

  };

  const editComment = async (comment_id, post_id, comment,commentFile) => {
    let payload = {
      post_id: post_id,
      comment_text: comment,
      parent_comment: "",
      comment_id:comment_id
    }

    if (commentFile) {
      let multerData = new FormData();
    multerData.append("file", commentFile);
      const feedRes = await commentUpload(multerData);
      if (feedRes?.data?.status === 1) {
        payload = {
          ...payload,
          file_id: feedRes?.data?.data?._id,
          file_url: feedRes?.data?.data?.file_url,
          file_type: commentFile?.type,
          file_details: feedRes?.data?.data
        };
      } else {
        console.log("ERROR in File upload");
        toast(feedRes?.error?.error
          , {
            position: "top-right",
            autoClose: 3000,
            hideProgressBar: false,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            theme: "colored",
            type: "error",
          });
        setLoading(false);

        return;
      }
    }


    const result = await updateComment(payload);
    if (result?.data?.status === 1) {
      getPost.refetch();
      toast(result?.data?.message, {
        position: "top-right",
        autoClose: 1500,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        theme: "colored",
        type: "success",
      });
    }
  };

  const handleSeeAllPeople = ()=>{
    history.push('/dashboards/app/people-you-may-know')
  }
  const handleSeeAllGroup =()=>{
    history.push('/dashboards/app/groups')

  }
  return (
      <Container>
      <Row>
          <Col lg={8} className="m-0 p-0">
            <Col sm={12}>
              <Card
                id="post-modal-data"
                className="card-block card-stretch card-height"
              >
                <Card.Body>
                  <div className="d-flex align-items-center">
                    <Link to="/dashboard/app/profile">
                      <div className="user-img">
                        <img
                          src={
                            detail?.profile_picture
                              ? detail?.profile_picture
                              : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                          }
                          alt="user1"
                          className="avatar-50 rounded-circle"
                        />
                      </div>
                    </Link>
                    <form
                      className="post-text ms-3 w-100 "
                      onClick={handleShow}
                    >
                      <input
                        type="text"
                        className="form-control rounded"
                        placeholder="Write something here..."
                        style={{ border: "none" }}
                      />
                    </form>
                  </div>
                  <hr />
                </Card.Body>
                <Modal
                  size="lg"
                  className=" fade"
                  id="post-modal"
                  onHide={handleClose}
                  show={show}
                >
                  <Modal.Header className="d-flex justify-content-end">
                    {/* <Modal.Title id="post-modalLabel">Create Post</Modal.Title> */}
                    <Link to="#" className="lh-1" onClick={handleClose}>
                      <span className="material-symbols-outlined">close</span>
                    </Link>
                  </Modal.Header>
                  <Modal.Body>
                    <div className="d-flex align-items-center">
                      <div
                        className="user-img"
                        style={{ width: "60px", height: "60px" }}
                      >
                        <img
                          src={
                            detail?.profile_picture
                              ? detail?.profile_picture
                              : "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"
                          }
                          alt="user1"
                          className="avatar-50 rounded-circle "
                        />
                      </div>
                      <form
                        className="post-text ms-3 w-100 "
                        data-bs-toggle="modal"
                        data-bs-target="#post-modal"
                      >
                        <TextEditor
                          textEditorData={textEditorData}
                          onChange={setTextEditorData}
                          urlDetails={urlDetails}
                          fetchUrlDetails={fetchUrlDetails}
                        />
                        {/* <input
                          type="text"
                          className="form-control rounded"
                          onChange={handleTextChange}
                          placeholder="Write something here..."
                          style={{ border: "none" }}
                        /> */}
                      </form>
                    </div>
                    <hr />
                    {loading ? <Loading /> : <>
                      <ul className="d-flex flex-column  list-inline m-0 p-0">
                        <li className="col-md-6 mb-3">
                          <div
                            className="bg-soft-primary rounded p-2 pointer me-3"
                            style={{ textAlign: "center" }}
                          >
                            <label className="shareOption">
                              <span style={{ cursor: "pointer", fontWeight: "600", color: "#000" }}>
                              Choose Photo, Video or Document
                              </span>

                              <input
                                style={{ display: "none" }}
                                type="file"
                                id="file"
                                //  accept=".png,.jpeg,.jpg"
                                onChange={(e) => {
                                  uploadMediaHandle(e.target.files[0]);
                                }}
                              />
                            </label>
                          </div>
                        </li>
                        <RenderFile file={file} filetype={"filetype"} />
                      </ul>
                      <hr />
                      <button
                        type="submit"
                        className="btn btn-primary d-block w-100 mt-3"
                        onClick={handleSubmit}
                      >
                        Post
                      </button>
                    </>
                    }
                  </Modal.Body>
                </Modal>
              </Card>
            </Col>
            {allPosts?.map((val) => (
              <FeedBlock
                postDetails={val}
                handleLike={handleLike}
                handleComment={handleComment}
                handleCommentLike={handleCommentLike}
                handleReply={handleReply}
                getPost={getPost}
                handleDelete={handleDelete}
                deleteComment={deleteComment}
                editComment={editComment}
                page={page}
                handleDisable={handleDisable}
              />
            ))}
          </Col>
          <Col lg={4}>
            <Card>
              <div className="card-header d-flex justify-content-between">
                <div className="header-title">
                  <h3 className="card-title"
                   style={{
                    fontWeight: "600",
                }}
                  >People You May Know</h3>
                </div>
              </div>
              <Card.Body>
                <ul className="media-story list-inline m-0 p-0">
                  {allDisplayedUsers?.slice(0,members)?.map((val, index) => { 
                    var userType = "family"
                    if(val?.user_type === "family"){
                      userType="Family/Caregiver"
                    }else if(val?.user_type === "agency"){
                      userType = "Agency/Community partner"
                    }else{
                      userType= val?.user_type
                    }                   
                    return (
                      <li className="d-flex mb-3  active">
                        <Link to={`/dashboard/app/profile-view/${val._id}`} style={{flex:1}}>
                          <img
                            // style={{ height: "40px", width: "40px" }}
                            src={val.profile_picture ? val.profile_picture : s2}
                            alt="story-img"
                            className="rounded-circle img-fluid"
                          />
                        </Link>
                        <div className=" d-flex justify-content-between" style={{flex:3}} >
                          <div style={{flex:3}}><Link to={`/dashboard/app/profile-view/${val._id}`}>
                            <h6 className="profile-name"
                             style={{
                              fontWeight: "600",
                          }}
                            >{`${val.first_name}${" "}${val.last_name}`} </h6>
                          </Link>
                         <p 
                           style={{
                            fontSize: "12px",
                            marginBottom: "2px",
                            textTransform: "capitalize",
                        }}
                          >{userType}</p>
                       </div>
                          <div style={{cursor:"pointer"}} 
                            onClick={() => handleSentInvite(val._id)}
                            className="d-flex justify-content-center align-items-center "
                          
                          >
                            {/* <button
                            type="submit"
                            className="p-0"
                            style={{ padding: "2px 10px",
                            // lineHeight: "16px",
                            fontSize: "2px",
                            fontWeight: "60",
                           }}
                          > */}
                            {/* Connect */}
                       {/* <i className="ri-user-add-line" style={{fontSize:'30px',margin:"0",padding:0,color:'#173f5f'}}></i> */}
                       {/* <i className="ri-add-circle-fill" style={{fontSize:'30px',margin:"0",padding:0,color:'#173f5f'}}></i> */}
                      <img src={PlusMinus} style={{height:'25px',width:'25px'}} />
                           
                          {/* </button> */}
                          
                          
                          </div>
                          </div>
                      </li>
                    );
                  })}
                </ul>
                {allDisplayedUsers?.length>0 && <div className="d-flex justify-content-center align-items-center">
                  <button onClick={members==5 ? ()=>setMembers(10) : handleSeeAllPeople } className="btn btn-primary  mt-1 mb-0 d-block">
                  {members==5? "View More":"See All"}  
                </button></div>}
              </Card.Body>
            </Card>
            
           <div  style={{position:'sticky', top:77}}> 
           <Card>
              <div className="card-header d-flex justify-content-between" >
                <div className="header-title">
                  <h4 className="card-title"
                   style={{
                    fontWeight: "600",
                }}
                  >Groups</h4>
                </div>
              </div>
              <Card.Body>
              <ul className="media-story list-inline m-0 p-0 media-scroll">
                {group?.slice(0,groupLimit)?.map((val, index) => { 
                  var userType = "family"
                  if(val?.user_type === "family"){
                    userType="Family/Caregiver"
                  }else if(val?.user_type === "agency"){
                    userType = "Agency/Community partner"
                  }else{
                    userType= val?.user_type
                  }                  
                  return (
                    <li className="d-flex mb-3 active">
                      <Link to={`/dashboard/app/profile-view/${val._id}`} style={{flex:1}}>
                        <img
                          style={{ height: "40px", width: "40px" }}
                          src={val.group_image ? val.group_image : s2}
                          alt="story-img"
                          className="rounded-circle img-fluid"
                        />
                      </Link>
                      <div className="d-flex" style={{flex:3}}>
                        <div  style={{flex:3}}><Link to={`/dashboard/app/profile-view/${val._id}`}>
                          <h6
                           style={{
                            fontWeight: "600",
                        }}
                          >{val.title}</h6>
                        </Link>
                        <p
                         style={{
                          fontSize: "12px",
                          marginBottom: "2px",
                          textTransform: "capitalize",
                      }}
                        >{val.is_public ? 'Public' :'Private'} </p></div>
                        <div  style={{flex:1}} className="d-flex align-items-center justify-content-center"><button
                          type="submit"
                          className="btn btn-primary  w-100 sendInvite"
                          style={{ padding: "2px 10px",
                          // lineHeight: "16px",
                          fontSize: "12px",
                          fontWeight: "600",
                         }}
                         onClick={() => memberAdd(val._id)}
                         >
                          Join
                        </button></div>
                      </div>
                    </li>
                  );
                })}
              </ul>
              {group?.length>0 && <div className="d-flex justify-content-center align-items-center">
                <button onClick={groupLimit==5 ? ()=>setGroupLimit(10) : handleSeeAllGroup} className="btn btn-primary  mt-1 mb-0 d-block">
                {groupLimit==5? "View More":"See All"}  
              </button></div>}
            </Card.Body>
            </Card>
            </div>
          </Col>
        </Row>
      </Container>
  );
};

export default Index;
