import React from "react";
import { Container, Row, Card, Col } from "react-bootstrap";
import user05 from "../../../assets/images/user/05.jpg";
import { Link, useParams, useHistory } from "react-router-dom";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useGetSearchQuery } from "../../../store/globalSearch/globalSearchApiSlice";
import { useCreateConnectionMutation, useGetConnectionQuery, useGetInvitationsQuery } from "../../../store/connection/connectionApiSlice";
import PeoplesCard from "./PeoplesCard";
import config from "../../../store/config.json"

const SearchResultPage = () => {
  const history = useHistory()
  const [createConnection] = useCreateConnectionMutation();
  const connectionData = useGetConnectionQuery();
  const invitationData = useGetInvitationsQuery()
  const [searchRes, setSearchRes] = useState("");
  const [connection, setConnection] = useState([])
  const [invitation, setInvitation] = useState([]);
  const [invitedUser, setInvitedUser] = useState([])
  const { searchText } = useParams();

  const data = useGetSearchQuery(searchText);
  const userInfo = useSelector((state) => state.user.userDetails);
  let userId = userInfo?._id;
  useEffect(() => {

    if (data && data?.data?.status === 1) {
      setSearchRes(data?.data?.data);
    }
  }, [data]);

  useEffect(() => {
    if (connectionData && connectionData?.data?.status === 1) {
      setConnection(connectionData?.data?.data)
    }
  }, [connectionData]);

  useEffect(() => {
    if (invitationData && invitationData?.data?.status === 1) {
      setInvitation(invitationData?.data?.data?.data)
    }
  }, [invitationData]);

  const getInvitationByUser = () => {
    fetch(
      `${config.WEBPORTAL_URL}/api/connections/get-invitation-byId`,
      {
        method: "GET",
        headers: {
          authorization: `Bearer ${localStorage.getItem("token")}`,
          "content-type": "application/json",
        },
      }
    )
      .then((response) => {
        if (response.ok) {
          response.json().then((data) => {
            setInvitedUser(data?.data?.data)
          });
        } else {
        }
      })
      .catch((error) => { });
  };

  useEffect(() => {
    getInvitationByUser()
  }, [])

  console.log("user",invitedUser)

  return (
    <>
      {searchRes?.users?.length > 0 && (
        <Container style={{ borderRadius: "5px" }}>
          <h4 className="grp-text mb-2">People</h4>
          <Row className="d-flex">
            {searchRes?.users?.map((item) => {
              var match = false;
              var invitationMatch = false;
              var invited = false;
              return (
               <PeoplesCard 
               item={item}
               connection={connection}
               invitation={invitation}
               userId={userId}
               invitationMatch={invitationMatch}
               createConnection={createConnection}
               match={match}
               invitedUser={invitedUser}
               invited={invited}
               getInvitationByUser={getInvitationByUser}
               />
              );
            })}
          </Row>
        </Container>
      )}

      {searchRes?.groups?.length > 0 && (
        <Container style={{ borderRadius: "5px" }}>
           <h4 className="grp-text mb-2">Groups</h4>
          <Row className="d-flex">
            {searchRes?.groups?.map((item) => {
              return (
                <Col lg={6} md={6}>
                  <Card style={{ boxShadow: "6px 6px 5px #e5e5e5" }}>
                    <Card.Body>
                      <div className="d-flex align-items-center justify-content-between">
                        <Link to={`/dashboards/app/group-detail/${item._id}`}>
                          <div className="">
                            <img
                              src={item?.group_image || "https://jbpbucket-dev.s3.amazonaws.com/devjanbask/profile_pic/f65d1d56-5887-4abb-b4f1-a3100975g718.jpg"}
                              alt="story-img"
                              className="rounded-circle avatar-40"
                            />
                          </div>
                        </Link>
                        <Link to={`/dashboards/app/group-detail/${item._id}`}>
                          <div className="">
                            <h6>{item?.title}</h6>
                          </div>
                        </Link>
                        <Link to={`/dashboards/app/group-detail/${item._id}`}>
                          <div className=" btn btn-primary rounded confirm-btn">
                            View
                          </div>
                        </Link>
                      </div>
                    </Card.Body>
                  </Card>
                </Col>
              );
            })}
          </Row>
        </Container>
      )}
    </>
  );
};

export default SearchResultPage;
