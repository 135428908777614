import React from 'react'
import { Container,Row,Col } from 'react-bootstrap';
import {Link} from 'react-router-dom';

const TermConditions = () => {
  return (
    <Container>
      <Row>
        <Col lg={12} >
    <div  className="" style={{marginLeft:"20%"}}>
      <h2 style={{marginTop:"4vh" , marginLeft:"5vh"}}>Term and Conditions:-</h2>
      <h4 style={{marginLeft:"5vh",marginTop:"2vh"}}>User Agreement</h4>
      
       <ul className='mt-2' style={{height:"",overflow:"",overflowY:"",width:""}}>
        <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>Sed id diam et nisl lacinia feugiat et nec lacus.</li> 
          <li>Etiam a mauris non est pretium hendrerit a non nunc.</li>
          <li>Quisque consequat mauris et facilisis tristique.</li>
          <li>In vel neque id tellus finibus suscipit.</li>
          <li>Sed viverra augue nec malesuada tincidunt.</li>
          <li>Nullam ac dolor vitae sapien venenatis sagittis vitae nec sem.</li>
          <li>Suspendisse ac sapien volutpat, euismod ante vel, rhoncus tellus.</li>
          <li>Sed ac nisi pretium, venenatis dui nec, consectetur dolor.</li>
          <li>In consequat quam ac dui condimentum vestibulum.</li>
          <li>Vestibulum nec enim volutpat, finibus erat at, imperdiet sem.</li>
          <li>Etiam a mauris non est pretium hendrerit a non nunc.</li>
          <li>Quisque consequat mauris et facilisis tristique.</li>
          <li>In vel neque id tellus finibus suscipit.</li>
          <li>Sed viverra augue nec malesuada tincidunt.</li>
          <li>Nullam ac dolor vitae sapien venenatis sagittis </li>
          <li>Sed ac nisi pretium, venenatis dui nec, consectetur dolor.</li>
          <li>In consequat quam ac dui condimentum vestibulum.</li>
          <li>Vestibulum nec enim volutpat, finibus erat at, imperdiet sem.</li>
          <li>Etiam a mauris non est pretium hendrerit a non nunc.</li>
          <li>Quisque consequat mauris et facilisis tristique.</li>
          <li>In vel neque id tellus finibus suscipit.</li>
          <li>Sed viverra augue nec malesuada tincidunt.</li>
          <li>Nullam ac dolor vitae sapien venenatis sagittis </li>
          <li>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</li>
          <li>Sed id diam et nisl lacinia feugiat et nec lacus.</li> 
          <li>Etiam a mauris non est pretium hendrerit a non nunc.</li>
          <li>Quisque consequat mauris et facilisis tristique.</li>
          <li>In vel neque id tellus finibus suscipit.</li>
          <li>Sed viverra augue nec malesuada tincidunt.</li>
          <li>Nullam ac dolor vitae sapien venenatis sagittis vitae nec sem.</li>
          <li>Suspendisse ac sapien volutpat, euismod ante vel, rhoncus tellus.</li>
          <li>Sed ac nisi pretium, venenatis dui nec, consectetur dolor.</li>
          <li>In consequat quam ac dui condimentum vestibulum.</li>
          <li>Vestibulum nec enim volutpat, finibus erat at, imperdiet sem.</li>
          <li>Etiam a mauris non est pretium hendrerit a non nunc.</li>
          <li>Quisque consequat mauris et facilisis tristique.</li>
          <li>In vel neque id tellus finibus suscipit.</li>
          <li>Sed viverra augue nec malesuada tincidunt.</li>
          <li>Nullam ac dolor vitae sapien venenatis sagittis </li>
          <li>Sed ac nisi pretium, venenatis dui nec, consectetur dolor.</li>
          <li>In consequat quam ac dui condimentum vestibulum.</li>
          <li>Vestibulum nec enim volutpat, finibus erat at, imperdiet sem.</li>
          <li>Etiam a mauris non est pretium hendrerit a non nunc.</li>
          <li>Quisque consequat mauris et facilisis tristique.</li>
          <li>In vel neque id tellus finibus suscipit.</li>
          <li>Sed viverra augue nec malesuada tincidunt.</li>
          <li>Nullam ac dolor vitae sapien venenatis sagittis </li>
       </ul>
       
       <div style={{marginLeft:"4vh", marginTop:"3vh",marginBottom:"3vh"}}><Link to="/auth/sign-up"><button className='btn btn-primary'>Back</button></Link></div>
    </div>
    </Col>
    </Row>
    </Container>
  )
}

export default TermConditions