import React, { useState, useRef, useEffect } from "react";
import {
  Container,
  Row,
  Col,
  Card,
  Tab,
  Form,
  Button,
  Nav,
} from "react-bootstrap";
import { useSelector } from "react-redux";
import { Link, useHistory, useParams } from "react-router-dom";

//image
import img1 from "../../assets/images/user/11.png";
import { useUpdateuserListByAdminMutation } from "../../store/userList/userListByAdminApiSlice";

const ProfileEditByAdmin = () => {
  const { i } = useParams();
  const userList = useSelector((state) => state.userList);
  const [updateUser] = useUpdateuserListByAdminMutation()

  // const [user,setUser] = useState(userList ? userList.userList[i] : {});
  // const [userData, setUserData] = useState(
  //   userList ? userList.userList[i] : {}
  // );

  const [userData, setUserData] = useState([])

  const inputRef = useRef(null);

  const history = useHistory();

  const handleChange = (value) => {
    setUserData({
      ...userData,
      [value.target.id]: value.target.value,
      ["user_id"]: i,
    });
  };

  useEffect(() => {
    userList.userList?.filter((item) => {
      if (item._id === i) {
        setUserData(item)
      }
    })
  }, [userList]);

  const updateUserdetail = async () => {
    let result = await updateUser(userData)
    if (result.data.status === 1) {
      setTimeout(() => {
        history.push('/dashboard/app/userList')
      }, 1200)
    }
  };

  return (
    <>
      <Container>
        <Tab.Container defaultActiveKey="first">
          <Row>
            <Col lg={12}>
              {/* <div className="iq-edit-list-data"> */}
              <Tab.Content>
                <Tab.Pane eventKey="first" className="fade show">
                  <Card>
                    <Card.Header className="d-flex justify-content-between">
                      <div className="header-title">
                        <h4 className="card-title">Edit User Detail</h4>
                      </div>
                    </Card.Header>
                    <Card.Body>
                      <Form>
                        <Form.Group className="form-group align-items-center">
                          <Col md="12">
                            <div className="profile-img-edit">
                              <img
                                className="profile-pic"
                                src={
                                  userData.profile_picture
                                    ? userData.profile_picture
                                    : img1
                                }
                                alt="profile-pic"
                              />
                              {/* <div className="p-image">
                                <i
                                  className="ri-pencil-line upload-button text-white"
                                  onClick={() => inputRef.current?.click()}
                                ></i>
                                <input
                                  className="file-upload"
                                  type="file"
                                  ref={inputRef}
                                  accept="image/*"
                                  onChange={(event) => handleUpload(event)}
                                />
                              </div> */}
                            </div>
                          </Col>
                        </Form.Group>
                        <Row className="align-items-center">
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label
                              htmlFor="first_name"
                              className="form-label"
                            >
                              First Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="first_name"
                              value={userData?.first_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label
                              htmlFor="last_name"
                              className="form-label"
                            >
                              Last Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="last_name"
                              value={userData?.last_name}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label htmlFor="uname" className="form-label">
                              User Name:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="uname"
                              placeholder={userData?.first_name + " " + userData?.last_name}
                              disabled
                            />
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6">
                            <Form.Label htmlFor="email" className="form-label">
                              Email:
                            </Form.Label>
                            <Form.Control
                              type="text"
                              className="form-control"
                              id="email"
                              placeholder={userData?.email}
                              onChange={handleChange}
                              disabled
                            />
                          </Form.Group>

                          {/* <Form.Group className="form-group col-sm-6">
                            <Form.Label htmlFor="phone" className="form-label">
                              Phone
                            </Form.Label>
                            <Form.Control
                              className="form-control"
                              id="phone"
                              placeholder={userData?.phone}
                              onChange={handleChange}
                            />
                          </Form.Group> */}

                          <Form.Group className="form-group col-sm-6">
                            <Form.Label className="form-label">
                              User Role:
                            </Form.Label>
                            <Form.Select
                              className="form-select"
                              aria-label="Default select example 2"
                              id="user_type"
                              onChange={handleChange}
                              disabled={userData?.user_type === "student" || userData?.user_type === "family"}
                              value={userData?.user_type}
                            >
                              {userData?.user_type === "student" || userData?.user_type === "family" ? <><option value="student">Student</option>
                                <option value="family">Family/Caregiver</option></> : ""}
                              <option value="educator">Educator</option>
                              <option value="agency">Agency/Community Partner</option>
                              <option value="admin">Admin</option>
                            </Form.Select>
                          </Form.Group>
                          <Form.Group className="form-group col-sm-6 ">
                            <Form.Label htmlFor="location" className="form-label">
                              Location/Region
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              className="form-control"
                              id="location"
                              value={userData?.location}
                              onChange={handleChange}
                            />
                          </Form.Group>
                          {userData?.user_type === "educator" || userData?.user_type === "agency" ? <Form.Group className="form-group col-sm-6 ">
                            <Form.Label htmlFor="work" className="form-label">
                              Work
                            </Form.Label>
                            <Form.Control
                              as="textarea"
                              className="form-control"
                              id="work"
                              value={userData?.work}
                              onChange={handleChange}
                            />
                          </Form.Group> : ""}
                        </Row>
                        <Button
                          className="btn btn-primary me-2"
                          onClick={updateUserdetail}
                        >
                          Submit
                        </Button>
                        <Link to="/dashboard/app/userList">
                          <Button
                            type="reset"
                            className="btn btn-secondry me-1"
                          >
                            Cancel
                          </Button>
                        </Link>
                      </Form>
                    </Card.Body>
                  </Card>
                </Tab.Pane>
              </Tab.Content>
              {/* </div> */}
            </Col>
          </Row>
        </Tab.Container>
      </Container>
    </>
  );
};

export default ProfileEditByAdmin;
