import React from 'react'
import Index from '../views/index'
import { Switch, Route } from 'react-router-dom'
import userProfile from '../views/app/UserProfile';
import UserPrivacySetting from '../views/app/UserPrivacySetting';
import FriendProfile from '../views/app/FriendProfile';
import ProfileBadges from '../views/app/ProfileBadges';
import ProfileForums from '../views/app/ProfileForum';
import Chat from '../views/app/Chat';
import Notification from '../views/app/Notification';
import Activity from '../views/app/Activity';
import File from '../views/app/File'
import FriendRequest from '../views/app/invitation/InvitationList'
import Todo from '../views/app/Todo';
import UserAccountSetting from '../views/app/UserAccountSetting';
import UserProfileEdit from '../views/app/UserProfileEdit';
import Remixicon from '../views/icons/IconRemixicon';
import Lineawesome from '../views/icons/IconLineawesome';
import Fontawesome from '../views/icons/IconFontawesome5';
import Material from '../views/icons/IconMaterial';
import FormElement from '../views/from/FormElement';
import FormValidation from '../views/from/FormValidation';
import FormSwitch from '../views/from/FormSwitch';
import FormWizard from '../views/from/FormWizard';
import FormWizardValidate from '../views/from/FormWizardValidate';
import FormWizardVertical from '../views/from/FormWizardVertical';
import FormCheckbox from '../views/from/FormCheckbox';
import FormRadio from '../views/from/FormRadio';
import DataTable from '../views/table/DataTable';
import TableBasic from '../views/table/TablesBasic';
import TableEditable from '../views/table/TableEditable';
import { TransitionGroup, CSSTransition } from "react-transition-group";
import BlogGrid from '../views/blog/BlogGrid'
import BlogList from '../views/blog/BlogList';
import BlogDetail from '../views/blog/BlogDetail'
import Email from '../views/email/Email';
import EmailCompose from '../views/email/EmailCompose';
import UiAlerts from '../views/ui-kit/Alerts';
import UiBadges from '../views/ui-kit/Badges';
import UiBreadcrumbs from '../views/ui-kit/Breadcrumb';
import UiButtons from '../views/ui-kit/Buttons';
import UiCards from '../views/ui-kit/Cards';
import UiCarousels from '../views/ui-kit/Carousel';
import UiColors from '../views/ui-kit/Color';
import UiDropdowns from '../views/ui-kit/Dropdowns';
import UiEmbedVideos from '../views/ui-kit/EmbedVideo';
import UiGrids from '../views/ui-kit/Grid';
import UiImages from '../views/ui-kit/Images';
import UiListGroups from '../views/ui-kit/ListGroups';
import UiModals from '../views/ui-kit/Modal';
import UiNotifications from '../views/ui-kit/Notifications';
import UiOffcanvas from '../views/ui-kit/Offcanvas';
import UiPaginations from '../views/ui-kit/Pagination';
import UiPopovers from '../views/ui-kit/Popovers';
import UiProgressbars from '../views/ui-kit/Progressbars';
import UiTabs from '../views/ui-kit/Tabs';
import UiTooltips from '../views/ui-kit/Tooltips';
import UiTypographys from '../views/ui-kit/Typography';
import Pricing from '../views/extrapages/Pricing';
import Pricing1 from '../views/extrapages/Pricing1';
import Timeline from '../views/extrapages/Timeline';
import Invoice from '../views/extrapages/Invoice';
import Faq from '../views/extrapages/Faq'
import PrivacyPolicy from '../views/extrapages/PrivacyPolicy';
import TermsofService from '../views/extrapages/TermsOfService';
import BlankPage from '../views/extrapages/BlankPage';
import Admin from '../views/app/Admin';
import UserList from '../views/app/UserList';
import UserList2 from '../views/app/UserList2';
import ProfileEditByAdmin from '../views/app/ProfileEditByAdmin';
import ProfileViewByAdmin from '../views/app/ProfileViewByAdmin';
import SearchResultPage from '../views/app/searchResult/SearchResultPage';
import Events from '../views/app/EventComponent';


const DefaultRouter = () => {
    return (
        <TransitionGroup>
            <CSSTransition classNames="fadeUp" timeout={300}>
                <Switch>
                    <Route path="/dashboard" exact component={Index} />
                    <Route path="/dashboard/app/search-result-page/:searchText" component={SearchResultPage}/>
                    <Route path="/dashboard/app/profile" component={userProfile} />
                    <Route path="/dashboard/app/activity" component={Activity} />
                    <Route path="/dashboard/app/user-privacy-setting" component={UserPrivacySetting} />
                    <Route path="/dashboard/app/friend-profile" component={FriendProfile} />
                    <Route path="/dashboard/app/profile-badges" component={ProfileBadges} />
                    <Route path="/dashboard/app/profile-forum" component={ProfileForums} />
                    <Route path="/dashboard/app/userList" component={UserList2} />
                    <Route path="/dashboard/app/userList2" component={UserList2} />
                    <Route path="/dashboard/app/profile-edit-by-admin/:i" component={ProfileEditByAdmin} />
                    <Route path="/dashboard/app/profile-view/:i" component={ProfileViewByAdmin} />
                    <Route path="/dashboard/app/chat" component={Chat} />
                    <Route path="/dashboard/app/notification" component={Notification} />
                    <Route path="/dashboard/app/event" component={Events} />
                    <Route path="/dashboard/app/file" component={File} />
                    <Route path="/dashboard/app/friend-request" component={FriendRequest} />
                    <Route path="/dashboard/app/todo" component={Todo} />
                    <Route path="/dashboard/app/user-account-setting" component={UserAccountSetting} />
                    <Route path="/dashboard/app/user-profile-edit" component={UserProfileEdit} />

                    <Route path="/dashboard/icon/fontawesome-5" component={Fontawesome} />
                    <Route path="/dashboard/icon/remixicon" component={Remixicon} />
                    <Route path="/dashboard/icon/lineawesome" component={Lineawesome} />
                    <Route path="/dashboard/icon/material" component={Material} />

                    <Route path="/dashboard/form/form-element" component={FormElement} />
                    <Route path="/dashboard/form/form-validation" component={FormValidation} />
                    <Route path="/dashboard/form/form-switch" component={FormSwitch} />
                    <Route path="/dashboard/form/form-wizard" component={FormWizard} />
                    <Route path="/dashboard/form/form-wizard-validate" component={FormWizardValidate} />
                    <Route path="/dashboard/form/form-wizard-vertical" component={FormWizardVertical} />
                    <Route path="/dashboard/form/form-checkbox" component={FormCheckbox} />
                    <Route path="/dashboard/form/form-radio" component={FormRadio} />

                    <Route path="/dashboard/table/data-table" component={DataTable} />
                    <Route path="/dashboard/table/tables-basic" component={TableBasic} />
                    <Route path="/dashboard/table/table-editable" component={TableEditable} />

                    <Route path="/dashboard/blog/blog-grid" component={BlogGrid} />
                    <Route path="/dashboard/blog/blog-list" component={BlogList} />
                    <Route path="/dashboard/blog/blog-detail" component={BlogDetail} />

                    <Route path="/dashboard/Email/email" component={Email} />
                    <Route path="/dashboard/Email/email-compose" component={EmailCompose} />

                    <Route path="/dashboard/ui-kit/ui-alerts" component={UiAlerts} />
                    <Route path="/dashboard/ui-kit/ui-badges" component={UiBadges} />
                    <Route path="/dashboard/ui-kit/ui-breadcrumb" component={UiBreadcrumbs} />
                    <Route path="/dashboard/ui-kit/ui-buttons" component={UiButtons} />
                    <Route path="/dashboard/ui-kit/ui-cards" component={UiCards} />
                    <Route path="/dashboard/ui-kit/ui-carousel" component={UiCarousels} />
                    <Route path="/dashboard/ui-kit/ui-color" component={UiColors} />
                    <Route path="/dashboard/ui-kit/ui-dropdowns" component={UiDropdowns} />
                    <Route path="/dashboard/ui-kit/ui-embed-video" component={UiEmbedVideos} />
                    <Route path="/dashboard/ui-kit/ui-grid" component={UiGrids} />
                    <Route path="/dashboard/ui-kit/ui-images" component={UiImages} />
                    <Route path="/dashboard/ui-kit/ui-list-groups" component={UiListGroups} />
                    <Route path="/dashboard/ui-kit/ui-modal" component={UiModals} />
                    <Route path="/dashboard/ui-kit/ui-notifications" component={UiNotifications} />
                    <Route path="/dashboard/ui-kit/ui-offcanvas" component={UiOffcanvas} />
                    <Route path="/dashboard/ui-kit/ui-pagination" component={UiPaginations} />
                    <Route path="/dashboard/ui-kit/ui-popovers" component={UiPopovers} />
                    <Route path="/dashboard/ui-kit/ui-progressbars" component={UiProgressbars} />
                    <Route path="/dashboard/ui-kit/ui-tabs" component={UiTabs} />
                    <Route path="/dashboard/ui-kit/ui-tooltips" component={UiTooltips} />
                    <Route path="/dashboard/ui-kit/ui-typography" component={UiTypographys} />

                    <Route path="/dashboard/extrapages/pages-pricing" component={Pricing} />
                    <Route path="/dashboard/extrapages/pages-pricing-one" component={Pricing1} />
                    <Route path="/dashboard/extrapages/pages-timeline" component={Timeline} />
                    <Route path="/dashboard/extrapages/pages-invoice" component={Invoice} />
                    <Route path="/dashboard/extrapages/pages-faq" component={Faq} />
                    <Route path="/dashboard/extrapages/privacy-policy" component={PrivacyPolicy} />
                    <Route path="/dashboard/extrapages/terms-of-service" component={TermsofService} />
                    <Route path="/dashboard/extrapages/blankpage" component={BlankPage} />
                    <Route path="/dashboard/app/admin" component={Admin} />

                </Switch>
            </CSSTransition>
        </TransitionGroup>
    )
}

export default DefaultRouter
