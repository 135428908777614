import React, { useState } from 'react'
import { useUpdateStatusMutation } from '../../../store/connection/connectionApiSlice';
import {Link} from 'react-router-dom'
import {
    Navbar,
    Dropdown,
    Nav,
    Form,
    Card,
    Container,
    Image,
    Offcanvas,
    CloseButton,
  } from "react-bootstrap";
import Loading from '../../../views/app/Loading';

const InvitationCard = ({
    user,
    displayInvitations,
    invitationCount,
    setInvitationCount,
    setDisplayInvitations,
    dispatch,
    setUser,
    val,
    invitationData,
    userInfo,
    setShowNtf
}) => {
    const [loading, setLoading] = useState(false);
    const [updateStatus] = useUpdateStatusMutation();

    const handleConfirm = async (id) => {
        setLoading(true);
        const res = await updateStatus({
            id: id,
            status: "active",
        });
        if (res?.data?.status === 1) {
            setLoading(false)
            setShowNtf(false)
            try {
                let newList = displayInvitations.filter((val) => val._id != id);
                setDisplayInvitations(newList);
                // userInfo.invitation_count = userInfo.invitation_count - 1;
                setInvitationCount(invitationCount - 1);
                invitationData?.refetch();
                dispatch(
                    setUser({
                        ...userInfo,
                        invitation_count: userInfo?.invitation_count - 1,
                        connection_count: userInfo.connection_count + 1,
                    })
                );
            } catch (error) {
                console.log(error);
            }
        }else{
            setLoading(false)
            setShowNtf(false)
        }
    };

    const handleReject = async (id) => {
        setLoading(true);
        const res = await updateStatus({
            id: id,
            status: "declined",
        });
        if (res?.data?.status === 1) {
            setLoading(false)
            setShowNtf(false)
            try {
                let newList = displayInvitations.filter((val) => val._id != id);
                setDisplayInvitations(newList);
                // userInfo.invitation_count = userInfo.invitation_count - 1;
                setInvitationCount(invitationCount - 1);
                invitationData?.refetch();
                dispatch(
                    setUser({
                        ...userInfo,
                        invitation_count: userInfo?.invitation_count - 1,
                    })
                );
            } catch (error) {
                console.log(error);
            }
        }else{
            setLoading(false)
            setShowNtf(false)
        }
    };
    return (
        <> 
        {loading ? <div style={{width:"100%",height:"50px"}} className="d-flex justify-content-center align-items-center"><h5>Processing...</h5></div> : <div className="iq-friend-request">
            <div className="iq-sub-card iq-sub-card-big d-flex align-items-center justify-content-between">
               <div className="d-flex align-items-center">
                    <Link to={`/dashboard/app/profile-view/${user.ref}`}>
                        <Image
                            className="avatar-40 rounded"
                            src={user?.profile_picture}
                            alt=""
                            loading="lazy"
                        />
                    </Link>
                    <div className="ms-3">
                        <h6 className="mb-0 ">
                            {user?.username}{" "}
                        </h6>
                        {/* <p className="mb-0">40 friends</p> */}
                    </div>
                </div>
                <div className="d-flex align-items-center">
                    <div
                        onClick={() => handleConfirm(val._id)}
                        className="me-3 btn btn-primary rounded"
                    >
                        Confirm
                    </div>
                    <div
                        onClick={() => handleReject(val._id)}
                        className="me-3 btn btn-secondary rounded"
                    >
                        Reject
                    </div>
                </div> 
            </div>
        </div>}
        </>
    )
}

export default InvitationCard