import React from 'react'

function LikePeopleList({likePeoples}) {
  return (
    <div className="user-list">
            {/* <ul> */}
            {likePeoples.map((user, index) => (
              <div style={{alignItems:"start"}}>{user?.liked_by?.username}</div>

              // <li key={index} 
              // // style={listItemStyle}
              // >
              //   {/* <img src={user?.liked_by
              //     ?.profile_picture} alt={user?.liked_by
              //       ?.username} 
              //       // style={avatarStyle} 
              //       /> */}
              //   <div style={{ flex: 1 }}>
              //  { user?.liked_by
              //       ?.username}
              //     {/* <h3 style={{ fontSize: '1.2rem', margin: 0 }}>{user?.liked_by
              //       ?.username
              //     }</h3> */}
              //     {/* <p style={{ margin: 0 }}>{user.email}</p> */}
              //   </div>
              // </li>
            ))}
            {/* </ul> */}
          </div>
  )
}

export default LikePeopleList