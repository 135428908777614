import React from 'react'
import {Row, Col, Container} from 'react-bootstrap'
import Card from '../../components/Card'
import {Link} from 'react-router-dom'
import { useGetConnectionQuery } from "../../store/connection/connectionApiSlice";

import user05 from '../../assets/images/user/05.jpg'



const FollowersList =() =>{
    // const {data}=useGetConnectionQuery()
  // console.log(data,"getConnection")
    return(
        <>
            {/* <ProfileHeader title="Friend Lists" img={img3}/> */}
                <div id="content-page" className="content-page">
                    <Container>
                        <Row>
                            {[1,2].map(val=>{
                                return (
                                    <Col md={6}>
                                <Card className=" card-block card-stretch card-height">
                                    <Card.Body className=" profile-page p-0">
                                        <div className="profile-header-image">
                                            {/* <div className="cover-container">
                                                <img loading="lazy" src={img1} alt="profile-bg" className="rounded img-fluid w-100"/>
                                            </div> */}
                                            <div className="profile-info p-4">
                                                <div className="user-detail">
                                                    <div className="d-flex flex-wrap justify-content-between align-items-start">
                                                        <div className="profile-detail d-flex">
                                                            <div className="profile-img pe-4">
                                                                <img loading="lazy" src={user05} alt="profile-img" className="avatar-130 img-fluid" />
                                                            </div>
                                                            <div className="user-data-block">
                                                                <h4>
                                                                    <Link to="/dashboard/app/friend-profile">Milan Srivastava</Link>
                                                                </h4>
                                                                <h6>developer</h6>
                                                                <div class="d-flex flex-row mb-3 ">
                                                        <button className="btn btn-primary m-2">Follow Back</button>
                                                        <button className="btn btn-primary m-2">Connect</button>
                                                        </div>
                                                            </div>
                                                        </div>
                                                        
                                                       
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </Card.Body>
                                </Card>
                            </Col>
                                )
                            })}
                           
                        </Row>
                    </Container>
                </div>
        </>
  )

}

export default FollowersList;